import React from 'react';
import Layout from '../../../../components/Layout';
import Sustainabiltycommon from '../../Sustainabiltycommon';

const OccupationalHealth = () => {
   const img1= `${process.env.PUBLIC_URL}/assets/sustainability/governance/occupationalhealtyandsaftey.webp`;

    return (
        <Layout title={"MBA Solutions - Occupational Health and Safety"} keywords={"Occupational Health, MBA Sustainability Service, MBA Sustainability- Safety, MBA Services"}>
            <h1 className="text-3xl drop-shadow-md font-bold mt-14 mb-20 text-center" data-aos="fade-up"> Occupational Health and Safety</h1>
            
            <div className="sectionlayout mt-20 justify-center overflow-x-hidden md:space-x-20 flex flex-col-reverse md:flex-row" data-aos="fade-up">
                <div className="flex flex-col md:w-1/2" data-aos="fade-right">
                    <p className="text-gray-700 mt-16 md:mt-4">
                        We maintain a safe and healthy work environment by implementing <strong>ISO 45001-certified occupational health and safety (OHS) programs</strong>. This includes risk assessments, employee safety training, and wellness programs to ensure workplace well-being.
                    </p>
                </div>
                <img src={img1} alt="Occupational Health and Safety" className="w-full md:w-1/2 h-auto rounded-lg" data-aos="fade-left" />
            </div>
            <Sustainabiltycommon />
            
        </Layout>
    );
};

export default OccupationalHealth;
