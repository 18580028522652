import React from 'react';
import Layout from '../../../../components/Layout';
import Sustainabiltycommon from '../../Sustainabiltycommon';

const Biodiversity = () => {
const img1 = `${process.env.PUBLIC_URL}/assets/sustainability/biodiversity.webp`;

    return (
        <Layout title={"MBA Solutions - Biodiversity"} keywords={"Biodiversity, MBA Sustainability Service, MBA Sustainability- Environment, MBA Services"}>
            <h1 className="text-3xl drop-shadow-md font-bold mt-14 mb-20 text-center" data-aos="fade-up"> Biodiversity</h1>
            
            <div className="sectionlayout mt-20 justify-center overflow-x-hidden md:space-x-20 flex flex-col-reverse md:flex-row" data-aos="fade-up">
                <div className="flex flex-col md:w-1/2" data-aos="fade-right">
                    <p className="text-gray-700 mt-16 md:mt-4">
                        We are dedicated to <strong> protecting  biodiversity </strong> through sustainable sourcing, responsible land use, and conservation efforts. Our policies ensure that <strong> natural habitats, wildlife, and ecosystems </strong> remain protected while supporting environmental sustainability initiatives.
                    </p>
                </div>
                <img src={img1} alt="Biodiversity" className="w-full md:w-1/2 h-auto rounded-lg" data-aos="fade-left" />
            </div>
            <Sustainabiltycommon />
            
        </Layout>
    );
};

export default Biodiversity;
