import React from 'react';
import Layout from '../../../../components/Layout';
import Sustainabiltycommon from '../../Sustainabiltycommon';

const ResourceCircularity = () => {
    const img1 = `${process.env.PUBLIC_URL}/assets/sustainability/resourcecircularity.jpg`;

    return (
        <Layout title={"MBA Solutions - Resource Circularity"} keywords={"Resource Circularity, MBA Sustainability Service, Mba Sustainability- Resource Circularity, MBA Services"}>
            <h1 className="text-3xl drop-shadow-md font-bold mt-14 mb-20 text-center" data-aos="fade-up"> Resource Circularity</h1>
            
            <div className="sectionlayout bg-white mt-20 justify-center overflow-x-hidden md:space-x-20 flex flex-col-reverse md:flex-row" data-aos="fade-up">
                <div className="flex flex-col md:w-1/2" data-aos="fade-right">
                    <p className="text-gray-700 mt-16 md:mt-4">
                        To promote <strong>resource circularity</strong>, we implement recycling programs, closed-loop manufacturing, and waste reduction strategies. We focus on minimizing landfill waste by repurposing materials and integrating sustainable solutions into our supply chain. Our goal is to create a sustainable future by reducing waste and promoting the reuse of resources.
                    </p>
                </div>
                <img src={img1} alt="Resource Circularity" className="w-full md:w-1/2 h-auto rounded-lg" data-aos="fade-left" />
            </div>
            <Sustainabiltycommon />

        </Layout>
    );
};

export default ResourceCircularity;
