import React, { useState, useEffect } from 'react';
import Axios from 'axios';

const Section1 = () => {
  const [consultancy, setConsultancy] = useState(null);

    const [imageLoaded, setImageLoaded] = useState(false);
  
    const handleImageLoad = () => {
      setImageLoaded(true);
    };
  
  useEffect(() => {
    // Make the Axios GET request to fetch data
    Axios.get('https://api.mbasolutions.co/api/categories/get')
      .then((response) => {
        // Filter the data to find the object with name: "Consultancy"
        const ConsultancyObject = response.data.find(item => item.name === "Consultancy");

        // Set the consultancy data in the state
        setConsultancy(ConsultancyObject);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);

  return (
    <div className='my-8 mt-16 sectionlayout '>
      {consultancy ? (
        <div>
          
          <h1 className='text-[32px] font-bold drop-shadow-lg text-center'> {consultancy.name}</h1>
          {/* Add more data properties here */}

  <div className={`flex justify-center item-center  ${consultancy.image?'my-20':'mb-20'
  }`}>
            {!imageLoaded && <div className='justify-center items-center flex h-[70vh]'><span className='loader'></span></div>}

          <img src={`https://api.mbasolutions.co${consultancy.image}`}  onLoad={handleImageLoad}className="max-w-full rounded-md mx-auto max-h-[70vh]" alt='Consultancy' />
          </div>
          <p className=' text-center'>{consultancy.paragraph}</p>


        </div>
        
      ) : (
          <div className='justify-center items-center flex h-[70vh]'><span className='loader'></span></div>

      )}

     
    </div>
  );
}

export default Section1;
