import React from 'react';
import Layout from '../../../../components/Layout';
import Sustainabiltycommon from '../../Sustainabiltycommon';

const Compilance = () => {
   const img1= `${process.env.PUBLIC_URL}/assets/sustainability/governance/compilance.webp`;
    return (
        <Layout title={"MBA Solutions - Compliance"} keywords={"Compliance, MBA Sustainability Service, MBA Sustainability- Governance, MBA Services"}>
            <h1 className="text-3xl drop-shadow-md font-bold mt-14 mb-20 text-center" data-aos="fade-up"> Compliance</h1>
            
            <div className="sectionlayout mt-20 justify-center overflow-x-hidden md:space-x-20 flex flex-col-reverse md:flex-row" data-aos="fade-up">
                <div className="flex flex-col md:w-1/2" data-aos="fade-right">
                    <p className="text-gray-700 mt-16 md:mt-4">
                        Our <strong> compliance framework </strong> ensures that we operate in adherence to <strong> local and international regulations, </strong> covering areas such as environmental laws, workplace safety, fair trade, and anti-corruption policies. We maintain strict ethical standards to protect our business and stakeholders.
                    </p>
                </div>
                <img src={img1} alt="Compliance" className="w-full md:w-1/2 h-auto rounded-lg" data-aos="fade-left" />
            </div>
            <Sustainabiltycommon />
            
        </Layout>
    );
};

export default Compilance;
