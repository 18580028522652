import React from 'react';
import Layout from '../../../../components/Layout';
import Sustainabiltycommon from '../../Sustainabiltycommon';

const CyberSecurity = () => {
    return (
        <Layout title={"MBA Solutions - Cybersecurity & Sustainable IT Practices"} keywords={"Cybersecurity, Sustainable IT Practices, MBA Sustainability Service, MBA Sustainability- IT Technology, MBA Services"}>
            <div className="sectionlayout" data-aos="fade-up">
                <h1 className="text-3xl mt-12 font-bold mb-16 text-center">Cybersecurity & Sustainable IT Practices</h1>
                <div className="mb-6" data-aos="fade-up" data-aos-delay="100">
                    <h2 className="text-xl font-semibold mb-2">ISO 27001 – Information Security Management System (ISMS)</h2>
                    <p className="text-gray-700">
                        Ensures that IT businesses protect sensitive data while maintaining sustainable cybersecurity practices.
                    </p>
                </div>
                <div className="mb-6" data-aos="fade-up" data-aos-delay="200">
                    <h2 className="text-xl font-semibold mb-2">ISO 27701 – Privacy Information Management System</h2>
                    <p className="text-gray-700">
                        Encourages responsible data handling and privacy protection in IT organizations.
                    </p>
                </div>
                <div className="mb-6" data-aos="fade-up" data-aos-delay="300">
                    <h2 className="text-xl font-semibold mb-2">GDPR Compliance (General Data Protection Regulation)</h2>
                    <p className="text-gray-700">
                        Ensures responsible and ethical management of user data, which is an essential part of corporate sustainability.
                    </p>
                </div>
            </div>
            <Sustainabiltycommon />
        </Layout>
    );
};

export default CyberSecurity;
