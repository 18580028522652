import React, { useState } from 'react';
import { data } from '../../data';
import CountUp from 'react-countup'
import ScrollTrigger from 'react-scroll-trigger'
import { useSpring, animated } from "react-spring";

const HomeSection4 = () => {
    const { Numbers } = data;
const [counterOn, setCounterOn]=useState(false)
const textSpring = useSpring({
  opacity: counterOn ? 1 : 0,
  from: { opacity: 0, },
});

  return (
   
   <div>
   
      <section
        className="py-10 sm:py-16 lg:py-10 mb-32 "
       
      >
    <ScrollTrigger onEnter={()=>setCounterOn(true)} onExit={()=>setCounterOn(false)}>

        <div className="max-w-5xl px-4 mx-auto sm:px-6 lg:px-8">
          <div className="max-w-2xl mx-auto text-center">
            <h2 className=" text-[32px] text-center leading-[1.2] font-semibold pb-[8rem] drop-shadow-lg">
              Why Us?
            </h2>
          </div>
          <animated.div style={textSpring}>
          <div className="grid grid-cols-1 gap-y-12 md:gap-8 mt-3 lg:mb-5 mb-4 text-center lg:mt-3 sm:gap-x-8 md:grid-cols-3">
           {Numbers.map((nm,i)=>(
            <div key={i}>
             <div>
              <h3 className="font-bold text-7xl">
                <span className=" bg-clip-text bg-gradient-to-r text-orange-500">
          {counterOn &&  <CountUp start={0} end={nm.innumber} duration={nm.delay} delay={0} />
}+      
                </span>
              </h3>
              <p className="mt-4 text-xl font-medium text-gray-900">{nm.name}</p>
              <p className="text-base mt-0.5 text-gray-500">{nm.description}</p>
            </div>
            </div>
           ))}
           
          </div>
          </animated.div>
        </div>
    </ScrollTrigger>

      </section>
    </div>
  );
};

export default HomeSection4;
