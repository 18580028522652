import React from "react";
import { MdCall,MdLocationOn  } from "react-icons/md";
import { BsFillEnvelopeFill } from "react-icons/bs";

function ContactSection2() {
    const phoneNumber = "+13074001335"; 
    const email = "mz415961@gmail.com"; 
  const mapLink = "https://maps.app.goo.gl/QrpX2SFEX25deo8M7";
  const mapLink2 = "https://goo.gl/maps/7cvXBpX4e7CYaNxK9";
   
    const generateCallLink = () => {
      const url2 = `tel:${phoneNumber}`;
      return url2;
    };
  return (
    <>
      <div className="mt-20 mb-32">
      
        <div className=" grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4   md:items-center  md:mx-10 items-center   cursor-pointer">
        <a href={generateCallLink()} target="_blank" rel="noopener noreferrer" >

          <div
            className={`relative pointer   mx-4  text-center hover:border-b-4 border-orange-500 rounded-[25px] p-8 lg:p-10 h-[250px] transform duration-400 ease-in bg-white  shadow-xl hover:shadow-2xl
             mb-2 mt-2`}
          >
           
            <MdCall size={40} className="text-orange-500 mx-auto"/>
            <h5 className="font-semibold text-[24px] mt-10 mb-[1.5rem] leading-[1.2] text-[#1B1C19]">
              Get In Touch
            </h5>
            <p className="font-light text-[16px] mb-[3rem] md:mb-[5rem] text-[#1B1C19]">
            +1 (307) 400-1335{" "}
            </p>
          </div>
          </a>
          <a href={`mailto:${email}`} target="_blank" rel="noopener noreferrer">
        
          <div
            className={`relative pointer   mx-4  text-center hover:border-b-4 border-orange-500 rounded-[25px] p-8 h-[250px] transform duration-400 ease-in bg-white  shadow-xl hover:shadow-2xl
             mb-2 mt-2`}
          >
           
            
           
            <BsFillEnvelopeFill size={40} className="text-orange-500 mx-auto"/>
            <h5 className="font-semibold text-[24px] mt-10 mb-[1.5rem] leading-[1.2] text-[#1B1C19]">
            DROP US A LINE
            </h5>
            <p className="font-light text-[16px] mb-[3rem] md:mb-[5rem] text-[#1B1C19]">
            atif@mbasolutions.com{" "}
            </p>
          </div>
            </a>
            <a href={mapLink} target="_blank" rel="noopener noreferrer">

          <div
            className={`relative pointer   mx-4  text-center hover:border-b-4 border-orange-500 rounded-[25px] p-8 h-[250px] transform duration-400 ease-in bg-white  shadow-xl hover:shadow-2xl
             mb-2 mt-2`}
          >
           
            <MdLocationOn size={40} className="text-orange-500 mx-auto"/>
            <h5 className="font-semibold text-[24px] mt-10 mb-[1.5rem] leading-[1.2] text-[#1B1C19]">
            WE ARE HERE
            </h5>
            <p className="font-light text-[16px] mb-[3rem] md:mb-[5rem] text-[#1B1C19]">
           525 Randall Ave Ste 100 Cheyenne,WY 82001
            </p>
          </div>
          </a>
          <a href={mapLink2} target="_blank" rel="noopener noreferrer">

          <div
            className={`relative pointer   mx-4  text-center hover:border-b-4 border-orange-500 rounded-[25px] p-8 h-[250px] transform duration-400 ease-in bg-white  shadow-xl hover:shadow-2xl
             mb-2 mt-2`}
          >
           
            <MdLocationOn size={40} className="text-orange-500 mx-auto"/>
            <h5 className="font-semibold text-[24px] mt-10 mb-[1.5rem] leading-[1.2] text-[#1B1C19]">
            WE ARE HERE
            </h5>
            <p className="font-light text-[16px] mb-[3rem] md:mb-[5rem] text-[#1B1C19]">
            Office # 11-2 B2 Township, Lahore, Pakistan.{" "}
            </p>
          </div>
          </a>
        </div>
      </div>
    </>
  );
}

export default ContactSection2;
