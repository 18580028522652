import React from "react";
import {BsChevronRight} from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { animateScroll as scroll } from "react-scroll";

function Sustainabiltycommon() {
  const handlelinkclick=()=>{
       scroll.scrollToTop();
  }
 
  
    const sections = [
      {
      title: "Management",
      link:"/sustainability-management",
      items: [
        { name: "Sustainability Management", link: "/sustainability-management" },
        { name: "Maximize Sustainability and ESG Impact", link: "/maximize-sustainability" },
      ],
      },
      {
      title: "Environmental Responsibility",
      link: "/sustainability/environment",
      items: [
        { name: "Environmental Policy", link: "/environment/environmental-policy" },
        { name: "Environmental Management", link: "/environment/environmental-management" },
        { name: "Environmental Balance", link: "/environment/environmental-balance" },
        { name: "Biodiversity", link: "/environment/biodiversity" },
        { name: "Climate Change", link: "/environment/climate-change" },
        { name: "Resource Circularity", link: "/environment/resource-circularity" },
      ],
      },
      {
      title: "Social Responsibility and Governance",
      link: "/sustainability/social-Governance",
      items: [
        { name: "Human and Labored Rights", link: "/social-governance/human-labored-rights" },
        { name: "Diversity and Inclusions", link: "/social-governance/diversity-inclusions" },
        { name: "Relationship with Society", link: "/social-governance/relationship-society" },
        { name: "Creating a Fulfillment Work Environment", link: "/social-governance/fulfillment-work-environment" },
        { name: "Occupational Health and Safety", link: "/social-governance/occupational-health-safety" },
        { name: "Quality Assurance", link: "/social-governance/quality-assurance" },
        { name: "Supply Chain Management", link: "/social-governance/supply-chain-management" },
        { name: "Corporate Governance", link: "/social-governance/corporate-governance" },
        { name: "Risk Management", link: "/social-governance/risk-management" },
        { name: "Compliance", link: "/social-governance/compliance" },
        { name: "Intellectual Properties", link: "/social-governance/intellectual-properties" },
      ],
      },
      {
      title: "IT & Technology Standards",
      link: '/sustainability/it-technology',
      items: [
        { name: "Environmental & Energy Management Standards", link: "/it-technology/environmental-energy-management" },
        { name: "Circular Economy & E-Waste Management Standards", link: "/it-technology/circular-economy-e-waste" },
        { name: "Ethical Sourcing & Social Responsibility Standards", link: "/it-technology/ethical-sourcing-social-responsibility" },
        { name: "Sustainable IT Infrastructure & Green Data Centers", link: "/it-technology/sustainable-it-infrastructure" },
        { name: "Cybersecurity & Sustainable IT Practices", link: "/it-technology/cybersecurity-sustainable-it" },
      ],
      },
    ];
  return (
    <div className=''>
      <div className="bg-purple-200 sectionlayout ">
        <div className='py-20 mt-20'>
          <h1 className="text-3xl font-bold mb-8" data-aos="fade-up">Sustainability</h1>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
            {sections.map((section, index) => (
              <div key={index} className="space-y-4" data-aos="fade-up" data-aos-delay={`${index * 10}`} data-offset='-100'>
                <Link to={`${section.link}`} onClick={handlelinkclick}>
                  <div className='flex py-3 border-b mb-6 cursor-pointer hover:border-b-4 transition-all duration-300 ease-in-out border-purple-600 justify-between items-center'>
                    <h2 className="text-xl font-semibold text-gray-800">
                      {section.title}{" "}
                    </h2>
                    <span className="bg-purple-500 hover:bg-purple-600 rounded-full p-3 text-white">
                      <BsChevronRight />
                    </span>
                  </div>
                </Link>
                {section.items.map((item, idx) => (
                  <Link to={`${item.link}`} onClick={handlelinkclick} className='' key={idx}>
                    <button
                      className="flex justify-between items-center w-full bg-purple-100 text-left px-4 py-2 rounded-md shadow-md my-4 hover:bg-purple-200 hover:shadow-lg transition duration-200"
                      data-aos="fade-up" data-aos-delay={`${ idx * 10}`}
                    >
                      <span className="text-gray-800">{item.name}</span>
                      <span className="text-purple-700 text-xl">
                        <BsChevronRight />
                      </span>
                    </button>
                  </Link>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Sustainabiltycommon