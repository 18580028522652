import React from 'react';
import Layout from '../../../components/Layout';
import Sustainabiltycommon from '../Sustainabiltycommon';
import { Link } from 'react-router-dom';
import { animateScroll as scroll } from "react-scroll";

const Environment = () => { 
const img1 =`${process.env.PUBLIC_URL}/assets/sustainability/Environmentalpolicy.webp`
const img2 =`${process.env.PUBLIC_URL}/assets/sustainability/environmentalmanagement.webp`
const img3 =`${process.env.PUBLIC_URL}/assets/sustainability/Environmental balance.webp`
const img4 =`${process.env.PUBLIC_URL}/assets/sustainability/biodiversity.webp`
const img5 =`${process.env.PUBLIC_URL}/assets/sustainability/Climatechange.webp`
const img6 =`${process.env.PUBLIC_URL}/assets/sustainability/resourcecircularity.webp`

  const cards = [
    { title: "Environmental Policy", img: img1, link: "/environment/environmental-policy" },
    { title: "Environmental Management", img: img2, link: "/environment/environmental-management" },
    { title: "Environmental Balance", img: img3, link: "/environment/environmental-balance" },
    { title: "Biodiversity", img: img4, link: "/environment/biodiversity" },
    { title: "Climate Change", img: img5, link: "/environment/climate-change" },
    { title: "Resource Circularity", img: img6, link: "/environment/resource-circularity" },
  ];

  const [loading, setLoading] = React.useState(true);

  const handleImageLoad = () => {
    setLoading(false);
  };
 const handlelinkclick=()=>{
       scroll.scrollToTop();
  }
  return (
    <Layout title={"MBA Solutions - Environment"} keywords={"Environment, MBA Sustainability Service, Mba Sustainability- Environment, MBA Services"}>
      <div className="sectionlayout ">
        <h2 className='text-center font-bold drop-shadow-lg pt-20' style={{fontSize:'clamp(36px,2.5vw,50px)'}} data-aos="fade-up">
          Environmental Responsibility
        </h2>
        <p className="text-center mt-4 mb-8 px-4">
          We are committed to reducing environmental impact and promoting sustainable development through the following areas:
        </p>
        <h3 className='font-bold my-4 drop-shadow-md' style={{fontSize:'clamp(24px,1.8vw,36px)'}} data-aos="fade-up">
            Environmental Management
            </h3>
           <p className='mb-6'> We operate under an Environmental Management System (EMS) to monitor and reduce carbon emissions, energy consumption, and waste production. By adopting industry-leading sustainability practices, we ensure that our operations align with international standards such as:
           </p>
        <ul className="  text-left mt-6 space-y-4" data-aos="fade-up">
          <li>
          
            <ul className="list-disc list-inside space-y-2">
              <li><strong> ISO 14001  </strong>– International standard for environmental management systems (EMS) to improve sustainability performance.</li>
              <li><strong> ISO 14064 </strong> – Standards for measuring and reporting greenhouse gas (GHG) emissions.</li>
              <li><strong> ISO 50001 </strong> – Energy management system standard to optimize energy performance and reduce environmental impact.</li>
              <li><strong> GHG Protocol </strong> – Framework for measuring and managing greenhouse gas emissions.</li>
              <li><strong> LEED </strong> (Leadership in Energy and Environmental Design) – Certification for sustainable building design and operation.</li>
              <li><strong> BREEAM </strong> (Building Research Establishment Environmental Assessment Method) – Sustainable construction certification.</li>
            </ul>
          </li>
        </ul>
        {loading && <div className="flex justify-center items-center loader"></div>}
        <div className={`grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8 my-24 ${loading ? 'hidden' : ''}`}>
          {cards.map((card, index) => (
            <div
              key={index}
              className="bg-white shadow-lg rounded-lg overflow-hidden transform transition-all duration-300 hover:scale-105 hover:shadow-2xl group"
              data-aos="fade-up"
              data-aos-delay={`${index * 10}`}
            >
              <div className="relative h-56 overflow-hidden">
                <img
                  src={card.img}
                  alt={card.title}
                  className="w-full h-full object-cover transition-transform duration-300 group-hover:scale-110"
                  onLoad={handleImageLoad}
                />
                <div className="absolute inset-0 bg-black bg-opacity-20 transition-opacity duration-300 group-hover:bg-opacity-30"></div>
              </div>
              <div className="p-6 text-center">
                <h2 className="text-2xl font-bold mb-4 text-gray-800">{card.title}</h2>
                <Link to={card.link} className="inline-block" onClick={handlelinkclick}>
                  <button className="text-white py-3 px-6 bg-purple-500 rounded-lg font-semibold hover:bg-purple-600 transition-all duration-300 ease-in-out transform hover:-translate-y-1">
                    Learn More
                  </button>
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Sustainabiltycommon />
    </Layout>
  );
};

export default Environment;