import React, { useState } from "react";
const img4 = `${process.env.PUBLIC_URL}/assets/sustainability/biodiversity.webp`;
const img5 = `${process.env.PUBLIC_URL}/assets/sustainability/Climatechange.webp`;
const img6 = `${process.env.PUBLIC_URL}/assets/sustainability/resourcecircularity.webp`;

const topics = [
    {
        title: "Defining Sustainability Strategy and Roadmap",
        description: "Description for Defining Sustainability Strategy and Roadmap",
        image: img4,
    },
    {
        title: "Integrate ESG and Impact into the Investment Process",
        description: "Description for Integrate ESG and Impact into the Investment Process",
        image: img5,
    },
    {
        title: "Energy Transition & Decarbonisation",
        description: "Description for Energy Transition & Decarbonisation",
        image: img6,
    },
    {
        title: "Industry Applied Solutions",
        description: "Description for Industry Applied Solutions",
        image: img4,
    },
    {
        title: "Enabling Implementation through Capability-Building",
        description: "Description for Enabling Implementation through Capability-Building",
        image: img5,
    },
];

const SustainabilityList = () => {
    const [activeIndex, setActiveIndex] = useState(null);

    const handleLearnMoreClick = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    return (
        <div className="bg-[#0D0E2C] sectionlayout text-white">
            <div>
                {topics.map((topic, index) => (
                    <div key={index} className="border-t border-gray-500 py-5" data-aos="fade-up">
                        <div className="flex justify-between items-center">
                            <p className="text-lg font-semibold">{topic.title}</p>
                            <button
                                className="bg-orange-600 text-white font-medium py-2 px-4 rounded-full flex items-center gap-2 transition-colors duration-300 hover:bg-orange-700"
                                onClick={() => handleLearnMoreClick(index)}
                            >
                                LEARN MORE
                                <span className="text-lg font-bold">
                                    {activeIndex === index ? '-' : '+'}
                                </span>
                            </button>
                        </div>
                        <div className={`overflow-hidden transition-max-height duration-500 ease-in-out ${activeIndex === index ? 'max-h-screen' : 'max-h-0'}`}>
                            <img src={topic.image} alt={topic.title} className="w-full mt-5 border-gray-500 border-t pt-6 h-auto max-h-[70vh] mb-5" />
                            <p>{topic.description}</p>
                        </div>
                    </div>
                ))}
                <div className="border-t border-gray-500"></div>
            </div>
        </div>
    );
};

export default SustainabilityList;
