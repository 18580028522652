import React  from 'react';
import Layout from '../../../../components/Layout';
import Sustainabiltycommon from '../../Sustainabiltycommon';

const EnvironmentManagment = () => {
  
    const img1 = `${process.env.PUBLIC_URL}/assets/sustainability/environmentalmanagement.webp`;

    return (
        <Layout title={"MBA Solutions - Environment Management"} keywords={"Environment Management, MBA Sustainability Service, Mba Sustainability- Environment, MBA Services"}>
            <h1 className="text-3xl drop-shadow-md font-bold mt-14 mb-20 text-center" data-aos="fade-up"> Environment Management</h1>
            
            <div className="sectionlayout bg-white mt-20 justify-center overflow-x-hidden md:space-x-20 flex flex-col-reverse md:flex-row" data-aos="fade-up">
                <div className="flex flex-col md:w-1/2" data-aos="fade-right">
                    <p className="text-gray-700 mt-16 md:mt-4">
                    We operate under an <strong> Environmental Management System (EMS) </strong> to monitor and reduce <strong>  carbon emissions, energy consumption, and waste production. </strong> By adopting industry-leading sustainability practices, we ensure that our operations align with international standards such as:
                    </p>
                    <ul className="list-disc list-inside text-gray-700 mt-4">
                        <li><strong> ISO 14001 </strong> International standard for environmental management systems (EMS) to improve sustainability performance.</li>
                        <li><strong> ISO 14064 </strong> Standards for measuring and reporting greenhouse gas (GHG) emissions.</li>
                        <li><strong> ISO 50001 </strong> Energy management system standard to optimize energy performance and reduce environmental impact.</li>
                        <li><strong> GHG Protocol </strong> Framework for measuring and managing greenhouse gas emissions.</li>
                        <li><strong> LEED </strong> (Leadership in Energy and Environmental Design) Certification for sustainable building design and operation.</li>
                        <li><strong> BREEAM </strong> (Building Research Establishment Environmental Assessment Method) Sustainable construction certification.</li>
                    </ul>
                    <p className="text-gray-700 mt-4">
                    We regularly update our policies to reflect industry best practices and strive for continuous improvement. Our goal is to minimize our environmental footprint and promote a culture of environmental stewardship within our organization. We believe that sustainable practices are essential for the well-being of our planet and future generations.
                    </p>
                </div>
                <img src={img1} alt="Environmental Policy" className="w-full md:w-1/2 h-auto rounded-lg" data-aos="fade-left" />
            </div>
            <Sustainabiltycommon />

        </Layout>
    );
};

export default EnvironmentManagment;