import React from 'react';
import Layout from '../../../../components/Layout';
import Sustainabiltycommon from '../../Sustainabiltycommon';

const IntelectualProperty = () => {
   const img1= `${process.env.PUBLIC_URL}/assets/sustainability/governance/intelectualproperty.webp`;

    return (
        <Layout title={"MBA Solutions - Intellectual Property"} keywords={"Intellectual Property, MBA Sustainability Service, MBA Sustainability- Governance, MBA Services"}>
            <h1 className="text-3xl drop-shadow-md font-bold mt-14 mb-20 text-center" data-aos="fade-up"> Intellectual Property</h1>
            
            <div className="sectionlayout mt-20 justify-center overflow-x-hidden md:space-x-20 flex flex-col-reverse md:flex-row" data-aos="fade-up">
                <div className="flex flex-col md:w-1/2" data-aos="fade-right">
                    <p className="text-gray-700 mt-16 md:mt-4">
                        We safeguard our intellectual property (IP) through patents, trademarks, copyrights, and data security measures. By ensuring compliance with international IP laws, we foster innovation while protecting proprietary information and maintaining a competitive edge in the industry.
                    </p>
                </div>
                <img src={img1} alt="Intellectual Property" className="w-full md:w-1/2 h-auto rounded-lg" data-aos="fade-left" />
            </div>
            <Sustainabiltycommon />
            
        </Layout>
    );
};

export default IntelectualProperty;
