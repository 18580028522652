import React from 'react'
import Section1 from './Auditing/Section1'
import Section2 from './Certification/Section1'
import Section3 from './Consultancy/Section1'
import Section4 from './cyber Security/Section1'
import Section5 from './Gap Assessment/Section1'
import Section6 from './Govereness,risk/Section1'
import Section7 from './Process Enginerring/Section1'
import Section8 from './Skill Building/Section1'
import Section9 from './Staff Augmentation/Section1'
import ContactSection4 from '../contact/ContactSection4'
import Layout from '../../components/Layout'
import ServicesSection1 from './ServicesSection1'


const Services = (props) => {
  return (
    <div className=' w-full '>
         <Layout title={"MBA Solutions - Services "}>
          <ServicesSection1  name={"Services"}/>
         <div  className='py-8 bg-[#F2F2F2]'>
            <div><Section1 /></div>
            <div><Section2 /></div>
            
            <div><Section4 /></div>
            <div><Section5 /></div>
            <div><Section6 /></div>
            <div><Section7 /></div>
            <div><Section3 /></div>
            <div><Section8 /></div>
            <div><Section9/></div>
            

                <ContactSection4 />
                </div>
         </Layout>
    </div>
  )
}

export default Services