import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Layout from '../../components/Layout';

const NewBlogPage = () => {
  const { specificBlogId } = useParams();
  const [blogData, setBlogData] = useState(null);
  const [imageLoaded, setImageLoaded] = useState(false);
    
      const handleImageLoad = () => {
        setImageLoaded(true);
      };
  useEffect(() => {
    const fetchBlogDetails = async () => {
      try {
        const response = await axios.get(`https://api.mbasolutions.co/api/blogs/getsingle/${specificBlogId}`);
        setBlogData(response.data);
      } catch (error) {
        console.error('Error fetching blog details:', error);
        
      }
    };

    fetchBlogDetails();
  }, [specificBlogId]);

  return (
    <div>
      <Layout>
        <div className='bg-[#F2F2F2]  sectionlayout'>
      {blogData ? (
        
        <>
        <div className='flex justify-center py-20 items-center'>
        {!imageLoaded && <div className='justify-center items-center flex h-[70vh]'><span className='loader'></span></div>}
          
          <img
            src={`https://api.mbasolutions.co${blogData.image}`}
            onLoad={handleImageLoad}
            alt={blogData.name}
            className="max-w-full mb-4 rounded-lg max-h-[500px]  "
          />
          </div>
          <h2 className="text-3xl font-bold mb-4  mt-8 uppercase">{blogData.name}</h2>

          <div dangerouslySetInnerHTML={{ __html: blogData.paragraph }} className="text-gray-700  my-10 py-8 " />
        </>
      ) : (
        <p className='loader h-[70vh] flex justify-center items-center w-full mx-auto'></p>
      )}
      </div>
      </Layout>

    </div>
  );
};

export default NewBlogPage;
