import React, { useState, useEffect } from "react";
import { ToolkitData } from "./ToolkitData";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import { animateScroll as scroll } from "react-scroll";
import { loadStripe } from "@stripe/stripe-js";
const Toolkits = () => {
  const { toolkitBasicData } = ToolkitData;
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedStandards, setSelectedStandards] = useState([]);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [showStandardFilters, setShowStandardFilters] = useState(false); // Manage filter visibility
  const [showTypeFilters, setShowTypeFilters] = useState(false); // Manage type filter visibility
  const maxCardsPerPage = 15; // Maximum number of cards per page
  const toggleStandardFilter = (filterName) => {
    if (selectedStandards.includes(filterName)) {
      setSelectedStandards(
        selectedStandards.filter((filter) => filter !== filterName)
      );
    } else {
      setSelectedStandards([...selectedStandards, filterName]);
    }
  };
  
  const toggleTypeFilter = (filterName) => {
    if (selectedTypes.includes(filterName)) {
      setSelectedTypes(selectedTypes.filter((filter) => filter !== filterName));
    } else {
      setSelectedTypes([...selectedTypes, filterName]);
    }
  };
  
  const handleClick = (page) => {
    setCurrentPage(page);
    scroll.scrollToTop();

  };
  useEffect(() => {
    // Calculate the indexes for the current page's cards
    const indexOfFirstCard = (currentPage - 1) * maxCardsPerPage;
    const indexOfLastCard = indexOfFirstCard + maxCardsPerPage;

    // Filter the cards based on selected standards and types
    const filteredCards = filterCards(
      toolkitBasicData,
      selectedStandards,
      selectedTypes
    );

    // Update the current cards based on the current page
    const currentCards = filteredCards.slice(
      indexOfFirstCard,
      indexOfLastCard
    );

    // Calculate the total number of pages based on filtered cards
    const totalPages = Math.ceil(filteredCards.length / maxCardsPerPage);

    // Ensure currentPage is within valid bounds
    if (currentPage < 1) {
      setCurrentPage(1);
    } else if (currentPage > totalPages) {
      setCurrentPage(totalPages);
    }

  }, [currentPage, selectedStandards, selectedTypes]);

  // Define the missing toggle functions (unchanged from previous code)

  const filterCards = (cards, standards, types) => {
    if (standards.length === 0 && types.length === 0) {
      // No filters selected, return all cards
      return cards;
    }
  
    return cards.filter((card) => {
      // Check if any element in the 'name' array of the card matches any selected standard
      const nameMatches = card.name.some((name) => standards.includes(name));
  
      // Check if any element in the 'type' array of the card matches any selected type
      const typeMatches = card.type.some((type) => types.includes(type));

      // Return true if either name or type matches
      return nameMatches || typeMatches ;
    });
  };
  
 
  const availableStandards = [
    "ISO 27001",
    "ISO 13485",
    "EU MDR",
    "EU GDPR",
    "ISO 14001",
    "ISO 9001",
    "ISO 45001",
    "ISO 17025",
    "IATF 16949",
    "AS9100",
    "ISO 22301",
    "ISO 20000",
  ];

  const availableTypes = ["Multiple Standards", "Regular Toolkits", "Toolkits for Consultants"];

  // Calculate currentCards based on the filtered data
  const indexOfFirstCard = (currentPage - 1) * maxCardsPerPage;
  const indexOfLastCard = indexOfFirstCard + maxCardsPerPage;
  const currentCards = filterCards(
    toolkitBasicData,
    selectedStandards,
    selectedTypes
  ).slice(indexOfFirstCard, indexOfLastCard);
  const totalPages = Math.ceil(toolkitBasicData.length / maxCardsPerPage);

  // Filter the pages with cards
  const pagesWithCards = Array.from(
    { length: totalPages },
    (_, index) => index + 1
  ).filter((page) => {
    const pageCards = filterCards(
      toolkitBasicData,
      selectedStandards,
      selectedTypes
    ).slice((page - 1) * maxCardsPerPage, page * maxCardsPerPage);
    return pageCards.length > 0;
  });

  // Function to toggle filter visibility
  const toggleStandardFilters = () => {
    setShowStandardFilters(!showStandardFilters);
  };

  // Function to toggle type filter visibility
  const toggleTypeFilters = () => {
    setShowTypeFilters(!showTypeFilters);
  };

  // Function to clear filters
  const clearFilters = () => {
    setSelectedStandards([]);
    setSelectedTypes([]);
  };
  const handlePayment =async (tname,tprice) => {
    const data=[{
      name:tname,
      price:tprice
    }
    ]
    console.log(data)
    const stripe=await loadStripe("pk_live_51PIAuTFOSBeEdDTWMzpWzT8C4oftu8Aym62hC2pR3JYktXCfvZixGt1HNY5ddNJPQJIE1SO54wfPweUhcIAZSfOp00EDg7JPF6") 
  const body={
    product:data
  }  
  const headers={
    "Content-Type":"application/json"
  }
  const response=await fetch("https://api.mbasolutions.co/api/payments/create-checkout",{
    method:"POST",
    headers:headers,
    body:JSON.stringify(body)
  });
  const session=await response.json();
  const result=stripe.redirectToCheckout({
    sessionId:session.id
  })
  if(result.error){
    console.log(result.error);
  }
}

  return (
    <div className=" md:mx-7">
      <h2 className="font-bold   text-center py-8 text-4xl">Our Toolkits</h2>
      <div className="mx-auto p-4">
        <div className="flex flex-col md:flex-row">
          {/* Filter column */}
          <div className="mb-4 mt-4 md:mb-0 md:w-[70%] w-full">
            <div className="p-4">
              {/* Filter content */}
              <div className="bg-white shadow-lg p-4 w-[100%] px-6 rounded-lg md:w-[250px]">
                <div className="flex items-center justify-between">
                  <h2 className="text-xl pt-3 font-bold">Standards</h2>
                  <button
                    onClick={toggleStandardFilters}
                    className="text-gray-900 cursor-pointer"
                  >
                    {showStandardFilters ? (
                      <BsChevronUp className="w-4 h-4" />
                    ) : (
                      <BsChevronDown className="w-4 h-4" />
                    )}
                  </button>
                </div>
                {showStandardFilters && (
                  <ul className="pt-3 pl-3 ">
                    {availableStandards.map((filterName, index) => (
                      <li key={index} className="cursor-pointer my-3 ">
                        <label>
                          <input
                            type="checkbox"
                            onChange={() => toggleStandardFilter(filterName)}
                            checked={selectedStandards.includes(filterName)}
                          className="w-4 h-4 mr-2 " />
                          {filterName}
                        </label>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
              {/* Type Filter */}
              <div className="mt-4 bg-white shadow-lg p-4 px-6 rounded-lg md:w-[250px]">
                <div className="flex items-center justify-between">
                  <h2 className="text-xl font-bold">Type</h2>
                  <button
                    onClick={toggleTypeFilters}
                    className="text-gray-900 cursor-pointer"
                  >
                    {showTypeFilters ? (
                      <BsChevronUp className="w-4 h-4" />
                    ) : (
                      <BsChevronDown className="w-4 h-4" />
                    )}
                  </button>
                </div>
                {showTypeFilters && (
                  <ul>
                    {availableTypes.map((filterName, index) => (
                      <li key={index} className="cursor-pointer my-2 mx-2 ">
                        <label>
                          <input
                            type="checkbox"
                            onChange={() => toggleTypeFilter(filterName)}
                            checked={selectedTypes.includes(filterName)}
                            className="w-4 h-4 mr-2 mb-2 "/>
                          {filterName}
                        </label>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
              {/* Clear Filters Button */}
              <button
                onClick={clearFilters}
                className="bg-orange-500 hover:bg-orange-600 cursor-pointer text-white mt-4 p-2 rounded-md"
              >
                Clear Filters
              </button>
            </div>
          </div>

          {/* Cards */}
      
          <div className="bg-[#F2F2F2] grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-y-10 md:items-center ml-5 items-center relative">
  {currentCards.length > 0 ? (
    currentCards.map((li, keys) => (
      // <Link key={key} to={`/paymentForm?name=${encodeURIComponent(li.name)}&price=${encodeURIComponent(li.price)}`}>
        <div key={keys} className="cursor-pointer mb-5 w-[92%] mx-4 text-left border-none rounded-[25px] p-8 h-fit md:h-[350px] transform duration-400 ease-out transition-all bg-white shadow-2xl hover:shadow-black/60 hover:shadow-2xl mt-2" onClick={() => {handlePayment(li.heading,li.price)}}>
          <img src={li.img} alt={li.name[0]} className="rounded-md" />
          <div className="flex space-x-2 text-[9px] my-5">
            {Array.isArray(li.type) && li.type.map((innername, i) => (
              <div
                key={i}
                onClick={() => toggleTypeFilter(innername)}
                className="p-[0.5rem] font-medium rounded-md hover:font-semibold text-white/80 transition-all text-[12px] hover:text-white duration-300 ease-in-out bg-orange-500 hover:bg-orange-600 cursor-pointer"
              >
                {innername}
              </div>
            ))}
          </div>
          <h5 className="font-semibold text-[17px] pt-3 mb-[0.5rem] leading-[1.2] hover:text-orange-500 cursor-pointer text-[#1B1C19]">
            {li.heading}
          </h5>
        </div>
      // </Link>
    ))
  ) : (
    <div className="text-center w-[100%] text-gray-500 mt-8">
      Not Available Now
    </div>
  )}
</div>


         
        </div>
         {/* Pagination */}
         {pagesWithCards.length > 0 && (
            <div className=" z-[10000]  mt-5 flex  bottom-2   justify-center items-center">
              {pagesWithCards.map((page, index) => (
                <button
                  key={index}
                  onClick={() => handleClick(page)}
                  className={`px-4 py-2 mx-1 ${
                    currentPage === page
                      ? "bg-orange-600 text-white"
                      : "bg-gray-300 text-gray-700 hover:bg-orange-400 hover:text-white"
                  } rounded-full focus:outline-none focus:ring focus:ring-orange-200 `}
              >
                  {page}
                </button>
              ))}
            </div>
          )}
      </div>
    </div>
  );
};

export default Toolkits;
