import React from 'react';
import Layout from '../../../../components/Layout';
import Sustainabiltycommon from '../../Sustainabiltycommon';



const EnergyManagement = () => {
    return (
        <Layout title={"MBA Solutions - Environmental & Energy Management Standards"} keywords={"Environmental Management, Energy Management, CarbonNeutral Certification, ENERGY STAR Certification, Green Grid Metrics, MBA Sustainability Service, MBA Sustainability- IT Technology, MBA Services"}>
            <div className="sectionlayout" data-aos="fade-up">
                <h1 className="text-3xl text-center mt-12 font-bold mb-16">Environmental & Energy Management Standards</h1>
                <div className="mb-6" data-aos="fade-up" data-aos-delay="100">
                    <h2 className="text-xl font-semibold mb-2">ISO 14001 – Environmental Management System (EMS)</h2>
                    <p className="text-gray-700">
                        A globally recognized standard that helps IT companies minimize their environmental footprint, optimize energy use, and comply with environmental regulations.
                    </p>
                </div>
                <div className="mb-6" data-aos="fade-up" data-aos-delay="200">
                    <h2 className="text-xl font-semibold mb-2">ISO 50001 – Energy Management System</h2>
                    <p className="text-gray-700">
                        Essential for IT businesses to monitor and improve energy efficiency, particularly in data centers and large-scale IT infrastructure.
                    </p>
                </div>
                <div className="mb-6" data-aos="fade-up" data-aos-delay="300">
                    <h2 className="text-xl font-semibold mb-2">CarbonNeutral® Certification</h2>
                    <p className="text-gray-700">
                        This certification ensures that IT companies measure, reduce, and offset their carbon emissions to achieve carbon neutrality.
                    </p>
                </div>
                <div className="mb-6" data-aos="fade-up" data-aos-delay="400">
                    <h2 className="text-xl font-semibold mb-2">ENERGY STAR Certification</h2>
                    <p className="text-gray-700">
                        A widely recognized standard for energy-efficient IT equipment, including servers, workstations, and data centers.
                    </p>
                </div>
                <div className="mb-6" data-aos="fade-up" data-aos-delay="500">
                    <h2 className="text-xl font-semibold mb-2">Green Grid Metrics (PUE, CUE, WUE)</h2>
                    <p className="text-gray-700">
                        These metrics help IT companies measure energy efficiency, carbon footprint, and water consumption in their data centers.
                    </p>
                </div>
            </div>
            <Sustainabiltycommon />
        </Layout>
    );
};

export default EnergyManagement;
