import React from "react";
import { data } from '../../data';
import Carousel from "react-multi-carousel";
import 'react-multi-carousel/lib/styles.css';
import { CustomButtonGroupAsArrows } from './CustomArrows';

const HomeSection3 = () => {
  const { testominals } = data;
  const responsive = {
   
    desktop: {
      breakpoint: { max: 1700, min: 3000 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1700, min: 850 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 850, min: 0 },
      items: 1
    },
  }
    
  const CustomDot = ({ onClick, active, index, carouselState }) => {
    const { currentSlide } = carouselState;
    return (
      <div className=' flex items-center justify-center space-x-2 mt-[10px]'>
      <li
        className={` w-4 h-4 rounded-full ml-2 ${active ? " bg-black w-8 h-3" : " bg-gray-300 "}`}
        onClick={() => onClick()}
      >
        
      </li>
      </div>
    );
  };
   
  
  return (
    <section className="mb-20 w-full z-10 " >
      <h2 id="solutiontitle" className=' text-[40px] text-center leading-[1.2] font-semibold drop-shadow-lg pb-[7rem] mt-2'>Testominals</h2>
      <div className='w-[100%]   px-10  md:items-center mx-auto items-center relative bg-[#F2F2F2] cursor-pointer'>
      <Carousel responsive={responsive}
    
    arrows={false} renderButtonGroupOutside={true} customButtonGroup={<CustomButtonGroupAsArrows />}

      removeArrowOnDeviceType={["mobile"]}
      customDot={<CustomDot />}
      infinite={true}
      showDots
      >
        {testominals.map((li, key) => (
          <div
            key={key}
            className={` w-[92%]    md:w-[85%] mx-auto   text-left border-none rounded-[25px] p-8 h-fit md:h-[310px]    transform duration-400 ease-in bg-white  shadow-lg hover:shadow-2xl
             mb-16  mt-2`}
          >
           
            <p className='font-light text-[16px] mb-[2rem] text-[#1B1C19]'>{li.comments} </p>
           
            <h5 className='absolute font-semibold text-[20px] mt-6 bottom-5 leading-[1.2] text-[#1B1C19] '>{li.name}</h5>
          </div>
        ))}
        </Carousel>
        
        
      </div>
      
    </section>
  );
};

export default HomeSection3;