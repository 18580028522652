import React from 'react';
import Layout from '../../../components/Layout';
import SustainabiltyCommon from '../Sustainabiltycommon';
const SustainabilityManagement = () => {
    return (
        <Layout title={"MBA Solutions - Sustainability Management"} keywords={"Sustainability Management, MBA Sustainability Service, MBA Sustainability- Management, MBA Services"}>
        <div className="sectionlayout">
            <h1 className="text-3xl text-center mt-16 mb-8 font-bold " data-aos="fade-up">Sustainability Management</h1>
            <p className="mb-4" data-aos="fade-up">
                At MBA Solutions, we are committed to environmental, social, and governance (ESG) sustainability, integrating responsible practices throughout every aspect of our operations. Our approach aligns with global standards, such as ISO 14001 (Environmental Management) and ISO 45001 (Occupational Health and Safety), ensuring that we not only meet but exceed the expectations of our clients, stakeholders, and the communities we serve.
            </p>
            <h2 className="text-2xl font-semibold mb-6" data-aos="fade-up">Our Sustainability Strategy</h2>
            <div className="mb-4" data-aos="fade-up">
                <h3 className="text-lg font-semibold">Environmental Responsibility</h3>
                <p>We focus on minimizing our environmental footprint by adopting green technologies, reducing waste, and promoting energy-efficient solutions. We implement best practices in sustainable design for outdoor advertising and interior setups that align with eco-friendly principles.</p>
            </div>
            <div className="mb-4" data-aos="fade-up">
                <h3 className="text-lg font-semibold">Social Responsibility</h3>
                <p>We are dedicated to enhancing the well-being of our employees, clients, and communities. Our commitment includes providing comprehensive training programs on sustainability, social responsibility, and workplace safety. We also ensure that our suppliers and partners adhere to the same ethical standards.</p>
            </div>
            <div className="mb-4" data-aos="fade-up">
                <h3 className="text-lg font-semibold">Health & Safety</h3>
                <p>We prioritize the health and safety of our team and customers. Through thorough risk assessments, compliance with industry regulations, and continuous improvement of safety protocols, we aim to create a safe and supportive environment for all.</p>
            </div>
            <h2 className="text-xl font-semibold mb-4" data-aos="fade-up">ISO Certifications & Eco Vadis Assessment</h2>
            <p className="mb-4" data-aos="fade-up">
                As part of our ongoing dedication to sustainability, we are proud to comply with the stringent requirements of ISO 14001 for environmental management and ISO 45001 for occupational health and safety. These certifications validate our commitment to reducing environmental impact and ensuring a safe and healthy workplace.
            </p>
            <p className="mb-4" data-aos="fade-up">
                Additionally, we are preparing for an Eco Vadis sustainability assessment, which will help us gain a comprehensive understanding of our sustainability performance. Through this evaluation, we aim to refine our efforts and continue to foster a culture of sustainability across our business operations.
            </p>
            <h2 className="text-xl font-semibold mb-4" data-aos="fade-up">Sustainable Products & Services</h2>
            <p className="mb-4" data-aos="fade-up">
                Our services are designed with sustainability at the forefront. Whether we’re creating branded shelves, stands, or outdoor signage, we carefully select materials that support environmental stewardship. We are continually exploring new innovations to enhance the durability and recyclability of our designs.
            </p>
            <h2 className="text-xl font-semibold mb-4" data-aos="fade-up">Employee Training & Development</h2>
            <p className="mb-4" data-aos="fade-up">
                Our employees are our greatest asset, and we believe that fostering a culture of sustainability begins with them. We provide ongoing training on key sustainability topics, including energy conservation, waste reduction, and sustainable design practices. Our training programs also cover social responsibility and ethical business practices, ensuring that our workforce is equipped to meet the challenges of a sustainable future.
            </p>
            <h2 className="text-xl font-semibold mb-4" data-aos="fade-up">Looking Ahead</h2>
            <p className="mb-4" data-aos="fade-up">
                We are proud of the progress we've made in sustainability management, but we know the journey doesn't end here. As we move forward, we continue to seek new ways to enhance our sustainability practices, reduce our carbon footprint, and create a positive impact on the world.
            </p>
        </div>
        <SustainabiltyCommon />
        </Layout>
    );
};

export default SustainabilityManagement;