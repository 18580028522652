import React from 'react';
import Layout from '../../../components/Layout';
import Sustainabiltycommon from '../Sustainabiltycommon';

const ItTechnology = () => {
    return (
        <Layout title={"MBA Solutions - Driving Sustainability in IT & Technology"} keywords={"IT & Technology, MBA Sustainability Service, MBA Sustainability- IT, MBA Services"}>
        <div className="sectionlayout">
            <h1 className="text-2xl drop-shadow-md mt-10 font-bold mb-4" data-aos="fade-up">Driving Sustainability in IT & Technology</h1>
            <p className="mb-4" data-aos="fade-up">
                The IT & Technology industry plays a crucial role in global sustainability efforts. From reducing carbon emissions in data centers to ensuring ethical sourcing of raw materials, technology companies must adopt sustainable practices that align with international standards.
            </p>
            <p className="mb-4" data-aos="fade-up">
                At MBA Solutions, we help IT businesses implement sustainability standards that reduce environmental impact, enhance social responsibility, and drive long-term growth.
            </p>
            <h2 className="text-xl font-semibold mb-2"  data-aos="fade-up">Key Sustainability Challenges in the IT Industry</h2>
            <ul className="list-disc list-inside mb-4" data-aos="fade-up">
                <li className="mb-2"> Energy Consumption & Carbon Emissions: Data centers and IT operations consume massive amounts of energy, leading to high carbon footprints.</li>
                <li className="mb-2"> E-Waste Management: Improper disposal of electronics contributes to environmental pollution and resource depletion.</li>
                <li className="mb-2"> Ethical Sourcing & Supply Chains: IT companies must ensure responsible sourcing of materials such as rare earth metals, avoiding conflict zones.</li>
                <li className="mb-2"> Social & Labor Responsibility: Fair labor practices in IT manufacturing and software development are essential for ethical business operations.</li>
                <li className="mb-2"> Cybersecurity & Sustainable IT Practices: Data security and responsible information management are crucial for long-term sustainability.</li>
            </ul>
        </div>
        <Sustainabiltycommon />
        </Layout>
    );
};

export default ItTechnology;