import React from 'react';
import Layout from '../../../../components/Layout';
import Sustainabiltycommon from '../../Sustainabiltycommon';

const ClimateChange = () => {
const img1 = `${process.env.PUBLIC_URL}/assets/sustainability/Climatechange.webp`;

    return (
        <Layout title={"MBA Solutions - Climate Change"} keywords={"Climate Change, MBA Sustainability Service, MBA Sustainability- Environment, MBA Services"}>
            <h1 className="text-3xl drop-shadow-md font-bold mt-14 mb-20 text-center" data-aos="fade-up"> Climate Change</h1>
            
            <div className="sectionlayout bg-white mt-20 justify-center overflow-x-hidden md:space-x-20 flex flex-col-reverse md:flex-row" data-aos="fade-up">
                <div className="flex flex-col md:w-1/2" data-aos="fade-right">
                    <p className="text-gray-700 mt-16 md:mt-4">
                        Our commitment to <strong> climate change mitigation </strong> includes adopting renewable energy sources, reducing greenhouse gas emissions, and implementing energy-efficient technologies. We actively support <strong> carbon reduction programs </strong> and work toward achieving net-zero emissions in the long run.
                    </p>
                </div>
                <img src={img1} alt="Climate Change" className="w-full md:w-1/2 h-auto rounded-lg" data-aos="fade-left" />
            </div>
            <Sustainabiltycommon />

        </Layout>
    );
};

export default ClimateChange;
