import React from 'react';


const AboutSection3 = () => {

  return (
    <div className="sectionlayout">
      <h3 className="text-3xl mt-28  font-bold text-center pb-20 drop-shadow-2xl" data-aos='fade-up'>
        Our Mission
      </h3>
      <p className="mb-16 mx-6 text-center" data-aos="fade-up">
        MBA Solutions working as a neutral & independent certification agency
        for assessment, training, product, certification, and other areas in
        order to deliver economic values to customers & assist in the
        management of their assets, projects, products, and systems, resulting
        in performance improvement & risk reduction to support industry and
        the public sector by providing innovative, solution-driven,
        professional services. MBA Solutions have a vision of Quality
        Transformation through its Services.
      </p>
    </div>
  );
};

export default AboutSection3;
