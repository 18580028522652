import React from 'react';
import Layout from '../../../../components/Layout';
import Sustainabiltycommon from '../../Sustainabiltycommon';

const Relationshipwithsociety = () => {
 const img1= `${process.env.PUBLIC_URL}/assets/sustainability/governance/Relationshipwithsociety.webp`;
    
    return (
        <Layout title={"MBA Solutions - Relationship with Society"} keywords={"CSR, Corporate Social Responsibility, Community Engagement, Social Development, MBA Services"}>
            <h1 className="text-3xl drop-shadow-md font-bold mt-14 mb-20 text-center" data-aos="fade-up"> Relationship with Society</h1>
            
            <div className="sectionlayout mt-20 justify-center overflow-x-hidden md:space-x-20 flex flex-col-reverse md:flex-row" data-aos="fade-up">
                <div className="flex flex-col md:w-1/2" data-aos="fade-right">
                    <p className="text-gray-700 mt-16 md:mt-4">
                        We engage with local communities through <strong>corporate social responsibility (CSR) programs</strong>, volunteer initiatives, and philanthropic projects. By investing in education, healthcare, and social development, we strengthen our relationship with society and create lasting positive impacts.
                    </p>
                </div>
                <img src={img1} alt="Relationship with Society" className="w-full md:w-1/2 h-auto rounded-lg" data-aos="fade-left" />
            </div>
            <Sustainabiltycommon />
            
        </Layout>
    );
};

export default Relationshipwithsociety;
