import React, { useEffect, useState } from "react";
import { data } from "../../data";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import axios from "axios";

const Team = () => {
  const Team1 = `${process.env.PUBLIC_URL}/assets/Team/Team1.webp`;
  const { TeamSlider } = data;
  const [dataa, setDataa] = useState([]);
  const [loading, setLoading] = useState(true);

  // Fetch data from the server
  const fetchData = async () => {
    try {
      const response = await axios.get("https://api.mbasolutions.co/api/team/");
      setDataa(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const responsive = {
    desktop: {
      breakpoint: { max: 4000, min: 0 },
      items: 1,
    },
  };

  return (
    <section className="bg-gray-100 w-full sectionlayout">
      <div className="text-center py-20">
        <h2 className="text-4xl font-semibold mb-6 pb-3" data-aos="fade-up">
          Our Amazing Team
        </h2>
        <p
          className="text-center text-xl text-[#3a353a66] pb-24"
          data-aos="fade-up"
        >
          The Talented People Behind the Scenes of the Organization
        </p>

        <div className="w-[100%] mb-28">
          <Carousel
            responsive={responsive}
            autoPlay={true}
            infinite={true}
            arrows={false}
          >
            {TeamSlider.map((li, i) => (
              <img
                src={li.img}
                alt={li.name}
                key={i}
                className="w-[100%] h-[250px] md:h-[550px] lg:h-[600px] mx-0"
              />
            ))}
          </Carousel>
        </div>

        <div className="mb-12 " data-aos="fade-up">
          <div className="flex flex-col justify-center items-center cursor-pointer">
            <div className="bg-white px-6 md:px-20 lg:px-32 py-12 shadow-lg rounded-lg hover:shadow-2xl transition duration-300 ease-in-out">
              <img
                src={Team1}
                alt="Atif"
                className="mx-auto w-[180px] h-[180px] md:w-[250px] md:h-[250px] rounded-full mb-4"
                data-aos="fade-up"
              />
              <h3
                className="text-2xl font-semibold text-gray-800"
                data-aos="fade-up"
              >
                Muhammad Atif
              </h3>
              <p className="text-gray-600" data-aos="fade-up">
                CEO
              </p>
              <p className="text-gray-700 mt-2" data-aos="fade-up">
                Our visionary CEO leading us to new horizons with a wealth of
                experience, innovative thinking, and a passion for driving our
                organization's success.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="grid w-full grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 px-4 md:px-12">
        {loading ? (
          <div className="flex justify-center items-center h-screen">
            <span className="loader"></span>
          </div>
        ) : (
          dataa.map((item, index) => (
            <div
              key={index}
              className="bg-white p-6 md:p-8 cursor-pointer shadow-lg rounded-lg hover:shadow-2xl transition duration-300 ease-in-out"
              data-aos="fade-up"
            >
              <img
                src={`https://api.mbasolutions.co${item.image}`}
                alt={item.name}
                className="mx-auto w-48 h-48 rounded-full mb-4"
              />
              <h3 className="text-2xl text-center font-semibold text-gray-800">
                {item.name}
              </h3>
              <p className="text-gray-600 text-center pt-2">{item.position}</p>
              <p className="text-gray-700 text-center pt-5 pb-7">
                {item.paragraph}
              </p>
            </div>
          ))
        )}
      </div>
    </section>
  );
};

export default Team;
