import React, { useState, useEffect } from "react";
import { ServicesData } from "./ServicesData";
import { Link, useParams } from "react-router-dom";
import { animateScroll as scroll } from 'react-scroll';


const ServicesSection2 = () => {
  const handleLinkClick = () => {
    scroll.scrollToTop();
  };
  const { services, servicepage2data } = ServicesData;
  const { name } = useParams(); // Access the 'name' parameter from the URL


  // Filter servicepage2data based on the 'name' parameter
  const filteredData = servicepage2data.find((item) => item.name === name);

  useEffect(() => {
    // You can use the 'name' parameter here as needed
    console.log("Name from URL:", name);
  }, [name]);

  return (
    <div className="sectionlayout">
      <h2 className="title text-3xl font-bold text-center pb-10 drop-shadow-lg py-28">
        Industrial Coverage
      </h2>
    <p className="text-center text-xl pb-24 text-[#3a353a66]">Comprehensive Industrial Solutions for Diverse Sectors</p>
      <div className="my-8  relative grid md:grid-cols-2 lg:grid-cols-4 grid-cols-1 gap-x-8">
        
          {services.map((li, i) => (
            <Link to={li.link} key={i} onClick={handleLinkClick}>
              <div className="mb-28  ">
                <div
                  className="bg-cover  bg-center h-96  relative rounded-md"
                  style={{ backgroundImage: `url(${li.img})` }}
                >
                  <div className="  relative hover:py-[50%] top-0  left-0 w-full h-[100%]  transition-all duration-300 ease-in-out  hover:bg-black hover:bg-opacity-50 text-white p-2">
                    <h2
                      className={`montserrat  md:text-[16px] text-[14px] hover:shadow-xl md:text-lg flex  text-center justify-center items-center font-semibold `}
                    >
                      {li.name}
                    </h2>
                  </div>
                </div>
              </div>
            </Link>
          ))}
     
      </div>

      {/* Display filtered data based on the 'name' parameter */}
      {filteredData && (
        <div>
          <h3>{filteredData.name}</h3>
          <p>{filteredData.shortdes}</p>
          {/* You can map through the 'points' array if needed */}
          {filteredData.points && (
            <ul>
              {filteredData.points.map((point) => (
                <li key={point.code}>
                  <h4>{point.title}</h4>
                  <p>{point.description}</p>
                </li>
              ))}
            </ul>
          )}
        </div>
      )}
    </div>
  );
};

export default ServicesSection2;
