import { useState } from "react";
import { FaLinkedin, FaPlus, FaMinus } from "react-icons/fa";
const team1 =  `${process.env.PUBLIC_URL}/assets/sustainability/team1.webp`;
const teamMembers = [
    {
        name: "Muhammad Atif",
        role: "CEO",
        image: team1,
        description: "Our visionary CEO leading us to new horizons with a wealth of experience, innovative thinking, and a passion for driving our organization's success.",
    },
    // {
    //     name: "David Rochat",
    //     role: "EMEA Director of Sustainability Services",
    //     image: team1,
    //     description: "David leads sustainability services across the EMEA region.",
    // },
    // {
    //     name: "Oliver Gawad",
    //     role: "Middle East & Africa Director: Chemicals, Sustainability & Economic Impact",
    //     image: team1,
    //     description: "Oliver specializes in chemicals, sustainability, and economic impact.",
    // },
    // {
    //     name: "Alban Blitz",
    //     role: "Director, dss’ Switzerland",
    //     image: team1,
    //     description: "Alban is the director of dss’ Switzerland.",
    // },
    // {
    //     name: "Ankit Kapasi",
    //     role: "India Sustainability Market Lead",
    //     image: team1,
    //     description: "Ankit leads the sustainability market in India.",
    // },
    // {
    //     name: "Tina Passalari",
    //     role: "Global Lead ESG and Sustainable Strategies",
    //     image: team1,
    //     description: "Tina leads global ESG and sustainable strategies.",
    // },
    // {
    //     name: "Stuart Scott",
    //     role: "Director of Sustainability Services, Asia Pacific",
    //     image: team1,
    //     description: "Stuart directs sustainability services in the Asia Pacific region.",
    // },
];

const TeamSection = () => {
    const [expanded, setExpanded] = useState(null);

    const rows = [];
    for (let i = 0; i < teamMembers.length; i += 4) {
        rows.push(teamMembers.slice(i, i + 4));
    }

    return (
        <section className="">
            <h2 className="text-3xl font-bold text-gray-800 text-center drop-shadow-md mt-32">Meet the Team</h2>
            <p className="text-center text-gray-600 mt-2">
                Explore the background and specialties of the experts who’ll be working with you.
            </p>

            {rows.map((row, rowIndex) => (
                <div key={rowIndex} className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-6 py-6 mt-10">
                    {row.map((member, index) => (
                        <div key={index} className="relative">
                            <div className="bg-white p-4 flex flex-col items-center text-center">
                                <img
                                    src={member.image}
                                    alt={member.name}
                                    className="w-32 h-32 rounded-full object-cover border-2 border-gray-300"
                                />
                                <h3 className="mt-4 text-xl drop-shadow-md font-bold">{member.name}</h3>
                                <p className="text-sm mt-4 text-gray-600">{member.role}</p>

                                <div className="flex items-center gap-3 mt-4">
                                    <a href="https://linkedin.com" target="_blank" rel="noreferrer" className="text-white rounded-md p-2 bg-orange-500 hover:bg-orange-600">
                                        <FaLinkedin size={20} />
                                    </a>
                                    <button
                                        onClick={() => setExpanded(expanded === rowIndex ? null : rowIndex)}
                                        className="p-2 rounded-full bg-purple-500 text-white hover:bg-purple-600 transition"
                                    >
                                        {expanded === rowIndex ? <FaMinus size={16} /> : <FaPlus size={16} />}
                                    </button>
                                </div>
                            </div>
                        </div>
                    ))}
                    {expanded === rowIndex && (
                        <div className="col-span-4 py-6 px-12 bg-gray-100 w-full text-gray-700 text-sm">
                            {row.map((member, index) => (
                                <p key={index}>{member.description}</p>
                            ))}
                        </div>
                    )}
                </div>
            ))}
        </section>
    );
};

export default TeamSection;
