// src/components/Section1.js
import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import 'react-multi-carousel/lib/styles.css';
import axios from "axios";
const ClienteleSection2 = () => {
    const [data, setData] = useState([]);

    // Fetch data from the server
    const fetchData = async () => {
        try {
            const response = await axios.get("https://api.mbasolutions.co/api/client/get");
                       setData(response.data);
        } catch (error) {
            console.error("Error fetching data:", error);
           
        }
    };
    
    useEffect(() => {
        fetchData();
    }, []);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 6
    },
    desktop: {
      breakpoint: { max: 3000, min: 900 },
      items: 4
    },
    tablet: {
      breakpoint: { max: 790, min: 550 },
      items: 3
    },
    mobile: {
      breakpoint: { max: 550, min: 0 },
      items: 2
    },
   
  }

  return (
    <section className="w-full h-screen  rubik" id="section2">
      <div className='text-[#1B1C19] mt-[4rem] overflow-x-hidden'>
      <h4 className='title text-center text-[36px]  my-16'>Our Connections</h4>
      <div className=" ">
      <Carousel responsive={responsive}
      autoPlay={true}
      infinite={true}
      arrows={true}
      >
        {data.map((li, key) => (
<div className="  mx-auto  w-[170px] md:w-[200px] pt-12 ">
            <img src={`https://api.mbasolutions.co${li.image}`} alt=""  className="w-[270px] h-[120px]"/>
            <div className="flex justify-center  items-center pt-7 text-[20px] font-bold">
                <h2>{li.name}</h2>
            </div>
            </div>

        )
        
        )}

</Carousel>

      </div>

      </div>
         </section>
  );
};

export default ClienteleSection2;
