import React from 'react';
import Layout from '../../../../components/Layout';
import Sustainabiltycommon from '../../Sustainabiltycommon';

const SupplyChain = () => {
   const img1= `${process.env.PUBLIC_URL}/assets/sustainability/governance/SupplyChainManagement.webp`;

    return (
        <Layout title={"MBA Solutions - Supply Chain Management"} keywords={"Supply Chain Management, MBA Sustainability Service, MBA Sustainability- Governance, MBA Services"}>
            <h1 className="text-3xl drop-shadow-md font-bold mt-14 mb-20 text-center" data-aos="fade-up"> Supply Chain Management</h1>
            
            <div className="sectionlayout mt-20 justify-center overflow-x-hidden md:space-x-20 flex flex-col-reverse md:flex-row" data-aos="fade-up">
                <div className="flex flex-col md:w-1/2" data-aos="fade-right">
                    <p className="text-gray-700 mt-16 md:mt-4">
                        We promote <strong> sustainable supply chain </strong> practices by partnering with <strong> responsible suppliers </strong> who adhere to ethical and environmental standards. Our supply chain policies focus on reducing carbon footprints, improving logistics efficiency, and ensuring compliance with sustainability guidelines.
                    </p>
                </div>
                <img src={img1} alt="Supply Chain Management" className="w-full md:w-1/2 h-auto rounded-lg" data-aos="fade-left" />
            </div>
            <Sustainabiltycommon />
            
        </Layout>
    );
};

export default SupplyChain;
