import React from 'react';
import Layout from '../../../../components/Layout';
import Sustainabiltycommon from '../../Sustainabiltycommon';

const HumanLabouredRight = () => {
   const img1= `${process.env.PUBLIC_URL}/assets/sustainability/governance/HumanLabouredRight.webp`;

    return (
        <Layout title={"MBA Solutions - Human and Labor Rights"} keywords={"Human Rights, Labor Rights, MBA Sustainability Service, MBA Services"}>
            <h1 className="text-3xl drop-shadow-md font-bold mt-14 mb-20 text-center" data-aos="fade-up"> Human and Labor Rights</h1>
            
            <div className="sectionlayout mt-20 justify-center overflow-x-hidden md:space-x-20 flex flex-col-reverse md:flex-row" data-aos="fade-up">
                <div className="flex flex-col md:w-1/2" data-aos="fade-right">
                    <p className="text-gray-700 mt-16 md:mt-4">
                        We uphold <strong>human and labor rights</strong> by providing a safe, inclusive, and fair working environment. Our policies ensure fair wages, safe working conditions, and compliance with ethical labor laws while strictly prohibiting child labor and forced labor in all aspects of our business.

                    </p>
                </div>
                <img src={img1} alt="Biodiversity" className="w-full md:w-1/2 h-auto rounded-lg" data-aos="fade-left" />
            </div>
            <Sustainabiltycommon />
            
        </Layout>
    );
};

export default HumanLabouredRight;
