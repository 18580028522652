import React from 'react';
import Layout from '../../../../components/Layout';
import Sustainabiltycommon from '../../Sustainabiltycommon';

const Fulfillmentwork = () => {
   const img1= `${process.env.PUBLIC_URL}/assets/sustainability/governance/fulfilmentofwork.webp`;

    return (
        <Layout title={"MBA Solutions - Fulfilling Work Environment"} keywords={"Employee Well-being, Professional Growth, Mental Health Support, Work-life Balance, MBA Services"}>
            <h1 className="text-3xl drop-shadow-md font-bold mt-14 mb-20 text-center" data-aos="fade-up"> Creating a Fulfilling Work Environment</h1>
            
            <div className="sectionlayout mt-20 justify-center overflow-x-hidden md:space-x-20 flex flex-col-reverse md:flex-row" data-aos="fade-up">
                <div className="flex flex-col md:w-1/2" data-aos="fade-right">
                    <p className="text-gray-700 mt-16 md:mt-4">
                        We prioritize <strong>employee well-being</strong>  by offering <strong> professional growth opportunities, mental health support, and work-life balance programs. </strong> Through training, mentorship, and leadership development, we empower our employees to succeed and thrive.
                    </p>
                </div>
                <img src={img1} alt="Fulfilling Work Environment" className="w-full md:w-1/2 h-auto rounded-lg" data-aos="fade-left" />
            </div>
            <Sustainabiltycommon />
            
        </Layout>
    );
};

export default Fulfillmentwork;
