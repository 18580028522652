import { Route, Routes, Navigate, useParams } from "react-router-dom";
import About from "./Pages/about/About";
import Services from "./Pages/Services/Services";
import Pecb from "./Pages/pecb/Pecb";
import Team from "./Pages/team/Team";
import Trainings from "./Pages/trainings/Trainings";
import Blog from "./Pages/blog/Blog";
import Contact from "./Pages/contact/Contact";
import Jobs from "./Pages/jobs/Jobs";
import Certification from "./Pages/Services/Certification/Certification";
import Auditing from "./Pages/Services/Auditing/Audidting";
import SkillBuilding from "./Pages/Services/Skill Building/SkillBuilding";
import WhatsAppLogo from "./WhatsappLogo/WhatsAppLogo";
import MainHome from "./Pages/Home/MainHome";
import { useEffect, useState } from "react";
import { BsChevronUp } from "react-icons/bs";
import ServicePage2 from "./Pages/IndustrialCoverage/ServiceInnerPage";
import Login from "./ServerSide/Login";
import Toolkits from "./Pages/toolkits/Toolkits";
import PaymentForm from "./Pages/toolkits/PaymentForm";
import PageNotFound from "./Pages/PageNotFound";
import Cookies from "./components/Cookies";
import Tracking from "./Pages/tracking/Tracking";
import AdminBlogUpdate from "./ServerSide/Blogs/AdminBlogUpdate";
import { useCookies } from "react-cookie";
import NewBlogPage from "./Pages/blog/NewBlogPage";
import Dashboard from "./ServerSide/Dashoard/Dashboard";
import UpdateTeam from "./ServerSide/Team/UpdateTeam";
import UpdateClient from "./ServerSide/Client/UpdateClient";
import Clientele from "./Pages/Clientele/Clientele";
import TermsAndConditions from "./Pages/TermsConditition/TermsAndConditions";
import UpdatePosting from "./ServerSide/JobsPosting/UpdatePosting";
import Success from "./Pages/responsePage/Success";
import Cancel from "./Pages/responsePage/Cancel";
import Sustainability from "./Pages/Sustainability/Sustainability";
import TrainingDetails from "./Pages/trainings/TrainingDetails";
import RegisterForm from "./Pages/trainings/RegisterForm";
import Environment from "./Pages/Sustainability/innerpage/Environment";
import SocialGovernance from "./Pages/Sustainability/innerpage/SocialGovernance";
import SustainabilityManagment from "./Pages/Sustainability/innerpage/MaximizeSustainability/MaximizeSustainability";
import EnvironmentPolicy from "./Pages/Sustainability/innerpage/Environment/EnvironmentPolicy";
import AOS from 'aos';
import 'aos/dist/aos.css';
import EnvironmentManagment from "./Pages/Sustainability/innerpage/Environment/EnvironmentManagment";
import EnvironmentalBalance from "./Pages/Sustainability/innerpage/Environment/EnvironmentalBalance";
import Biodiversity from "./Pages/Sustainability/innerpage/Environment/Biodiversity";
import ClimateChange from "./Pages/Sustainability/innerpage/Environment/ClimateChange";
import ResourceCircularity from "./Pages/Sustainability/innerpage/Environment/ResourceCircularity";
import HumanLabouredRight from "./Pages/Sustainability/innerpage/SocialGovernance/HumanLabouredRight";
import Diversity from "./Pages/Sustainability/innerpage/SocialGovernance/Diversity";
import Relationshipwithsociety from "./Pages/Sustainability/innerpage/SocialGovernance/Relationshipwithsociety";
import Fulfillmentwork from "./Pages/Sustainability/innerpage/SocialGovernance/Fulfillmentwok";
import OccupationalHealth from "./Pages/Sustainability/innerpage/SocialGovernance/OccupationalHealth";
import SupplyChain from "./Pages/Sustainability/innerpage/SocialGovernance/SupplyChain";
import RiskManagement from "./Pages/Sustainability/innerpage/SocialGovernance/RiskManagement";
import Compilance from "./Pages/Sustainability/innerpage/SocialGovernance/Compilance";
import IntelectualProperty from "./Pages/Sustainability/innerpage/SocialGovernance/IntellectualProperty";
import CorporateGovernance from "./Pages/Sustainability/innerpage/SocialGovernance/CorporateGovernance";
import QualityAssurance from "./Pages/Sustainability/innerpage/SocialGovernance/QualityAssuance";
import ItTechnology from "./Pages/Sustainability/innerpage/ItTechnology";
import EnergyManagement from "./Pages/Sustainability/innerpage/It Technology/EnergyManagment";
import CyberSecurity from "./Pages/Sustainability/innerpage/It Technology/CyberSecurity";
import SustainableIt from "./Pages/Sustainability/innerpage/It Technology/SustainableIt";
import EthicalSourcing from "./Pages/Sustainability/innerpage/It Technology/EthicalSourcing";
import SustainabilityManagement from "./Pages/Sustainability/innerpage/SustainabilityManagement";






function App() {
  
  const [showScrollTop, setShowScrollTop] = useState(false);
  const { specificBlogId } = useParams();

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };


  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setShowScrollTop(true);
      } else {
        setShowScrollTop(false);
      }
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {}, [specificBlogId]);
  const [cookies] = useCookies(["clickedServiceId"]);

  const user = localStorage.getItem("token");
  useEffect(() => {
    AOS.init({ duration: 1000 });
}, []);
  return (
    <>
      {showScrollTop && (
        <div
          className="fixed bottom-8  z-[1000] hover:shadow-lg text-white  hover:scale-100 right-8 cursor-pointer bg-orange-600 transition-all ease-out duration-300 p-2 hover:bg-purple-700 shadow-md "
          onClick={scrollToTop}
        >
          <BsChevronUp size={22} className="" />
        </div>
      )}
      <WhatsAppLogo />
      <Cookies />
      <Routes>
        <Route path="/" element={<MainHome />} />

        <Route path="/index.php" element={<MainHome />} />
        <Route path="*" element={<PageNotFound />} />
        <Route path="/our_team.php" element={<Team />} />
        {user && <Route exact path="/admindashboard" element={<Dashboard />} />}
        {user && (
          <Route
            path="/adminlogin"
            element={<Navigate replace to="/admindashboard" />}
          />
        )}
        {user && (
          <Route exact path="/updateblog" element={<AdminBlogUpdate />} />
        )}
        {!user && (
          <Route
            path="/updateblog"
            element={<Navigate replace to="/adminologin" />}
          />
        )}

        {!user && (
          <Route
            exact
            path="/admindashboard"
            element={<Navigate replace to="/adminlogin" />}
          />
        )}
        {user && <Route path="/updateteam" element={<UpdateTeam />} />}

        {!user && (
          <Route
            exact
            path="/updateteam"
            element={<Navigate replace to="/adminlogin" />}
          />
        )}
        {user && <Route path="/updateposting" element={<UpdatePosting />} />}

        {!user && (
          <Route
            exact
            path="/updateposting"
            element={<Navigate replace to="/adminlogin" />}
          />
        )}

        {!user && <Route exact path="/adminlogin" element={<Login />} />}

        <Route path="/jobs" element={<Jobs />} />
        <Route path="/paymentForm" element={<PaymentForm />} />
        <Route path="/toolkits" element={<Toolkits />} />
        <Route path="/toolkits/success" element={<Success />} />
        <Route path="/toolkits/cancel" element={<Cancel />} />
        <Route path="/:name" element={<ServicePage2 />} />
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Services />} />
        <Route path="/home" element={<MainHome />} />
        <Route path="/training-registration" element={<RegisterForm />} />
        <Route path="/pecb/:itemName" element={<Pecb />} />
        <Route path="/team" element={<Team />} />
        <Route path="/training" element={<Trainings />} />
        <Route path="/tracking" element={<Tracking />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/sustainability" element={<Sustainability />} />
        <Route path="/blog/:specificBlogId" element={<NewBlogPage />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/jobs" element={<Jobs />} />
        <Route path="/training-details" element={<TrainingDetails />} />
        <Route
          path={`/service/${cookies.clickedServiceId}`}
          element={<Auditing />}
        />
        <Route path="/Skill_Building" element={<SkillBuilding />} />
        <Route path="/Certifications" element={<Certification />} />
        <Route path="/updateclient" element={<UpdateClient />} />
        <Route path="/clientele" element={<Clientele />} />
        <Route path="/terms" element={<TermsAndConditions />} />
        <Route path="/sustainability/environment" element={<Environment />} />
        <Route path="/maximize-sustainability" element={<SustainabilityManagment />} />
        <Route path="/environment/environmental-policy" element={<EnvironmentPolicy />} />
        <Route path="/environment/environmental-management" element={<EnvironmentManagment />} />
        <Route path="/environment/environmental-balance" element={<EnvironmentalBalance/>} />
        <Route path="/environment/biodiversity" element={<Biodiversity/>} />
        <Route path="/environment/climate-change" element={<ClimateChange />} />
        <Route path="/environment/resource-circularity" element={<ResourceCircularity />} />
        
        <Route path="/sustainability/social-Governance" element={<SocialGovernance />} />
        <Route path="/social-governance/human-labored-rights" element={<HumanLabouredRight />} />
        <Route path="/social-governance/diversity-inclusions" element={<Diversity />} />
        <Route path="/social-governance/relationship-society" element={<Relationshipwithsociety/>} />
        <Route path="/social-governance/fulfillment-work-environment" element={<Fulfillmentwork />} />
        <Route path="/social-governance/occupational-health-safety" element={<OccupationalHealth />} />
        <Route path="/social-governance/supply-chain-management" element={<SupplyChain />} />
        <Route path="/social-governance/corporate-governance" element={<CorporateGovernance/>} />
        <Route path="/social-governance/risk-management" element={<RiskManagement />} />
        <Route path="/social-governance/compliance" element={<Compilance />} />
        <Route path="/social-governance/intellectual-properties" element={<IntelectualProperty />} />
        <Route path="/social-governance/quality-assurance" element={<QualityAssurance />} />
        <Route path="/sustainability/it-technology" element={<ItTechnology />} />
        <Route path="/it-technology/environmental-energy-management" element={<EnergyManagement />} />
        <Route path="/it-technology/circular-economy-e-waste" element={<ItTechnology />} />
        <Route path="/it-technology/ethical-sourcing-social-responsibility" element={<EthicalSourcing />} />
        <Route path="/it-technology/sustainable-it-infrastructure" element={<SustainableIt />} />
        <Route path="/it-technology/cybersecurity-sustainable-it" element={<CyberSecurity />} />
        <Route path="/sustainability-management" element={<SustainabilityManagement />} />

        

      </Routes>
    </>
  );
}

export default App;
