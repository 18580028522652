import React, { useState, useEffect } from 'react';
import Axios from 'axios';

const Section1 = () => {
  const [certificationdata, setCertificationData] = useState(null);

  useEffect(() => {
    Axios.get('https://api.mbasolutions.co/api/categories/get')
      .then((response) => {
        const CertificationsObject = response.data.find(item => item.name === "Certifications");

        setCertificationData(CertificationsObject);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);
  const [imageLoaded, setImageLoaded] = useState(false);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  return (
    <div className='my-8 mt-16 sectionlayout '>
      {certificationdata ? (
        <div>
          <h1 className='text-[32px] font-bold drop-shadow-lg text-center'> {certificationdata.name}</h1>
  

          <div className={`flex justify-center item-center ${certificationdata.image ? 'my-20' : 'mb-20'}`}>
            {!imageLoaded && <div className='justify-center items-center flex h-[70vh]'><span className='loader'></span></div>}
            <img
              src={`https://api.mbasolutions.co${certificationdata.image}`}
              className="max-w-full rounded-md mx-auto max-h-[70vh]"
              alt=''
              onLoad={handleImageLoad}
              style={{ display: imageLoaded ? 'block' : 'none' }}
            />
          </div>
          <p className='my-12 text-center'>{certificationdata.paragraph}</p>
        </div>
      ) : (
         <div className='justify-center items-center flex h-[70vh]'><span className='loader'></span></div>
      )}
    </div>
  );
}

export default Section1;
