import React from 'react';
import Layout from '../../../../components/Layout';
import Sustainabiltycommon from '../../Sustainabiltycommon';

const RiskManagement = () => {
   const img1= `${process.env.PUBLIC_URL}/assets/sustainability/governance/riskmanagement.webp`;
    
    return (
        <Layout title={"MBA Solutions - Risk Management"} keywords={"Risk Management, MBA Sustainability Service, MBA Sustainability- Governance, MBA Services"}>
            <h1 className="text-3xl drop-shadow-md font-bold mt-14 mb-20 text-center" data-aos="fade-up"> Risk Management</h1>
            
            <div className="sectionlayout mt-20 justify-center overflow-x-hidden md:space-x-20 flex flex-col-reverse md:flex-row" data-aos="fade-up">
                <div className="flex flex-col md:w-1/2" data-aos="fade-right">
                    <p className="text-gray-700 mt-16 md:mt-4">
                        We implement comprehensive <strong> risk management strategies </strong> to identify, assess, and mitigate potential financial, operational, and environmental risks. By conducting regular risk assessments and business continuity planning, we ensure resilience in a dynamic market environment.
                    </p>
                </div>
                <img src={img1} alt="Risk Management" className="w-full md:w-1/2 h-auto rounded-lg" data-aos="fade-left" />
            </div>
            <Sustainabiltycommon />
            
        </Layout>
    );
};

export default RiskManagement;
