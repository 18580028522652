import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Home/Home.css';
import Layout from '../components/Layout';

const PageNotFound = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000); 

    return () => clearTimeout(timer); 
  }, []);

  return (
    <>
      <Layout title={"MBA Solutions - Page Not Found"} keywords={"Page Not Found, 404, Page Not Found Error"}>
        {loading ? (
          <div className='loading'>Loading...</div>
        ) : (
          <div className='pnf'>
            <h1 className='pnf-title'>404</h1>
            <h2 className='pnf-heading'>Oops ! Page Not Found</h2>
            <Link to='/' className='pnf-btn'>Go Back</Link>
          </div>
        )}
      </Layout>
    </>
  );
}

export default PageNotFound;