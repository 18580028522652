import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { useCookies } from "react-cookie";

const Section1 = () => {
  const [auditingData, setAuditingData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [cookies] = useCookies(["clickedServiceId"]);
    const [imageLoaded, setImageLoaded] = useState(false);
  
  const handleImageLoad = () => {
    setImageLoaded(true);
  };
  useEffect(() => {
    // Make the Axios GET request to fetch data based on the extracted id
    Axios.get(`https://api.mbasolutions.co/api/categories/get/${cookies.clickedServiceId}`)
      .then((response) => {
      
        setAuditingData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });
  }, [cookies.clickedServiceId]);

  useEffect(() => {
    if (auditingData) {
   
    }
  }, [auditingData]);

  return (
    <div className='my-16 mt-16 sectionlayout '>
      {loading  ? (
        <div className="flex justify-center items-center h-screen">
          <span className="loader">

          </span>
        </div>
      ) : (
        auditingData && (
          <div>
            <h1 className='text-[32px] font-bold drop-shadow-lg text-center mt-20'>{auditingData.name}</h1>
            <div className='flex flex-col justify-center items-center '>
            {!imageLoaded && <div className='justify-center items-center flex h-[70vh]'><span className='loader'></span></div>}

              <div className={`flex justify-center w-full md:w-1/2 item-center ${auditingData.image ? 'my-20' : 'mb-20'}`}>
                <img src={`https://api.mbasolutions.co${auditingData.image}`}  onLoad={handleImageLoad} className="max-w-full  rounded-md mx-auto max-h-[70vh]" alt={auditingData.name} />
              </div>
              <div className=''>
                {auditingData.paragraph && (
                  <div dangerouslySetInnerHTML={{ __html: auditingData.paragraph }} className="text-gray-700 mx-10 my-10 " />
                )}
              </div>
            </div>
          </div>
        )
      )}
    </div>
  );
}

export default Section1;
