import React, { useState } from "react";
import Layout from "../../../../components/Layout";
import { animateScroll as scroll } from "react-scroll";
import SustainabilityList from "./SustainabilityList";
import OtherServices from "./OtherServices";
import  Sustainabiltycommon from '../../Sustainabiltycommon';
import TeamSection from "./Team";

const SustainabilityManagment = () => {
  const [showMore, setShowMore] = useState(false);

  const toggleShowMore = () => {
    setShowMore(!showMore);
    scroll.scrollToTop();
  };

  return (
    <Layout
      title={"MBA Solutions - Maximize Sustainability & ESG Impact "}
      keywords={
        "Sustainability, MBA Sustainability, Maximize Sustainability, MBA Services, ESG Impact"
      }
    >
      <div className="my-20">
        <div className="sectionlayout overflow-x-hidden">
          <div className="">
            <section className="mb-10 " data-aos="fade-up">
              <h1
                className="font-bold mb-14 drop-shadow-md"
                style={{ fontSize: "clamp(38px,2.9vw,60px)" }}
              >
                Maximize Sustainability and ESG Impact
              </h1>
              <p className="mt-4 text-lg">
                Sustainability has become a key priority for businesses
                worldwide, but what does it truly mean to integrate it into your
                operations? How can addressing{" "}
                <strong> Environmental, Social, and Governance (ESG) </strong>{" "}
                risks and opportunities enhance efficiency, optimize
                performance, and create long-term value? Beyond compliance,
                sustainability initiatives can drive{" "}
                <strong>
                  {" "}
                  cost reduction, improved risk management, stronger customer
                  trust, and increased employee engagement.
                </strong>
              </p>
              {showMore && (
                <>
                  <p className="mt-4 text-lg">
                    At <strong> MBA Solutions Pvt. Ltd., </strong> we support
                    organizations in transforming sustainability commitments
                    into tangible business impact. Our expertise lies in
                    developing and implementing{" "}
                    <strong> tailored ESG strategies </strong> that align with
                    your industry, operational goals, and compliance
                    requirements. We take a{" "}
                    <strong> practical, results-driven approach, </strong>{" "}
                    ensuring that sustainability principles are embedded in your
                    business in a way that is actionable and measurable.
                  </p>
                  <p className="mt-4 text-lg">
                    With a deep understanding of{" "}
                    <strong>
                      {" "}
                      global standards and certification frameworks,{" "}
                    </strong>{" "}
                    we help businesses navigate{" "}
                    <strong>
                      {" "}
                      environmental compliance, social responsibility, and
                      corporate governance requirements{" "}
                    </strong>{" "}
                    effectively. Whether you seek to{" "}
                    <strong>
                      {" "}
                      enhance your sustainability performance, meet regulatory
                      demands, or build a more responsible supply chain,{" "}
                    </strong>{" "}
                    we provide the insights and strategies to drive meaningful
                    change.
                  </p>
                  <p className="mt-4 text-lg">
                    Additional content goes here. You can add more details about
                    your services, case studies, or any other relevant
                    information that you want to display when the "Show More"
                    button is clicked.
                  </p>
                </>
              )}
              <button
                onClick={toggleShowMore}
                className="mt-4 bg-purple-500 text-white py-2 px-4 rounded-md fot-bold hover:bg-purple-700 transition-all duration-300 ease-in-out"
              >
                {showMore ? "Show Less" : "Show More"}
              </button>
            </section>
          </div>
        </div>
        <SustainabilityList />
        <OtherServices />
        <TeamSection />
        <Sustainabiltycommon />
      </div>
    </Layout>
  );
};

export default SustainabilityManagment;
