import React from 'react';
import Layout from '../../../../components/Layout';
import Sustainabiltycommon from '../../Sustainabiltycommon';

const EnvironmentPolicy = () => {
    const img1 = `${process.env.PUBLIC_URL}/assets/sustainability/Environmentalpolicy.webp`;
  

    return (
        <Layout title={"MBA Solutions - Environment Policy"} keywords={"Environment Policy, MBA Sustainability Service, Mba Sustainability- Environment, MBA Services"}>
            <h1 className="text-3xl drop-shadow-md font-bold mt-14 mb-20 text-center" data-aos="fade-up">Our Environment Policy</h1>
            
            <div className="sectionlayout bg-white mt-20 justify-center overflow-x-hidden    md:space-x-20 flex flex-col-reverse md:flex-row" data-aos="fade-up">
                <div className="flex flex-col md:w-1/2" data-aos="fade-right">
                    <p className="text-gray-700 mt-16 md:mt-4">
                        Our <strong> environmental policy </strong> is a commitment to sustainability, focusing on <strong> responsible resource management, pollution reduction, and compliance with environmental regulations </strong> We regularly update our policies to reflect industry best practices and strive for continuous improvement. Our goal is to minimize our environmental footprint and promote a culture of environmental stewardship within our organization. We believe that sustainable practices are essential for the well-being of our planet and future generations.
                    </p>
                </div>
                <img src={img1} alt="Environmental Policy" className="w-full md:w-1/2 h-auto rounded-lg" data-aos="fade-left" />
            </div>
            <Sustainabiltycommon />
        </Layout>
    );
};

export default EnvironmentPolicy;