import React from "react";
import "./Home.css";
import HomeSection2 from "./HomeSection2";
import HomeSection3 from "./HomeSection3";
import HomeSection4 from "./HomeSection4";
import Layout from "../../components/Layout";
import HomeSection1new from "./HomeSection1new";

const MainHome = () => {
  return (
    <Layout title={"MBA Solutions - Home "} >
     

<div className="bg-[#F2F2F2] overflow-x-hidden">
<HomeSection1new />
      <HomeSection2  />
      <HomeSection4 />
      <HomeSection3  />
      </div>
      
    </Layout>
  );
};

export default MainHome;
