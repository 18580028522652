import React from 'react';
import Sustainabiltycommon from '../../Sustainabiltycommon';
import Layout from '../../../../components/Layout';

const EthicalSourcing = () => {
    return (
        <Layout title={"MBA Solutions - Ethical Sourcing & Social Responsibility Standards"} keywords={"Ethical Sourcing, Social Responsibility, RBA Code of Conduct, ISO 26000, Fairtrade, Conflict-Free Minerals, SA8000, EcoVadis, MBA Sustainability Service, MBA Sustainability- IT Technology, MBA Services"}>
            <div className="sectionlayout" data-aos="fade-up">
                <h1 className="text-3xl  text-center mt-12 font-bold mb-16">Ethical Sourcing & Social Responsibility Standards</h1>
                <div className="mb-6" data-aos="fade-up" data-aos-delay="100">
                    <h2 className="text-xl font-semibold mb-2">RBA (Responsible Business Alliance) Code of Conduct</h2>
                    <p className="text-gray-700">
                        A leading standard ensuring fair labor practices, ethical sourcing, and environmental responsibility in IT supply chains.
                    </p>
                </div>
                <div className="mb-6" data-aos="fade-up" data-aos-delay="200">
                    <h2 className="text-xl font-semibold mb-2">ISO 26000 – Social Responsibility</h2>
                    <p className="text-gray-700">
                        Provides guidance on ethical business practices and corporate social responsibility in the IT sector.
                    </p>
                </div>
                <div className="mb-6" data-aos="fade-up" data-aos-delay="300">
                    <h2 className="text-xl font-semibold mb-2">Fairtrade & Conflict-Free Minerals Compliance</h2>
                    <p className="text-gray-700">
                        Ensures that materials like tungsten, tin, tantalum, and gold used in IT products are sourced responsibly and ethically.
                    </p>
                </div>
                <div className="mb-6" data-aos="fade-up" data-aos-delay="400">
                    <h2 className="text-xl font-semibold mb-2">SA8000 – Social Accountability Standard</h2>
                    <p className="text-gray-700">
                        Focuses on labor rights, workplace safety, and ethical business operations in IT manufacturing.
                    </p>
                </div>
                <div className="mb-6" data-aos="fade-up" data-aos-delay="500">
                    <h2 className="text-xl font-semibold mb-2">EcoVadis Sustainability Rating</h2>
                    <p className="text-gray-700">
                        An independent assessment that evaluates IT companies on environmental, social, and governance (ESG) factors.
                    </p>
                </div>
            </div>
            <Sustainabiltycommon />
        </Layout>
    );
};

export default EthicalSourcing;
