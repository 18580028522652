import React from 'react';
import Layout from '../../../../components/Layout';
import Sustainabiltycommon from '../../Sustainabiltycommon';

const corporategovernance = () => {
   const img1= `${process.env.PUBLIC_URL}/assets/sustainability/governance/corporategovernance.webp`;

    return (
        <Layout title={"MBA Solutions - Corporate Governance"} keywords={"Corporate Governance, MBA Sustainability Service, MBA Governance, MBA Services"}>
            <h1 className="text-3xl drop-shadow-md font-bold mt-14 mb-20 text-center" data-aos="fade-up"> Corporate Governance</h1>
            
            <div className="sectionlayout mt-20 justify-center overflow-x-hidden md:space-x-20 flex flex-col-reverse md:flex-row" data-aos="fade-up">
                <div className="flex flex-col md:w-1/2" data-aos="fade-right">
                    <p className="text-gray-700 mt-16 md:mt-4">
                        We uphold strong <strong>corporate governance principles</strong>, ensuring transparency, accountability, and ethical decision-making across our organization. Our governance structure fosters responsible leadership, regulatory compliance, and financial integrity to build trust with our stakeholders.
                    </p>
                </div>
                <img src={img1} alt="Corporate Governance" className="w-full md:w-1/2 h-auto rounded-lg" data-aos="fade-left" />
            </div>
            <Sustainabiltycommon />
            
        </Layout>
    );
};

export default corporategovernance;
