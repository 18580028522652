const Team1 = './assets/Team/Team1.webp'
const Pic1 = `${process.env.PUBLIC_URL}/assets/TeamSlider/Pic1.webp`
const Pic2 =`${process.env.PUBLIC_URL}/assets/TeamSlider/Pic2.webp`
const Pic3 =`${process.env.PUBLIC_URL}/assets/TeamSlider/Pic3.webp`
const Pic4 = `${process.env.PUBLIC_URL}/assets/TeamSlider/Pic4.webp`


export const data = {

   
    testominals:[
        {
            name:"Makson Packaging Director",
            comments:"Our experience with MBA Solutions Private Limited was exceptional. Their team's proficiency in ISO standards and commitment to client satisfaction were evident throughout our certification journey. MBA Solutions tailored their approach to our specific needs, providing valuable insights and ensuring a smooth certification process. We appreciate their dedication and recommend MBA Solutions as a reliable partner for ISO certification services."
        },
        {
            name:"James Wilson",
            comments:"This template has everything I was looking for my business website to have. = easy-to-customize pages to flawlessly working web tools, Wuart is my number one choice!"
        },
        {
            name:"Ann Williams",
            comments:"Thank you for such an affordable and multipurpose template! It's easy to configure and use and what's more constant it looks great. It's just what I wanted for my website. Keep doing a great job, I'm sure to become a returning customer!"
        },
        {
            name:"Jane Smith",
            comments:"Since I'm not a real pro in web design, I love to use these ready-made and beautifully looking templates.They are available at very affordable prices and they save a lot of time,and deliver = a lot of sweat and tears!"
        }
    ],
    Numbers:[
        {
            innumber:45,
            name:"Awards",
            description:"For Best Performances",
       delay:2,
        },
        {
            innumber:100,
            name:"Projects Anually",
            description:"Creating the successful path",
            delay:2,
        },
        {
            innumber:1047,
            name:"Happy Customers",
            description:"Customers Response",
       delay:2,
        }
    ],

     TeamMembers : [
        {
        name:'Muhammad Atif',
          role: "CEO",
          image:Team1, 
          bio:"Our visionary CEO leading us to new horizons with a wealth of experience, innovative thinking, and a passion for driving our organization's success. "    
        },
      
      ],
      TeamSlider:[
            { img:Pic1,name:"Pic1" },
            { img:Pic2,name:"Pic2" },
            { img:Pic3,name:"Pic3" },
            { img:Pic4,name:"Pic4" }
      ]

   
}