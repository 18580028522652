import React, { useState, useEffect } from 'react';
import Axios from 'axios';

const Section1 = () => {
  const [staffAugmentation, setStaffAugmentation] = useState(null);
const [imageLoaded, setImageLoaded] = useState(false);
  
    const handleImageLoad = () => {
      setImageLoaded(true);
    };
  
  useEffect(() => {
    // Make the Axios GET request to fetch data
    Axios.get('https://api.mbasolutions.co/api/categories/get')
      .then((response) => {
        // Filter the data to find the object with name: "Auditing"
        const Staff_Augmentation = response.data.find(item => item.name === "Staff Augmentation");

        // Set the auditing data in the state
        setStaffAugmentation(Staff_Augmentation);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);

  return (
    <div className='my-8 mt-16 sectionlayout '>
      {staffAugmentation ? (
        <div>
          
          <h1 className='text-[32px] font-bold drop-shadow-lg text-center'> {staffAugmentation.name}</h1>
          {/* Add more data properties here */}

  <div className={`flex justify-center item-center mb-20 ${staffAugmentation.image?'my-20':'mb-20'
  }`}>
            {!imageLoaded && <div className='justify-center items-center flex h-[70vh]'><span className='loader'></span></div>}

          <img src={`https://api.mbasolutions.co${staffAugmentation.image}`} onLoad={handleImageLoad} className="max-w-full rounded-md mx-auto max-h-[70vh]" alt='Staff Augmentation' />
          </div>
          <p className='my-12 text-center'>{staffAugmentation.paragraph}</p>


        </div>
        
      ) : (
         <div className='justify-center items-center flex h-[70vh]'><span className='loader'></span></div>
      )}

     
    </div>
  );
}

export default Section1;
