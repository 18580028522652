import React from "react";
const AboutSecion2 = () => {
const aboutbg =  `${process.env.PUBLIC_URL}/assets/about-bg.webp`
 

  return (
    <>
    <div className="sectionlayout">
      <div className="pt-28  mb-20 relative"></div>
        <h3 className="text-3xl font-bold text-center pb-20 drop-shadow-lg">
          Our Vision
        </h3>
        <div className="flex lg:flex-row flex-col-reverse justify-between">
          <div className="lg:w-[50%]">
            <p
              className="mb-16 mx-6 text-center lg:text-left mt-12"
              data-aos="fade-up"
            >
              The Primary purpose of MBA Solutions is to help the clients
              organization for resource development & implement the strategies
              properly for improvement. We identify competitive strengths,
              performance gaps, and growth potential. We help to develop
              execution and control plans to assure implementation across the
              country.
            </p>
          </div>
          <div>
            <img
              src={aboutbg}
              alt="about Company"
              className="lg:w-[400px] w-full h-[250px] mr-2"
              data-aos="fade-up"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutSecion2;
