import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { IoMdPerson } from 'react-icons/io';

const JobSection3 = () => {
  const [jobs, setJobs] = useState([]);
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 0 },
      items: 1,
    },
  };

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const response = await axios.get('https://api.mbasolutions.co/api/jobs/getjob');
        setJobs(response.data);
      } catch (error) {
        console.error('Error fetching jobs:', error);
      }
    };

    // Fetch jobs when the component mounts
    fetchJobs();
  }, []);

  return (
    <div className="p-8 mt-4 mx-auto max-w-[90%]">
      <h1 className="md:my-16 my-12 w-fit px-12 mx-auto h-14 flex items-center justify-center text-center shadow shadow-gray-400 rounded-lg font-bold text-lg md:text-xl lg:text-2xl">
        <span>
          <IoMdPerson size={22} className="mr-2" />
        </span>
        Available Jobs
      </h1>

      {jobs.length > 0 ? (
        <div className="">
          <Carousel
            responsive={responsive}
            arrows={false}
            autoPlay={true}
            renderButtonGroupOutside={true}
            // customButtonGroup={<CustomButtonGroupAsArrows />}
            removeArrowOnDeviceType={['mobile']}
            infinite={true}
            showDots
          >
            {jobs.map((job) => (
              <div
                key={job._id}
                className="p-6 bg-white shadow-md rounded-lg min-h-[12rem] max-h-fit"
              >
                <h3 className="text-[22px] font-semibold mb-2 text-center">{job.title}</h3>
                <div className="flex mt-2">
                  <h3 className="mr-3 font-bold">Education:</h3> <p>{job.education}</p>
                </div>
                <div className="flex mt-2">
                  <h3 className="mr-3 font-bold">Experience:</h3> <p>{job.experience}</p>
                </div>
                <div className="flex mt-2">
                  <h3 className="mr-3 font-bold">Apply at:</h3> <p>{job.apply}</p>
                </div>
                <div className="flex mt-2">
                  <h3 className="mr-3 font-bold">Description:</h3>
                  <div
                    dangerouslySetInnerHTML={{ __html: job.description }}
                    className="text-gray-700 mb-4"
                  />
                </div>

                {/* Add more job details as needed */}
              </div>
            ))}
          </Carousel>
        </div>
      ) : (
        <p className="text-center pt-16 text-gray-700 flex justify-center items-center">No available jobs at the moment.</p>
      )}
    </div>
  );
};

export default JobSection3;
