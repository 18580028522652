import React from "react";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { CustomButtonGroupAsArrows } from "./CustomArrows";

function OtherServices() {
  const img1 = `${process.env.PUBLIC_URL}/assets/sustainability/sustainability1.webp`;
  const img2 = `${process.env.PUBLIC_URL}/assets/sustainability/sustainability2.webp`;
  const img3 = `${process.env.PUBLIC_URL}/assets/sustainability/sustainability3.webp`;
  const carousel1 = `${process.env.PUBLIC_URL}/assets/sustainability/Climatechange.webp`;
  const carousel2 = `${process.env.PUBLIC_URL}/assets/sustainability/Environmental balance.webp`;
  const carousel3 = `${process.env.PUBLIC_URL}/assets/sustainability/biodiversity.webp`;
  const carousel4 = `${process.env.PUBLIC_URL}/assets/sustainability/environmentalmanagement.webp`;
  const topics = [
    {
      title: "A journey to a More Sustainable Future",
      description:
        "From designing products, services or operations to mobilising organisations, our industry experience & culture change capabilities meet you where you are & facilitate your journey.",
      image: img1,
    },
    {
      title: "Building capabilities and mindsets to success",
      description:
        "We’ll ensure your team has the capabilities to catalyse & sustain change. We’ll train & coach you to enable long-term change within, across your value chain or at a systemic level.",
      image: img2,
    },
    {
      title: "Integrating Environment Social and Governance (ESG) goals",
      description:
        "Our hands-on ESG and sustainability experience, industry knowledge and technical capabilities will protect what you care for, transform your organisation, and sustain your changes.",
      image: img3,
    },
  ];
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1280 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 1280, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 768, min: 0 },
      items: 1,
    },
  };
  const data = [
    {
      image: carousel1,
      title: "ESG Investing Strategy at Brandes Investment Partners",
      description:
        "dss helped Brandes Investment Partners to develop a novel approach to integrating ESG into their investment process.",
    },
    {
      image: carousel2,
      title: "Measuring carbon footprint of run-of-river power station",
      description:
        "dss helped measure post-construction carbon impact of the Massongex-Bex-Rhône (MBR) power station.",
    },
    {
      image: carousel3,
      title: "Building ESG Capability at Financial Services Group",
      description:
        "Custom ESG training drives responsible investing due diligence.",
    },
    {
      image: carousel4,
      title: "Helvetia Environnement Groupe",
      description:
        "dss and HEG collaborated on a step-by-step approach to succeed on the B Corp™ Certification journey.",
    },
  ];
  return (
    <div className="sectionlayout ">
      <h2
        className="mt-10 text-center text-4xl font-bold text-gray-800 drop-shadow-md"
        data-aos="fade-up"
      >
        Sustainability as a business opportunity
      </h2>
      <p className="mt-5 text-center text-lg text-gray-600" data-aos="fade-up">
        We'll ensure your team has the capabilities to catalyse & sustain
        change. We'll train and coach you to enable long-term change within,
        across your value chain or at a systematic level.
      </p>
      <div
        className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mt-16"
        data-aos="fade-up"
      >
        {topics.map((topic, index) => (
          <div
            key={index}
            className="card bg-white shadow-lg rounded-lg overflow-hidden flex flex-col"
            data-aos="fade-up"
            data-aos-delay={`${index * 10}`}
          >
            <img
              src={topic.image}
              alt={topic.title}
              className="w-full h-48 object-contain"
            />
            <div className="p-6 flex-grow">
              <h5 className="text-xl font-bold text-gray-800">{topic.title}</h5>
              <p className="mt-2 text-gray-600">{topic.description}</p>
            </div>
          </div>
        ))}
      </div>
      <h2
        className="mt-10 text-center text-4xl font-bold text-gray-800 drop-shadow-md"
        data-aos="fade-up"
      >
        Featured insights
      </h2>
      <p className="mt-5 text-center text-lg text-gray-600" data-aos="fade-up">
        We live in a volatile age. But there’s never been a better opportunity
        to reshape your actions and create a better world. Explore our thoughts
        on transformation and best practices in dynamic times
      </p>
      <div className="mt-16 relative" data-aos="fade-up">
        <Carousel
          responsive={responsive}
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={3000}
          arrows={false}
          renderButtonGroupOutside={true}
          customButtonGroup={<CustomButtonGroupAsArrows />}
        >
          {data.map((item, index) => (
            <div key={index} className="p-4">
              <div className="bg-white md:h-[440px] shadow-lg rounded-lg overflow-hidden flex flex-col">
                <img
                  src={item.image}
                  alt={item.title}
                  className="w-full h-56 object-cover"
                />
                <div className="p-4 flex-grow">
                  <h3 className="text-lg font-semibold text-red-600">
                    {item.title}
                  </h3>
                  <p className="text-gray-600 mt-2">{item.description}</p>
                </div>
              </div>
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
}

export default OtherServices;
