const iso2700122301 =  `${process.env.PUBLIC_URL}/assets/Toolkit/ISO-27001-ISO-22301-Premium-Documentation-Toolkit.webp` 
const iso13485M =  `${process.env.PUBLIC_URL}/assets/Toolkit/ISO-13485-MDR-Integrated-Documentation-Toolkit.webp`
const is9001 =  `${process.env.PUBLIC_URL}/assets/Toolkit/ISO-9001.webp`
const iso14001 =  `${process.env.PUBLIC_URL}/assets/Toolkit/ISO-14001-Documentation-Toolkit.webp`
const iso22301 =  `${process.env.PUBLIC_URL}/assets/Toolkit/ISO-22301-Documentation-Toolkit.webp`
const iso270012022 =  `${process.env.PUBLIC_URL}/assets/Toolkit/ISO-27001-2022.webp`
const iso27001 =  `${process.env.PUBLIC_URL}/assets/Toolkit/ISO-27001.webp` 
const iso45010 =  `${process.env.PUBLIC_URL}/assets/Toolkit/ISO-45001.webp`
const isoGDPR =  `${process.env.PUBLIC_URL}/assets/Toolkit/ISO-13485-MDR.webp`
const iso20000 =  `${process.env.PUBLIC_URL}/assets/Toolkit/ISO-20000-Documentation-Toolkit.webp`
const isogdpr27001 =  `${process.env.PUBLIC_URL}/assets/Toolkit/ISO-13485-MDR-Integrated-Documentation-Toolkit.webp`
const iso270012701727018 =  `${process.env.PUBLIC_URL}/assets/Toolkit/ISO-27001-ISO-27017-ISO-27018.webp`
const iso17025 =  `${process.env.PUBLIC_URL}/assets/Toolkit/ISO-17025-Documentation-Toolkit.webp`
const iso13485 =  `${process.env.PUBLIC_URL}/assets/Toolkit/ISO-13485-Documentation-Toolkit.webp`
const iso9001P =  `${process.env.PUBLIC_URL}/assets/Toolkit/ISO-9001-Premium.webp`
const iso90011400145001 =  `${process.env.PUBLIC_URL}/assets/Toolkit/ISO-9001-ISO-14001-ISO-45001-Integrated-Documentation-Toolkit.webp`
const isoeugdpr =  `${process.env.PUBLIC_URL}/assets/Toolkit/EU-GDPR.webp`
const iso14001p =  `${process.env.PUBLIC_URL}/assets/Toolkit/ISO-14001-Premium-Documentation-Toolkit.webp`
const iso900114001 =  `${process.env.PUBLIC_URL}/assets/Toolkit/ISO-9001-ISO-14001-Integrated-Documentation-Toolkit.webp`
const iso45001p =  `${process.env.PUBLIC_URL}/assets/Toolkit/ISO-45001-Premium-Documentation-Toolkit.webp`
const iatf16949 =  `${process.env.PUBLIC_URL}/assets/Toolkit/IATF-16949.webp`
const as9100d =  `${process.env.PUBLIC_URL}/assets/Toolkit/AS9100D.webp`
const iso22301B =  `${process.env.PUBLIC_URL}/assets/Toolkit/ISO-22301-Business-Impact-Analysis-Toolkit.webp`
const iso2700122301i =  `${process.env.PUBLIC_URL}/assets/Toolkit/ISO-27001-ISO-22301-Internal-Audit-Toolkit.webp`
const iso270012301r =  `${process.env.PUBLIC_URL}/assets/Toolkit/ISO-27001-ISO-22301-Risk-Assesment-Toolkit.webp`
const iso9001 =  `${process.env.PUBLIC_URL}/assets/Toolkit/ISO-9001-Internal-Audit-Toolkit.webp`
const iso90012015 =  `${process.env.PUBLIC_URL}/assets/Toolkit/ISO-9001-Risk-Management-Toolkit.webp`
const iso140012015 =  `${process.env.PUBLIC_URL}/assets/Toolkit/ISO-14001-Internal-Audit-Toolkit.webp`
const as9100 =  `${process.env.PUBLIC_URL}/assets/Toolkit/AS9100-P.webp`
const iso13485i =  `${process.env.PUBLIC_URL}/assets/Toolkit/ISO-13485-Internal-Audit-Toolkit.webp`
const itsm =  `${process.env.PUBLIC_URL}/assets/Toolkit/ITSM-Change-Management-Toolkit.webp`
const itsmB =  `${process.env.PUBLIC_URL}/assets/Toolkit/ITSM-Business-Relationship-Management-Toolkit.webp`
const iatf16949i =  `${process.env.PUBLIC_URL}/assets/Toolkit/IATF-16949-Internal-Audit.webp`
const iso45001i =  `${process.env.PUBLIC_URL}/assets/Toolkit/ISO-45001-Internal-Audit-Toolkit.webp`
const gdpr =  `${process.env.PUBLIC_URL}/assets/Toolkit/GDPR-Data-Mapping-DPIA.webp`
const gdprm =  `${process.env.PUBLIC_URL}/assets/Toolkit/GDPR-Mini-Toolkit-For-Websites.webp`
const gdprc =  `${process.env.PUBLIC_URL}/assets/Toolkit/GDPR-Consent-Data-Subject-Rights.webp` 
const iso2700122301c =  `${process.env.PUBLIC_URL}/assets/Toolkit/ISO-27001-ISO-22301-Consultant-Toolkit.webp`
const iso9001c =  `${process.env.PUBLIC_URL}/assets/Toolkit/ISO-9001-Consultant-Toolkit.webp`
const eugdpr =  `${process.env.PUBLIC_URL}/assets/Toolkit/EU-GDPR-Consultant.webp`
const iso13458eumdr =  `${process.env.PUBLIC_URL}/assets/Toolkit/ISO-13485-EU-MDR-Consultant-Toolkit.webp`
const iso20000c =  `${process.env.PUBLIC_URL}/assets/Toolkit/ISO-20000-Consultant-Toolkit.webp`
const iso14001c =  `${process.env.PUBLIC_URL}/assets/Toolkit/ISO-14001-Consultant-Toolkit.webp`
const iso45001c =  `${process.env.PUBLIC_URL}/assets/Toolkit/ISO-45001-Consultant-Toolkit.webp`
const iatf1649c =  `${process.env.PUBLIC_URL}/assets/Toolkit/IATF-16949-Consultant.webp`
const iso9001t =  `${process.env.PUBLIC_URL}/assets/Toolkit/ISO-9001-Transition-Toolkit.webp`
const iso45001t =  `${process.env.PUBLIC_URL}/assets/Toolkit/ISO-45001-Transition-Toolkit.webp`
const iso140012015t =  `${process.env.PUBLIC_URL}/assets/Toolkit/ISO-14001-Transition-Toolkit.webp`
const as9100dt =  `${process.env.PUBLIC_URL}/assets/Toolkit/AS9100-Rev-D-Transition-Toolkit.webp`
const as9100r =  `${process.env.PUBLIC_URL}/assets/Toolkit/AS9100-Rev-D-Transition-Toolkit.webp`

export const ToolkitData = {
  
  toolkitBasicData: [
    {
      name: ['ISO 27001', 'ISO 22301'],
      heading: 'ISO 27001 & ISO 22301 Premium Documentation Toolkit',
      img: iso2700122301,
      type: ['Multiple Standards', 'Regular Toolkits'],
      price: 199, 
    },
    {
      name: ['ISO 13485'],
      heading: 'ISO 13485 & MDR Integrated Documentation Toolkit',
      img: iso13485M,
      type: ['Multiple Standards'],
      price: 139, 
    },
    {
      name: ['ISO 9001'],
      heading: 'ISO 9001 Documentation Toolkit',
      img: is9001,
      type: ['Regular Toolkits', '9001'],
      price: 129, 
    },
    {
      name: ['ISO 14001'],
      heading: 'ISO 14001 Documentation Toolkit',
      img: iso14001,
      type: ['Regular Toolkits', 'ISO 14001'],
      price: 169, 
    },
    {
      name: ['ISO 22301'],
      heading: 'ISO 22301 Documentation Toolkit',
      img: iso22301,
      type: ['ISO 22301', 'Regular Toolkits'],
      price: 127, 
    },
    {
      name: ['ISO 27001', 'ISO 2022'],
      heading: 'ISO 27001 2022 Transition Toolkit',
      img: iso270012022,
      type: ['Regular Toolkits', 'ISO 27001'],
      price: 149, 
    },
    {
      name: ['ISO 27001'],
      heading: 'ISO 27001 Documentation Toolkit',
      img: iso27001,
      type: ['Regular Toolkits', 'ISO 27001'],
      price: 129, 
    },
    {
      name: ['ISO 45001'],
      heading: 'ISO 45001 Documentation Toolkit',
      img: iso45010,
      type: ['ISO 45001', 'Regular Toolkits'],
      price: 898, 
    },
    {
      name: ['EU GDPR'],
      heading: 'EU GDPR Documentation Toolkit',
      img: isoGDPR,
      type: ['EU GDPR', 'Regular Toolkits'],
      price: 899, 
    },
    {
      name: ['ISO 20000'],
      heading: 'ISO 20000 Documentation Toolkit',
      img: iso20000,
      type: ['ISO 20000', 'Regular Toolkits'],
      price: 439, 
    },
    {
      name: ['EU GDPR', 'ISO 27001'],
      heading: 'EU GDPR & ISO 27001 Integrated Documentation Toolkit',
      img: isogdpr27001,
      type: ['ISO GDPR', 'Multiple Standards'],
      price: 199, 
    },
    {
      name: ['ISO 27001', 'ISO 27017', 'ISO 27018'],
      heading: 'ISO 27001 & ISO 27017 & ISO 27018 Cloud Toolkit',
      img: iso270012701727018,
      type: ['Regular Toolkits', 'ISO 27001'],
      price: 179, 
    },
    {
      name: ['ISO 17025'],
      heading: 'ISO 17025 Documentation Toolkit',
      img: iso17025,
      type: ['Regular Toolkits', 'ISO 17025'],
      price: 149, 
    },
    {
      name: ['ISO 13485'],
      heading: 'ISO 13485 Documentation Toolkit',
      img: iso13485,
      type: ['Regular Toolkits', 'ISO 13485'],
      price: 129, 
    },
    {
      name: ['ISO 9001'],
      heading: 'ISO 9001 Premium Documentation Toolkit',
      img: iso9001P,
      type: ['Regular Toolkits', 'ISO 9001'],
      price: 199, 
    },
    {
      name: [],
      heading: 'ISO 9001, ISO 14001 AND ISO 45001 INTEGRATED DOCUMENTATION TOOLKIT',
      img: iso90011400145001,
      type: ['Multiple Standards'],
      price: 249, 
      description: '',
    },
    {
      name: ['EU GDPR'],
      heading: 'EU GDPR Premium Documentation Toolkit',
      img: isoeugdpr,
      type: ['Regular Toolkits', 'EU GDPR'],
      price: 199, 
      description: '',
    },
    {
      name: ['ISO 14001'],
      heading: 'ISO 14001 PREMIUM DOCUMENTATION TOOLKIT',
      img: iso14001p,
      type: ['Regular Toolkits', 'ISO 14001'],
      price: 199, 
      description: '',
    },
    {
      name: ['ISO 9001', 'ISO 14001'],
      heading: 'ISO 9001 & ISO 14001 INTEGRATED DOCUMENTATION TOOLKIT',
      img: iso900114001,
      type: ['Regular Toolkits', 'ISO 9001'],
      price: 229, 
      description: '',
    },
    {
      name: ['ISO 45001'],
      heading: 'ISO 45001 PREMIUM DOCUMENTATION TOOLKIT',
      img: iso45001p,
      type: ['Regular Toolkits', 'ISO 45001'],
      price: 199, 
      description: '',
    },
    {
      name: ['IATF 16949', 'IATF 2016'],
      heading: 'IATF 16949:2016 DOCUMENTATION TOOLKIT',
      img: iatf16949,
      type: ['Regular Toolkits', 'IATF 16949'],
      price: 249, 
      description: '',
    },
    {
      name: ['AS9100D'],
      heading: 'AS9100D DOCUMENTATION TOOLKIT',
      img: as9100d,
      type: ['Regular Toolkits', 'AS9100D'],
      price: 199, 
      description: '',
    },
    {
      name: ['ISO 22301'],
      heading: 'ISO 22301 BUSINESS IMPACT ANALYSIS TOOLKIT',
      img: iso22301B,
      type: ['Regular Toolkits', 'ISO 22301'],
      price: 149, 
      description: '',
    },
    {
      name: ['ISO 22301'],
      heading: 'ISO 22301 BUSINESS IMPACT ANALYSIS TOOLKIT',
      img: iso2700122301i,
      type: ['Regular Toolkits', 'ISO 22301'],
      price: 149, 
    },
    {
      name: ['ISO 27001', 'ISO 22301'],
      heading: 'ISO 27001/ISO 22301 RISK ASSESSMENT TOOLKIT',
      img: iso270012301r,
      type: ['Regular Toolkits', 'Multiple Standards'],
      price: 179, 
      description: '',
    },
    {
      name: ['ISO 9001'],
      heading: 'ISO 9001 INTERNAL AUDIT TOOLKIT',
      img: iso9001,
      type: ['Regular Toolkits', 'ISO 9001'],
      price: 149, 
      description: '',
    },
    {
      name: ['ISO 9001'],
      heading: 'ISO 9001:2015 RISK MANAGEMENT TOOLKIT',
      img: iso90012015,
      type: ['Regular Toolkits', 'ISO 9001'],
      price: 149, 
    },
    {
      name: ['ISO 14001'],
      heading: 'ISO 14001:2015 INTERNAL AUDIT TOOLKIT',
      img: iso140012015,
      type: ['Regular Toolkits', 'ISO 14001'],
      price: 149, 
    },
    {
      name: ["AS9100"],
      heading: 'AS9100D PREMIUM DOCUMENTATION TOOLKIT',
      img: as9100,
      type: ['Regular Toolkits', 'AS9100'],
      price: 199, 
    },
    {
      name: ['ISO 13485'],
      heading: 'ISO 13485 Internal Audit Toolkit',
      img: iso13485i,
      type: ['Regular Toolkits', 'ISO 13485'],
      price: 149, 
    },
    {
      name: ['ISO 20000'],
      heading: 'ITSM Change Management Toolkit',
      img: itsm,
      type: ['Regular Toolkits', 'ISO 20000'],
      price: 129, 
    },
    {
      name: ['ISO 20000'],
      heading: 'ITSM Business Relationship Management Toolkit',
      img: itsmB,
      type: ['Regular Toolkits', 'ISO 20000'],
      price: 129, 
    },
    {
      name: ['IATF 16949'],
      heading: 'IATF 16949:2016 Internal Audit Toolkit',
      img: iatf16949i,
      type: ['Regular Toolkits', 'IATF 16949'],
      price: 149, 
    },
    {
      name: ['ISO 45001'],
      heading: 'ISO 45001 Internal Audit Toolkit',
      img: iso45001i,
      type: ['Regular Toolkits', 'ISO 45001'],
      price: 149, 
    },
    {
      name: ['EU GDPR'],
      heading: 'EU GDPR Data Mapping & DPIA Toolkit',
      img: gdpr,
      type: ['Regular Toolkits', 'EU GDPR'],
      price: 129, 
    },
    {
      name: ['EU GDPR'],
      heading: 'GDPR Mini Toolkit for Websites',
      img: gdprm,
      type: ['Regular Toolkits', 'EU GDPR'],
      price: 99, 
    },
    {
      name: ['EU GDPR'],
      heading: 'GDPR Consent & Data Subject Rights Toolkit',
      img: gdprc,
      type: ['Regular Toolkits', 'EU GDPR'],
      price: 129, 
    },
    {
      name: ['ISO 27001', 'ISO 22301'],
      heading: 'ISO 27001 & ISO 22301 Consultant Toolkit',
      img: iso2700122301c,
      type: ['Toolkits for Consultants', 'Multiple Standards'],
      price: 299, 
    },
    {
      name: ['ISO 9001'],
      heading: 'ISO 9001 Consultant Toolkit',
      img: iso9001c,
      type: ['Toolkits for Consultants', 'ISO 9001'],
      price: 249, 
    },
    {
      name: ['EU GDPR'],
      heading: 'EU GDPR Consultant Toolkit',
      img: eugdpr,
      type: ['Toolkits for Consultants', 'EU GDPR'],
      price: 299, 
    },
    {
      name: ['ISO 13485'],
      heading: 'ISO 13485 & EU MDR Consultant Toolkit',
      img: iso13458eumdr,
      type: ['Multiple Standards', 'Toolkits for Consultants'],
      price: 249, 
    },
    {
      name: ['ISO 20000'],
      heading: 'ISO 20000 Consultant Toolkit',
      img: iso20000c,
      type: ['Toolkits for Consultants', 'ISO 20000'],
      price: 249, 
    },
    {
      name: ['ISO 14001'],
      heading: 'ISO 14001 Consultant Toolkit',
      img: iso14001c,
      type: ['Toolkits for Consultants', 'ISO 14001'],
      price: 249, 
    },
    {
      name: ['ISO 45001'],
      heading: 'ISO 45001 Consultant Toolkit',
      img: iso45001c,
      type: ['Toolkits for Consultants', 'ISO 45001'],
      price: 249, 
    },
    {
      name: ['IATF 16949'],
      heading: 'IATF 16949 Consultant Toolkit',
      img: iatf1649c,
      type: ['Toolkits for Consultants', 'IATF 16949'],
      price: 249, 
    },
    {
      name: ['ISO 9001'],
      heading: 'SO 9001 Transition Toolkit',
      img: iso9001t,
      type: ['Regular Toolkits', 'ISO 9001'],
      price: 149, 
    },
    {
      name: ['ISO 45001'],
      heading: 'ISO 45001 Transition Toolkit',
      img: iso45001t,
      type: ['Regular Toolkits', 'ISO 45001'],
      price: 149, 
    },
    {
      name: ['ISO 14001', 'ISO 2015'],
      heading: 'ISO 14001:2015 Transition Toolkit',
      img: iso140012015t,
      type: ['Regular Toolkits', 'ISO 14001'],
      price: 149, 
    },
    {
      name: ['AS9100'],
      heading: 'AS9100D Internal Audit Toolkit',
      img: as9100dt,
      type: ['Regular Toolkits', 'AS9100'],
      price: 149, 
    },
    {
      name: ['AS9100'],
      heading: 'AS9100 Rev D Transition Toolkit',
      img: as9100r,
      type: ['Regular Toolkits', 'AS9100'],
      price: 149, 
    },
  ],
};
