import { Helmet, HelmetProvider } from "react-helmet-async";
import Navbar from "./Navbar";
import Footer from "./Footer";

const Layout = ({
  title = "MBA Solutions - Leading ISO Certification Provider",
  description = "MBA Solutions offers ISO certification services, helping businesses achieve compliance with international standards such as ISO 9001, ISO 14001, ISO 27001, and more. Get certified to improve quality, security, and efficiency.",
  keywords = "ISO certification, ISO 9001, ISO 14001, ISO 27001, ISO compliance, quality management, certification services, ISO consultants, MBA Solutions",
  author = "MBA Solutions",
  children,
}) => {
  return (
    <HelmetProvider>
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <meta name="description" content={description} />
          <meta name="keywords" content={keywords} />
          <meta name="author" content={author} />
          <title>{title}</title>
        </Helmet>
        <Navbar />
        {children}
        <Footer />
      </div>
    </HelmetProvider>
  );
};

export default Layout;
