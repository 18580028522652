import React from 'react'
import ContactshortForm from '../contact/ContactSection4'
import PecbSection1 from './PecbSection1';
import PecbSection2 from './PecbSection2';
import PecbSection3 from './PecbSection3';
import HomeSection3 from '../Home/HomeSection3';
import Layout from '../../components/Layout';



const Pecb = () => {

  return (
    <Layout title={"MBA Solutions - Pecb "} >
    
    
      <PecbSection1 />
 
 <div className='mt-[65vh]'>
  <PecbSection2 />
 </div>
 <div className='mt-12'>
 <PecbSection3 />
 </div>
 <div className='mt-24'>
 <HomeSection3 />
 </div>
 <div className='mt-[-4rem]'>
      <ContactshortForm />
      </div>
    </Layout>
  )
}

export default Pecb