const hospital =`${process.env.PUBLIC_URL}/assets/services/hospital.webp`;
const construction =`${process.env.PUBLIC_URL}/assets/services/construction.webp`;
const shugarcane =`${process.env.PUBLIC_URL}/assets/services/shugercane.webp`;
const rice =`${process.env.PUBLIC_URL}/assets/services/rice.webp`;
const floor =`${process.env.PUBLIC_URL}/assets/services/floor.webp`;
const it =`${process.env.PUBLIC_URL}/assets/services/it.webp`;
const textile =`${process.env.PUBLIC_URL}/assets/services/textile.webp`;
const sports =`${process.env.PUBLIC_URL}/assets/services/sport.webp`;
const surgical =`${process.env.PUBLIC_URL}/assets/services/surgical.webp`;
const automobile =`${process.env.PUBLIC_URL}/assets/services/automobile.webp`;
const fertilizer =`${process.env.PUBLIC_URL}/assets/services/fertilizer.webp`;
const chemical =`${process.env.PUBLIC_URL}/assets/services/chemical.webp`;
const cement =`${process.env.PUBLIC_URL}/assets/services/cement.webp`;
const education =`${process.env.PUBLIC_URL}/assets/services/education.webp`;
const himalayan =`${process.env.PUBLIC_URL}/assets/services/himalyan.webp`;
const food =`${process.env.PUBLIC_URL}/assets/services/food.webp`;
const banks =`${process.env.PUBLIC_URL}/assets/services/bank.webp`;
const restaurants =`${process.env.PUBLIC_URL}/assets/services/restaurants.webp`;
const bone =`${process.env.PUBLIC_URL}/assets/services/bone.webp`;
const packaging =`${process.env.PUBLIC_URL}/assets/services/packaging.webp`;

export const ServicesData = {
  services: [
    {
      name: "Hospital",
      link: "/hospital",
      img: hospital,
    },
    {
      name: "Construction and contractor companies",
      link: "/construction and contractor companies",
      img: construction,
    },
    {
      name: "Sugarcane Industry",
      link: "/sugarcane industry",
      img: shugarcane,
    },
    {
      name: "Flour Mills",
      link: "/flour mills",
      img: floor,
    },
    {
      name: "Rice Industries",
      link: "/rice industries",
      img: rice,
    },
    {
      name: "IT Companies",
      link: "/it companies",
      img: it,
    },
    {
      name: "Textile industries",
      link: "/textile industries",
      img: textile,
    },
    {
      name: "Sports industry",
      link: "/sports industry",
      img: sports,
    },
    {
      name: "Surgical industry",
      link: "/surgical industry",
      img: surgical,
    },
    {
      name: "Automobile industry",
      link: "/automobile industry",
      img: automobile,
    },
    {
      name: "Fertilizer industry",
      link: "/fertilizer industry",
      img: fertilizer,
    },
    {
      name: "Chemical industry",
      link: "/chemical industry",
      img: chemical,
    },
    {
      name: "Cement industry",
      link: "/cement industry",
      img: cement,
    },
    {
      name: "Educational universities",
      link: "/educational universities",
      img: education,
    },
    {
      name: "Pink Himalayan Salt",
      link: "/pink himalayan salt",
      img: himalayan,
    },
    {
      name: "Food industry",
      link: "/food industry",
      img: food,
    },
    {
      name: "Banks",
      link: "/banks",
      img: banks,
    },
    {
      name: "Restaurants",
      link: "/restaurants",
      img: restaurants,
    },
    {
      name: "Bone crushing mills",
      link: "/bone crushing mills",
      img: bone,
    },
    {
      name: "Packaging industry",
      link: "/packaging industry",
      img: packaging,
    },
  ],



  servicepage2data: [
    {
      name: "Hospital",
      shortdes:
        "Hospitals and healthcare facilities are subject to various ISO standards to ensure the quality, safety, and effectiveness of healthcare services and management. Some of the key ISO standards applicable to hospitals include:",
      points: [
            {
                code: "ISO 9001",
                title: "Quality Management System (QMS)",
                description:
                  "ISO 9001 sets the standards for a quality management system applicable to all types of organizations, including hospitals. Hospitals can use ISO 9001 to improve the quality of patient care, manage resources efficiently, and enhance overall hospital operations.",
              },
              {
                code: "ISO 13485",
                title: "Medical Devices Quality Management System",
                description:
                  "ISO 13485 is specifically designed for organizations involved in the manufacturing, distribution, and servicing of medical devices. Hospitals that handle medical devices or have their own medical device manufacturing facilities can benefit from this standard.",
              },
              {
                code: "ISO 15189",
                title: "Medical Laboratories Quality Management System",
                description:
                  "ISO 15189 is tailored for medical laboratories, including those within hospitals. It focuses on ensuring the accuracy and reliability of laboratory test results, which are crucial for patient diagnosis and treatment.",
              },
              {
                code: "ISO 14001",
                title: "Environmental Management System (EMS)",
                description:
                  "Hospitals can adopt ISO 14001 to manage their environmental impact. This standard helps hospitals reduce waste, energy consumption, and pollution, contributing to environmental sustainability.",
              },
              {
                code: "ISO 45001",
                title: "Occupational Health and Safety Management System (OHSMS)",
                description:
                  "ISO 45001 focuses on occupational health and safety within hospitals. It helps hospitals create safer working environments for staff, reduce workplace accidents, and ensure the wellbeing of patients and employees.",
              },
              {
                code: "ISO 26000",
                title: "Social Responsibility",
                description:
                  "While not specific to hospitals, ISO 26000 provides guidance on social responsibility. Hospitals can use this standard to assess and improve their social and ethical responsibilities in their communities.",
              },
              {
                code: "ISO 22301",
                title: "Business Continuity Management System (BCMS)",
                description:
                  "Hospitals can adopt ISO 22301 to ensure they can continue providing critical healthcare services during emergencies, such as natural disasters or pandemics.",
              },
              {
                code: "ISO 31000",
                title: "Risk Management",
                description:
                  "Hospitals can use ISO 31000 to develop a systematic approach to identifying, assessing, and managing risks in healthcare delivery, patient safety, and administrative processes.",
              },
              {
                code: "ISO 50001",
                title: "Energy Management System (EnMS)",
                description:
                  "Hospitals can implement ISO 50001 to improve energy efficiency, reduce energy costs, and lower their carbon footprint.",
              },
              {
                code: "ISO 10002",
                title: "Customer Satisfaction and Complaint Handling",
                description:
                  "ISO 10002 provides guidelines for managing customer feedback and complaints, which is essential for maintaining patient satisfaction in hospitals.",
              },
        ],
      },
    {
        name:"Construction and contractor companies",
        shortdes:"Construction and contractor companies are subject to various ISO standards that help ensure quality, safety, and environmental responsibility in their operations. The most relevant ISO standards for construction and contractor companies include:" ,
              points: [
            {
                code: "ISO 9001",
                title: "Quality Management System (QMS)",
                description:
                  "ISO 9001 sets the standards for a quality management system applicable to all types of organizations, including construction and contractor firms. It helps ensure consistent quality in construction projects, from planning and design to execution and completion.",
              },
              {
                code: "ISO 14001",
                title: "Environmental Management System (EMS)",
                description:
                  "ISO 14001 provides guidelines for managing environmental impact in construction projects. It helps construction companies minimize their environmental footprint by addressing issues such as waste management, resource conservation, and pollution control.",
              },
              {
                code: "ISO 45001",
                title: "Occupational Health and Safety Management System (OHSMS)",
                description:
                  "ISO 45001 focuses on occupational health and safety within construction sites. It helps construction companies create safer working environments, reduce workplace accidents, and ensure the wellbeing of employees.",
              },
              {
                code: "ISO 19650",
                title: "Building Information Modeling (BIM)",
                description:
                  "ISO 19650 provides a framework for managing information throughout the life cycle of a built asset. It is particularly relevant to construction companies utilizing BIM technology for project management and design coordination.",
              },
              {
                code: "ISO 22301",
                title: "Business Continuity Management System (BCMS)",
                description:
                  "Construction firms can adopt ISO 22301 to ensure they can continue operations and manage potential disruptions, such as natural disasters or unforeseen events, that could impact construction projects.",
              },
              {
                code: "ISO 31000",
                title: "Risk Management",
                description:
                  "ISO 31000 provides guidelines for risk management in construction projects. It helps construction companies identify, assess, and mitigate risks associated with project delivery, safety, and quality.",
              },
              {
                code: "ISO 50001",
                title: "Energy Management System (EnMS)",
                description:
                  "Construction companies can implement ISO 50001 to improve energy efficiency in construction processes, reduce energy costs, and minimize their environmental impact.",
              },
              {
                code: "ISO 38342",
                title: "Quality Management for Welding",
                description:
                  "ISO 38342 provides specific quality management requirements for fusion welding processes used in construction and manufacturing.",
              },
              {
                code: "ISO 14731",
                title: "Safety in Welding",
                description:
                  "ISO 14731 outlines requirements for safety and quality in welding processes commonly used in construction projects.",
              },
              {
                code: "ISO 10006",
                title: "Project Management",
                description:
                  "ISO 10006 offers guidelines for effective project management, which is crucial for ensuring the successful completion of construction projects on time and within budget.",
              },
        ],
      
    },
    {
      name: "Sugarcane Industry",
      shortdes:
        "Certainly, here's the revised list of ISO standards applicable to the sugar industry, including the 'Bonsucro' standard, which is relevant for sustainable sugarcane production:",
      points: [
        {
          code: "ISO 9001",
          title: "Quality Management System (QMS)",
          description:
            "ISO 9001 sets the standards for a quality management system applicable to all types of organizations, including those in the sugar industry. It helps sugar manufacturers maintain consistent product quality, streamline processes, and meet customer and regulatory requirements.",
        },
        {
          code: "ISO 22000",
          title: "Food Safety Management System (FSMS)",
          description:
            "ISO 22000 is relevant to sugar companies producing food-grade sugar products. It establishes requirements for food safety management, ensuring that sugar products are safe for consumption.",
        },
        {
          code: "ISO 14001",
          title: "Environmental Management System (EMS)",
          description:
            "ISO 14001 provides guidelines for managing the environmental impact of sugar production processes. It helps sugar companies address issues such as waste management, water usage, and pollution control.",
        },
        {
          code: "ISO 45001",
          title: "Occupational Health and Safety Management System (OHSMS)",
          description:
            "ISO 45001 focuses on occupational health and safety within sugar manufacturing facilities. It helps sugar companies create safer working environments, reduce workplace accidents, and protect the health of employees.",
        },
        {
          code: "ISO 50001",
          title: "Energy Management System (EnMS)",
          description:
            "Sugar companies can implement ISO 50001 to improve energy efficiency, reduce energy costs, and minimize their environmental impact in sugar processing and refining.",
        },
        {
          code: "ISO 14064",
          title: "Greenhouse Gas (GHG) Management",
          description:
            "ISO 14064 provides guidelines for measuring, quantifying, and managing greenhouse gas emissions. It is relevant to sugar companies interested in reducing their carbon footprint and participating in sustainability initiatives.",
        },
        {
          code: "ISO 22005",
          title: "Traceability in the Feed and Food Chain",
          description:
            "ISO 22005 helps ensure the traceability of sugar products from production to consumption, enhancing transparency and food safety in the supply chain.",
        },
        {
          code: "ISO 26000",
          title: "Social Responsibility",
          description:
            "ISO 26000 provides guidance on social responsibility, including ethical and social aspects of business operations. Sugar companies can use it to address social and ethical concerns related to their operations and supply chain.",
        },
        {
          code: "ISO 9001",
          title: "Quality Management for Sugar Production Equipment",
          description:
            "ISO 1735 provides specific quality management requirements for sugar production equipment, ensuring the reliability and performance of machinery used in the industry.",
        },
        {
          code: "ISO 3166",
          title: "International Standard for Country Codes",
          description:
            "ISO 3166 is relevant for sugar companies engaged in international trade as it provides standardized country codes used in shipping and logistics.",
        },
        {
          code: "Bonsucro Standard",
          title: "Sustainable Sugarcane Production",
          description:
            "The Bonsucro standard is specifically focused on sustainable sugarcane production. It provides guidelines for environmentally and socially responsible sugarcane cultivation, including issues related to land use, water management, labor practices, and biodiversity conservation.",
        },
      ],
    },
    {
      name: "Flour Mills",
      shortdes:
        "Certainly, here's the revised list of ISO and GFSI (Global Food Safety Initiative) standards applicable to flour mills, including FSSC 22000, BRCGS (British Retail Consortium Global Standards), IFS (International Featured Standards), and SQF (Safe Quality Food) standards:",
      points: [
        {
          code: "ISO 22000",
          title: "Food Safety Management System (FSMS)",
          description:
            "ISO 22000 remains crucial for flour mills, establishing requirements for food safety management, ensuring safe flour products.",
        },
        {
          code: "ISO 9001",
          title: "Quality Management System (QMS)",
          description:
            "ISO 9001 focuses on quality management and continuous improvement in flour production.",
        },
        {
          code: "ISO 14001",
          title: "Environmental Management System (EMS)",
          description:
            "ISO 14001 addresses environmental impact management within flour milling operations.",
        },
        {
          code: "ISO 45001",
          title: "Occupational Health and Safety Management System (OHSMS)",
          description:
            "ISO 45001 ensures the safety and well-being of employees in flour mills.",
        },
        {
          code: "ISO 50001",
          title: "Energy Management System (EnMS)",
          description:
            "ISO 50001 helps improve energy efficiency and reduce energy costs in milling processes.",
        },
        {
          code: "ISO 22005",
          title: "Traceability in the Feed and Food Chain",
          description:
            "ISO 22005 enhances supply chain transparency and food safety.",
        },
        {
          code: "ISO 26000",
          title: "Social Responsibility",
          description:
            "ISO 26000 addresses social responsibility and ethical concerns in flour milling operations and the supply chain.",
        },
        {
          code: "ISO 30301",
          title: "Records Management",
          description:
            "ISO 30301 aids in effective records management, which is essential for compliance and traceability.",
        },
        {
          code: "ISO 31000",
          title: "Risk Management",
          description:
            "ISO 31000 provides guidelines for risk management in flour milling operations.",
        },
        {
          code: "ISO 22002",
          title: "Prerequisite Programs for Food Safety",
          description:
            "ISO 22002-1 and ISO 22002-4 specifically offer guidance on prerequisite programs for food safety within flour mills.",
        },
        {
          code: "ISO 9001",
          title: "Quality Management for Milling Equipment",
          description:
            "ISO 20483 outlines quality management requirements for milling equipment used in flour mills.",
        },
        {
          code: "FSSC 22000",
          title: "Food Safety System Certification",
          description:
            "FSSC 22000 is a GFSI-recognized certification scheme that integrates ISO 22000 with additional requirements. It is highly relevant for ensuring food safety in flour production.",
        },
        {
          code: "BRCGS (British Retail Consortium Global Standards)",
          title: "BRCGS (British Retail Consortium Global Standards)",
          description:
            "BRCGS sets global food safety and quality standards, including those relevant to flour mills. Certification under BRCGS demonstrates commitment to food safety and quality.",
        },
        {
          code: "IFS (International Featured Standards)",
          title: "IFS (International Featured Standards)",
          description:
            "IFS provides food safety and quality standards for flour mills, ensuring product safety and consumer protection. It is widely recognized in Europe and beyond.",
        },
        {
          code: "SQF (Safe Quality Food)",
          title: "SQF (Safe Quality Food)",
          description:
            "SQF is another GFSI-recognized certification program that helps ensure the safety and quality of food products, including flour. It is important for demonstrating compliance with global food safety standards.",
        },
      ],
    },
    {
      name: "Rice Industries",
      shortdes:
        "Certainly, here's the extended list of ISO, GFSI (Global Food Safety Initiative), social, and other relevant standards applicable to the rice industry, including Halal certification, GMP (Good Manufacturing Practices), and Iranian GMP (Iran's version of GMP):",
      points: [
        {
          code: "ISO 22000",
          title: "Food Safety Management System (FSMS)",
          description:
            "ISO 22000 is crucial for the rice industry, establishing requirements for food safety management to ensure the safety of rice products.",
        },
        {
          code: "ISO 9001",
          title: "Quality Management System (QMS)",
          description:
            "ISO 9001 focuses on quality management and continuous improvement in rice production and processing.",
        },
        {
          code: "ISO 14001",
          title: "Environmental Management System (EMS)",
          description:
            "ISO 14001 addresses environmental impact management within rice milling and processing operations.",
        },
        {
          code: "ISO 45001",
          title: "Occupational Health and Safety Management System (OHSMS)",
          description:
            "ISO 45001 ensures the safety and well-being of employees in rice facilities.",
        },
        {
          code: "ISO 50001",
          title: "Energy Management System (EnMS)",
          description:
            "ISO 50001 helps improve energy efficiency and reduce energy costs in rice production and processing.",
        },
        {
          code: "ISO 22005",
          title: "Traceability in the Food Chain",
          description:
            "ISO 22005 enhances supply chain transparency and traceability, which is essential for food safety.",
        },
        {
          code: "ISO 26000",
          title: "Social Responsibility",
          description:
            "ISO 26000 addresses social responsibility and ethical concerns in rice production, processing, and supply chain activities.",
        },
        {
          code: "ISO 30301",
          title: "Records Management",
          description:
            "ISO 30301 aids in effective records management, crucial for compliance and traceability in the rice industry.",
        },
        {
          code: "ISO 31000",
          title: "Risk Management",
          description:
            "ISO 31000 provides guidelines for risk management in rice production, processing, and distribution.",
        },
        {
          code: "ISO 22002",
          title: "Prerequisite Programs for Food Safety",
          description:
            "ISO 22002-1 and ISO 22002-4 offer guidance on prerequisite programs for food safety within the rice industry.",
        },
        {
          code: "ISO 9001",
          title: "Quality Management for Rice Milling Equipment",
          description:
            "ISO 20483 outlines quality management requirements for rice milling equipment used in rice mills.",
        },
        {
          code: "FSSC 22000",
          title: "Food Safety System Certification",
          description:
            "FSSC 22000 is a GFSI-recognized certification scheme that integrates ISO 22000 with additional requirements, ensuring food safety in rice production and processing.",
        },
        {
          code: "BRCGS (British Retail Consortium Global Standards)",
          title: "BRCGS (British Retail Consortium Global Standards)",
          description:
            "BRCGS sets global food safety and quality standards, including those relevant to the rice industry. Certification under BRCGS demonstrates commitment to food safety and quality.",
        },
        {
          code: "IFS (International Featured Standards)",
          title: "IFS (International Featured Standards)",
          description:
            "IFS provides food safety and quality standards for rice production and processing, ensuring product safety and consumer protection. It is widely recognized in Europe and beyond.",
        },
        {
          code: "SQF (Safe Quality Food)",
          title: "SQF (Safe Quality Food)",
          description:
            "SQF is another GFSI-recognized certification program that helps ensure the safety and quality of food products, including rice. It is important for demonstrating compliance with global food safety standards.",
        },
        {
          code: "Halal Certification",
          title: "Halal Certification",
          description:
            "Halal certification ensures that rice products comply with Islamic dietary laws, making them suitable for Muslim consumers.",
        },
        {
          code: "Good Manufacturing Practices (GMP)",
          title: "Good Manufacturing Practices (GMP)",
          description:
            "GMP guidelines ensure the quality and safety of rice products during manufacturing processes.",
        },
        {
          code: "Iranian GMP",
          title: "Iranian GMP",
          description:
            "Iran has its version of GMP, which is relevant for rice production and processing facilities operating in Iran.",
        },
        {
          code: "EcoVidas",
          title: "EcoVidas",
          description:
            "EcoVidas is a social and environmental standard designed for the agricultural sector, including rice production. It focuses on promoting sustainable farming practices, ensuring worker rights and well-being, and minimizing the environmental impact of agricultural operations.",
        },
        {
          code: "Sedex (Supplier Ethical Data Exchange)",
          title: "Sedex (Supplier Ethical Data Exchange)",
          description:
            "Sedex is a global platform that helps organizations manage and improve social and ethical performance in their supply chains. It facilitates the sharing of ethical data and audits, ensuring transparency and accountability in supplier relationships.",
        },
        {
          code: "BSCI (Business Social Compliance Initiative)",
          title: "BSCI (Business Social Compliance Initiative)",
          description:
            "BSCI is a widely recognized social standard that aims to improve labor conditions in supply chains, including those related to rice production and processing. It focuses on principles such as fair wages, safe working conditions, and workers' rights.",
        },
        {
          code: "Fair Trade",
          title: "Fair Trade",
          description:
            "Fair Trade certification promotes fair and ethical trade practices, ensuring that rice farmers and workers receive fair wages and decent working conditions. It also encourages sustainable farming practices and community development.",
        },
        {
          code: "Rice Sustainable Standard",
          title: "Rice Sustainable Standard",
          description:
            "The Rice Sustainable Standard is a comprehensive standard specifically tailored for sustainable rice production. It encompasses social, environmental, and economic aspects of sustainability, including responsible water use, reduced chemical inputs, and adherence to social and labor standards.",
        },
      ],
    },
    {
      name: "IT Companies",
      shortdes:
        "IT companies are subject to various ISO standards and security measures to ensure the quality, security, and compliance of their services. Here are key ISO standards and security practices applicable to IT companies:",
      points: [
        {
          code: "ISO 27001",
          title: "Information Security Management System (ISMS)",
          description:
            "ISO 27001 is a globally recognized standard for information security management. It provides a systematic approach to managing and protecting sensitive information, including data security, risk management, and compliance with legal and regulatory requirements.",
        },
        {
          code: "ISO 9001",
          title: "Quality Management System (QMS)",
          description:
            "ISO 9001 is a standard for quality management applicable to IT service providers. It focuses on quality assurance, customer satisfaction, and continuous improvement of IT services.",
        },
        {
          code: "ISO 20000",
          title: "IT Service Management (ITSM)",
          description:
            "ISO 20000 defines requirements for IT service management systems, ensuring the delivery of high-quality IT services. It covers areas like service delivery, incident management, and service level agreements (SLAs).",
        },
        {
          code: "ISO 22301",
          title: "Business Continuity Management System (BCMS)",
          description:
            "ISO 22301 provides guidelines for IT organizations to establish and maintain business continuity plans, ensuring the availability of IT services during disruptions or disasters.",
        },
        {
          code: "ISO 31000",
          title: "Risk Management",
          description:
            "ISO 31000 offers principles and guidelines for IT organizations to implement effective risk management strategies, covering risk assessment, mitigation, and monitoring.",
        },
        {
          code: "Penetration Testing Standards",
          title: "Penetration Testing Standards",
          description:
            "Penetration testing standards, such as the NIST Special Publication 800-115 and the OWASP Testing Guide, provide guidelines and best practices for conducting penetration testing to assess IT system vulnerabilities and security weaknesses.",
        },
        {
          code: "SOC 1 (SSAE 18)",
          title: "Service Organization Control 1",
          description:
            "SOC 1 is an audit and reporting standard that focuses on internal controls over financial reporting. It is relevant for IT service providers that impact their clients' financial statements.",
        },
        {
          code: "SOC 2",
          title: "Service Organization Control 2",
          description:
            "SOC 2 is an auditing standard that assesses the security, availability, processing integrity, confidentiality, and privacy of IT systems and services. It is often used by IT service providers to demonstrate security and compliance to clients.",
        },
        {
          code: "HIPAA",
          title: "Health Insurance Portability and Accountability Act",
          description:
            "HIPAA is a U.S. regulatory standard applicable to healthcare organizations and their IT systems. It sets privacy and security standards for protecting patient health information (PHI) and mandates compliance for IT systems handling PHI.",
        },
        {
          code: "GDPR",
          title: "General Data Protection Regulation",
          description:
            "GDPR is a European Union regulation that affects IT organizations that process personal data of EU citizens. It imposes strict requirements for data protection, privacy, and breach reporting.",
        },
        {
          code: "ISO 27701",
          title: "Privacy Information Management System (PIMS)",
          description:
            "ISO 27701 is an extension of ISO 27001, focusing on privacy management and protection of personal data. It helps IT organizations comply with data privacy regulations like GDPR.",
        },
      ],
    },
    {
      name: "Textile Industries",
      shortdes:
        "Textile industries are subject to various ISO standards and textile-related practices to ensure product quality, environmental sustainability, and safety. Here are key ISO standards and textile-related information applicable to textile manufacturers:",
      points: [
        {
          code: "ISO 9001",
          title: "Quality Management System (QMS)",
          description:
            "ISO 9001 sets the standards for a quality management system applicable to all types of organizations, including textile manufacturers. It helps ensure consistent product quality, process efficiency, and customer satisfaction in textile production.",
        },
        {
          code: "ISO 14001",
          title: "Environmental Management System (EMS)",
          description:
            "ISO 14001 provides guidelines for managing the environmental impact of textile manufacturing processes. It addresses issues such as waste management, resource conservation, and pollution control, promoting sustainable practices in the industry.",
        },
        {
          code: "ISO 45001",
          title: "Occupational Health and Safety Management System (OHSMS)",
          description:
            "ISO 45001 focuses on occupational health and safety within textile manufacturing facilities. It helps textile companies create safer working environments, reduce workplace accidents, and protect the health of employees.",
        },
        {
          code: "ISO 50001",
          title: "Energy Management System (EnMS)",
          description:
            "ISO 50001 assists textile manufacturers in improving energy efficiency, reducing energy costs, and minimizing their environmental footprint in production processes.",
        },
        {
          code: "ISO 11111-1",
          title: "Quality Management for Textile Machinery",
          description:
            "ISO 11111-1 provides specific quality management requirements for textile machinery, ensuring the reliability and performance of equipment used in textile production.",
        },
        {
          code: "ISO 2076",
          title: "International Yarn Numbering System",
          description:
            "ISO 2076 standardizes the numbering system for textile yarns, providing a common language for yarn identification and classification.",
        },
        {
          code: "ISO 105",
          title: "Textile Colorfastness Testing",
          description:
            "The ISO 105 series comprises multiple parts covering colorfastness testing methods for textiles. It ensures that textile products maintain their color quality and durability during use and laundering.",
        },
        {
          code: "ISO 105-B",
          title: "Methods for Fastness to Artificial Light",
          description:
            "ISO 105-B standards specifically focus on evaluating the colorfastness of textiles when exposed to artificial light sources.",
        },
        {
          code: "ISO 1833",
          title: "Quantitative Chemical Analysis of Textiles",
          description:
            "ISO 1833 provides methods for the quantitative analysis of the fiber composition of textiles, helping to ensure accurate labeling and compliance with textile content regulations.",
        },
        {
          code: "ISO 3758",
          title: "Care Labeling Code Using Symbols",
          description:
            "ISO 3758 standardizes care labeling symbols used on textile products to provide consumers with clear instructions for proper care and maintenance.",
        },
        {
          code: "OEKO-TEX® Standards",
          title: "OEKO-TEX® Standards",
          description:
            "While not an ISO standard, OEKO-TEX® certification and testing systems are widely used in the textile industry to assess and certify that textile products meet strict criteria for human ecological safety and environmental responsibility.",
        },
      ],
    },
    {
      name: "Sports Industry",
      shortdes:
        "The sports industry encompasses a wide range of activities, including sports equipment manufacturing, event management, and sports facility operations. It adheres to various ISO standards and practices to ensure quality, sustainability, safety, and social responsibility. Here are key ISO standards and relevant information applicable to the sports industry:",
      points: [
        {
          code: "ISO 9001",
          title: "Quality Management System (QMS)",
          description:
            "ISO 9001 is a general quality management standard applicable to organizations involved in sports equipment manufacturing, event management, and sports facility operations. It helps ensure quality products and services, customer satisfaction, and continuous improvement.",
        },
        {
          code: "ISO 14001",
          title: "Environmental Management System (EMS)",
          description:
            "ISO 14001 provides guidelines for managing the environmental impact of sports facilities, events, and equipment manufacturing. It helps organizations reduce their environmental footprint and promote sustainable practices.",
        },
        {
          code: "ISO 45001",
          title: "Occupational Health and Safety Management System (OHSMS)",
          description:
            "ISO 45001 focuses on occupational health and safety in sports facilities, events, and organizations. It helps ensure the safety and well-being of athletes, staff, and spectators.",
        },
        {
          code: "ISO 20121",
          title: "Event Sustainability Management System",
          description:
            "ISO 20121 offers a framework for planning and implementing sustainable events within the sports industry. It covers areas such as environmental impact, social responsibility, and economic benefits.",
        },
        {
          code: "ISO 26000",
          title: "Social Responsibility",
          description:
            "ISO 26000 provides guidance on social responsibility, including ethical and social aspects of business operations in the sports industry. It addresses issues such as community engagement, diversity, and fair practices.",
        },
        {
          code: "ISO 14000 Series",
          title: "Environmental Management Standards",
          description:
            "Various standards within the ISO 14000 series, such as ISO 14004 and ISO 14040, are relevant for organizations seeking to manage environmental aspects associated with their sports-related activities.",
        },
        {
          code: "ISO 20118",
          title: "Sustainable Events - Event Sustainability Management Systems",
          description:
            "ISO 20118 focuses specifically on sustainability in events and can be applied to sports events to ensure responsible environmental and social practices.",
        },
        {
          code: "ISO 8601",
          title: "Date and Time Format",
          description:
            "ISO 8601 provides a standardized date and time format, which is important for scheduling and organizing sports events, competitions, and broadcasts.",
        },
        {
          code: "ISO 8601-6",
          title: "Representation of Dates and Times - Part 6: Guidelines for Expanding the Calendar",
          description:
            "This part of ISO 8601 provides guidelines for extending the calendar to handle dates beyond the year 9999, which can be relevant for long-term planning in the sports industry.",
        },
        {
          code: "ISO 10993",
          title: "Biological Evaluation of Medical Devices (relevant for sports equipment)",
          description:
            "ISO 10993 standards address the biological safety of medical devices, some of which are applicable to sports equipment, especially when it involves direct contact with the human body.",
        },
      ],
    },
    {
      name: "Surgical Industry",
      shortdes:
        "The surgical industry, particularly surgical instrument manufacturers, adheres to ISO standards to ensure the safety, quality, and compliance of their products. Here are key ISO standards and relevant information applicable to the surgical industry:",
      points: [
        {
          code: "ISO 13485",
          title: "Medical Devices - Quality Management Systems - Requirements for Regulatory Purposes",
          description:
            "ISO 13485 is a globally recognized standard specific to the medical device industry, including surgical instruments. It sets requirements for establishing and maintaining a quality management system (QMS) to ensure compliance with regulatory and safety standards. ISO 13485 covers various aspects, including design, development, manufacturing, labeling, packaging, and distribution of medical devices, including surgical instruments.",
        },
        {
          code: "ISO 11137",
          title: "Sterilization of Health Care Products - Radiation",
          description:
            "ISO 11137 provides guidelines for the sterilization of healthcare products, including surgical instruments, using radiation methods. It ensures the safety and efficacy of sterilization processes.",
        },
        {
          code: "ISO 17664",
          title: "Sterilization of Medical Devices - Information to be Provided by the Manufacturer for the Processing of Resterilizable Medical Devices",
          description:
            "ISO 17664 outlines the information that surgical instrument manufacturers must provide to users for the proper processing and re-sterilization of medical devices.",
        },
        {
          code: "ISO 7153",
          title: "Surgical Instruments - Materials",
          description:
            "ISO 7153 provides standards for the materials used in surgical instruments, ensuring their quality, durability, and safety.",
        },
        {
          code: "ISO 10993",
          title: "Biological Evaluation of Medical Devices (relevant for materials and biocompatibility)",
          description:
            "ISO 10993 standards address the biological safety and biocompatibility of materials used in medical devices, including surgical instruments.",
        },
        {
          code: "ISO 6009",
          title: "Surgical Instruments - Non-cutting, Sintered Metal Instruments - General Requirements and Test Methods",
          description:
            "ISO 6009 specifies requirements and test methods for non-cutting, sintered metal surgical instruments, ensuring their quality and performance.",
        },
        {
          code: "ISO 7157",
          title: "Surgical Instruments - Materials",
          description:
            "ISO 7157 provides standards for the materials used in surgical instruments, including requirements for corrosion resistance, biocompatibility, and mechanical properties.",
        },
      ],
    },
    {
      name: "Automobile Industry",
      shortdes:
        "The automobile industry adheres to various ISO standards to ensure quality, safety, and environmental responsibility. Here are key ISO standards and relevant information applicable to the automobile industry:",
      points: [
        {
          code: "ISO 9001",
          title: "Quality Management System (QMS)",
          description:
            "ISO 9001 is a general quality management standard applicable to all types of organizations, including those in the automotive sector. It helps ensure consistent product quality, customer satisfaction, and continuous improvement in automotive manufacturing.",
        },
        {
          code: "ISO 14001",
          title: "Environmental Management System (EMS)",
          description:
            "ISO 14001 provides guidelines for managing the environmental impact of automotive manufacturing processes. It addresses issues such as waste management, resource conservation, and pollution control, promoting sustainable practices in the industry.",
        },
        {
          code: "ISO/TS 16949",
          title: "Quality Management System for Automotive Production and Relevant Service Parts Organizations",
          description:
            "ISO/TS 16949 is a specific automotive industry standard based on ISO 9001. It sets requirements for quality management systems in the automotive supply chain, emphasizing continuous improvement and defect prevention.",
        },
        {
          code: "ISO 26262",
          title: "Road Vehicles - Functional Safety",
          description:
            "ISO 26262 is a critical standard for automotive safety, focusing on functional safety in electronic and electrical systems within road vehicles. It ensures the safety of automotive electronic components, software, and systems.",
        },
        {
          code: "ISO 16750",
          title: "Environmental Testing of Electrical and Electronic Equipment for Road Vehicles",
          description:
            "ISO 16750 specifies environmental testing requirements for electrical and electronic equipment used in road vehicles, ensuring their durability and performance under various conditions.",
        },
        {
          code: "ISO 179-1",
          title: "Plastics - Determination of Charpy Impact Properties - Part 1: Non-instrumented Impact Test",
          description:
            "ISO 179-1 outlines procedures for testing the impact resistance of plastics used in automotive components, helping to ensure their safety and performance.",
        },
        {
          code: "ISO 16232",
          title: "Cleanliness of Components of Fluid Circuits in Road Vehicles",
          description:
            "ISO 16232 provides guidelines for assessing the cleanliness of components within fluid circuits in road vehicles. It is critical for the reliability of automotive hydraulic and fuel systems.",
        },
        {
          code: "ISO 6469",
          title: "Electric Road Vehicles",
          description:
            "ISO 6469 sets safety requirements for electric road vehicles, including electric cars and electric vehicle components, to ensure their safe operation and use.",
        },
        {
          code: "ISO 9241",
          title: "Ergonomics of Human-System Interaction (relevant for in-car user interfaces)",
          description:
            "ISO 9241 standards address the ergonomics of human-system interaction, including in-car user interfaces, to enhance the usability and safety of automotive control systems.",
        },
        {
          code: "ISO 14040",
          title: "Environmental Management - Life Cycle Assessment (LCA)",
          description:
            "ISO 14040 outlines principles and procedures for conducting life cycle assessments of automotive products and processes, helping manufacturers evaluate their environmental impact.",
        },
      ],
    },
    {
      name: "Fertilizer Industry",
      shortdes:
        "The fertilizer industry adheres to various ISO standards to ensure quality, environmental responsibility, and accurate determination of nutrient content. Here are key ISO standards and relevant information applicable to the fertilizer industry:",
      points: [
        {
          code: "ISO 9001",
          title: "Quality Management System (QMS)",
          description:
            "ISO 9001 is a general quality management standard applicable to organizations of all types, including those in the fertilizer industry. It helps ensure consistent product quality, customer satisfaction, and continuous improvement in fertilizer production and distribution.",
        },
        {
          code: "ISO 14001",
          title: "Environmental Management System (EMS)",
          description:
            "ISO 14001 provides guidelines for managing the environmental impact of fertilizer manufacturing processes. It addresses issues such as waste management, resource conservation, and pollution control, promoting sustainable practices in the industry.",
        },
        {
          code: "ISO 20481",
          title: "Quality Management for Fertilizer Production Equipment",
          description:
            "ISO 20481 outlines quality management requirements for equipment used in fertilizer production, ensuring the reliability and performance of manufacturing machinery.",
        },
        {
          code: "ISO 18611",
          title: "Fertilizers - Determination of Moisture Content - Gravimetric Method",
          description:
            "ISO 18611 specifies a method for determining the moisture content of fertilizers, ensuring accurate measurement and quality control.",
        },
        {
          code: "ISO 18612",
          title: "Fertilizers - Determination of Bulk Density",
          description:
            "ISO 18612 provides guidelines for determining the bulk density of fertilizers, which is essential for packaging and handling considerations.",
        },
        {
          code: "ISO 18613",
          title: "Fertilizers - Determination of Particle Size Distribution - Sieving Method",
          description:
            "ISO 18613 outlines procedures for determining the particle size distribution of fertilizers using a sieving method, helping to ensure product consistency.",
        },
        {
          code: "ISO 18614",
          title: "Fertilizers - Determination of Particle Size Distribution - Optical Microscopy Method",
          description:
            "ISO 18614 provides an alternative method for determining the particle size distribution of fertilizers, using optical microscopy.",
        },
        {
          code: "ISO 18615",
          title: "Fertilizers - Determination of Ammonium Nitrogen by Titration",
          description:
            "ISO 18615 specifies a method for the determination of ammonium nitrogen content in fertilizers through titration, helping to verify nutrient content.",
        },
        {
          code: "ISO 18620",
          title: "Fertilizers - Determination of Ammonium Nitrogen Content - Method Using a Photometric Analyzer",
          description:
            "ISO 18620 outlines a method for determining ammonium nitrogen content in fertilizers using a photometric analyzer.",
        },
        {
          code: "ISO 18621",
          title: "Fertilizers - Determination of Nitrate Nitrogen Content - Method Using a Photometric Analyzer",
          description:
            "ISO 18621 specifies a method for determining nitrate nitrogen content in fertilizers using a photometric analyzer.",
        },
      ],
    },
    {
      name: "Chemical Industry",
      shortdes:
        "The chemical industry adheres to various ISO standards to ensure quality, environmental responsibility, and safety. Here are key ISO standards and relevant information applicable to the chemical industry:",
      points: [
        {
          code: "ISO 9001",
          title: "Quality Management System (QMS)",
          description:
            "ISO 9001 is a general quality management standard applicable to organizations of all types, including those in the chemical industry. It helps ensure consistent product quality, customer satisfaction, and continuous improvement in chemical manufacturing.",
        },
        {
          code: "ISO 14001",
          title: "Environmental Management System (EMS)",
          description:
            "ISO 14001 provides guidelines for managing the environmental impact of chemical manufacturing processes. It addresses issues such as waste management, resource conservation, and pollution control, promoting sustainable practices in the industry.",
        },
        {
          code: "ISO 45001",
          title: "Occupational Health and Safety Management System (OHSMS)",
          description:
            "ISO 45001 focuses on occupational health and safety within chemical manufacturing facilities. It helps chemical companies create safer working environments, reduce workplace accidents, and protect the health of employees.",
        },
        {
          code: "ISO 50001",
          title: "Energy Management System (EnMS)",
          description:
            "ISO 50001 assists chemical manufacturers in improving energy efficiency, reducing energy costs, and minimizing their environmental footprint in production processes.",
        },
        {
          code: "ISO 14040",
          title: "Environmental Management - Life Cycle Assessment (LCA)",
          description:
            "ISO 14040 outlines principles and procedures for conducting life cycle assessments of chemical products and processes, helping manufacturers evaluate their environmental impact.",
        },
        {
          code: "ISO 14064",
          title: "Greenhouse Gas Accounting and Verification",
          description:
            "ISO 14064 provides guidelines for the measurement, reporting, and verification of greenhouse gas emissions from chemical manufacturing operations, helping companies assess their carbon footprint.",
        },
        {
          code: "ISO 14051",
          title: "Material Flow Cost Accounting",
          description:
            "ISO 14051 assists chemical companies in identifying and reducing material losses and waste in their manufacturing processes, contributing to sustainability and cost savings.",
        },
        {
          code: "ISO 20743",
          title: "Textiles - Determination of Antibacterial Activity of Textile Products",
          description:
            "ISO 20743 outlines procedures for testing the antibacterial activity of textile products treated with antimicrobial agents, which are commonly used in the chemical industry for various applications.",
        },
        {
          code: "ISO 14024",
          title: "Environmental Labels and Declarations - Type I Environmental Labeling",
          description:
            "ISO 14024 provides criteria and guidelines for environmental labeling programs, allowing chemical companies to communicate their products' environmental attributes to consumers.",
        },
        {
          code: "ISO 14025",
          title: "Environmental Labels and Declarations - Type III Environmental Declarations",
          description:
            "ISO 14025 establishes principles and requirements for Type III environmental declarations, which provide comprehensive information about the environmental performance of chemical products.",
        },
      ],
    },
    {
      name: "Cement Industry",
      shortdes:
        "The cement industry adheres to various ISO standards to ensure quality, environmental responsibility, and safety. Here are key ISO standards and relevant information applicable to the cement industry:",
      points: [
        {
          code: "ISO 9001",
          title: "Quality Management System (QMS)",
          description:
            "ISO 9001 is a general quality management standard applicable to organizations of all types, including those in the cement industry. It helps ensure consistent product quality, customer satisfaction, and continuous improvement in cement manufacturing.",
        },
        {
          code: "ISO 14001",
          title: "Environmental Management System (EMS)",
          description:
            "ISO 14001 provides guidelines for managing the environmental impact of cement manufacturing processes. It addresses issues such as waste management, resource conservation, and pollution control, promoting sustainable practices in the industry.",
        },
        {
          code: "ISO 45001",
          title: "Occupational Health and Safety Management System (OHSMS)",
          description:
            "ISO 45001 focuses on occupational health and safety within cement manufacturing facilities. It helps cement companies create safer working environments, reduce workplace accidents, and protect the health of employees.",
        },
        {
          code: "ISO 50001",
          title: "Energy Management System (EnMS)",
          description:
            "ISO 50001 assists cement manufacturers in improving energy efficiency, reducing energy costs, and minimizing their environmental footprint in production processes.",
        },
        {
          code: "ISO 14040",
          title: "Environmental Management - Life Cycle Assessment (LCA)",
          description:
            "ISO 14040 outlines principles and procedures for conducting life cycle assessments of cement products and processes, helping manufacturers evaluate their environmental impact.",
        },
        {
          code: "ISO 14064",
          title: "Greenhouse Gas Accounting and Verification",
          description:
            "ISO 14064 provides guidelines for the measurement, reporting, and verification of greenhouse gas emissions from cement manufacturing operations, helping companies assess their carbon footprint.",
        },
        {
          code: "ISO 14080",
          title: "Greenhouse Gas Management and Related Activities",
          description:
            "ISO 14080 provides a framework for organizations to integrate climate change mitigation and adaptation into their cement manufacturing and operations.",
        },
        {
          code: "ISO 14051",
          title: "Material Flow Cost Accounting",
          description:
            "ISO 14051 assists cement companies in identifying and reducing material losses and waste in their manufacturing processes, contributing to sustainability and cost savings.",
        },
        {
          code: "ISO 11095",
          title: "Building Construction Machinery and Equipment - Terms and Commercial Specifications",
          description:
            "ISO 11095 provides standardized terms and commercial specifications for equipment used in cement manufacturing and construction, ensuring consistent terminology and product quality.",
        },
        {
          code: "ISO 18604",
          title: "Cement - Determination of Trace Elements by X-ray Fluorescence Spectrometry",
          description:
            "ISO 18604 outlines methods for the determination of trace elements in cement using X-ray fluorescence spectrometry, helping to ensure product quality and compliance with regulatory requirements.",
        },
      ],
    },
    {
      name: "Educational Universities",
      shortdes:
        "ISO standards applicable to educational universities cover various aspects, including quality management, information security, environmental responsibility, and more. Here are some ISO standards that are particularly relevant to universities:",
      points: [
        {
          code: "ISO 9001",
          title: "Quality Management System (QMS)",
          description:
            "ISO 9001 is a general quality management standard applicable to universities to ensure the quality of education, research, and administrative processes. It helps improve academic outcomes, student satisfaction, and overall institutional effectiveness.",
        },
        {
          code: "ISO 21001",
          title: "Educational Organizations Management System (EOMS)",
          description:
            "ISO 21001 is a specific standard tailored for educational organizations, including universities. It provides guidelines for establishing, implementing, maintaining, and improving an Educational Organizations Management System (EOMS), focusing on enhancing the quality of education and services.",
        },
        {
          code: "ISO/IEC 27001",
          title: "Information Security Management System (ISMS)",
          description:
            "ISO/IEC 27001 is crucial for universities that handle sensitive student data and research information. It provides a framework for managing information security risks and ensuring the confidentiality, integrity, and availability of information.",
        },
        {
          code: "ISO 14001",
          title: "Environmental Management System (EMS)",
          description:
            "ISO 14001 is relevant for universities that want to manage their environmental impact and promote sustainability on their campuses. It helps institutions implement eco-friendly practices, reduce waste, and minimize their environmental footprint.",
        },
        {
          code: "ISO 45001",
          title: "Occupational Health and Safety Management System (OHSMS)",
          description:
            "ISO 45001 is important for ensuring the health and safety of students, faculty, and staff within university campuses. It helps universities create safe learning and working environments, reduce accidents, and protect the well-being of stakeholders.",
        },
        {
          code: "ISO 29993",
          title: "Learning Services Outside Formal Education",
          description:
            "ISO 29993 provides guidelines for organizations, including universities, offering non-formal learning services. It covers various aspects of non-formal education, including tutoring, training, and other educational services.",
        },
        {
          code: "ISO 14064",
          title: "Greenhouse Gas Accounting and Verification",
          description:
            "ISO 14064 provides guidelines for universities to measure, report, and verify their greenhouse gas emissions. It helps institutions assess their carbon footprint and develop strategies for reducing emissions.",
        },
        {
          code: "ISO 41001",
          title: "Facility Management",
          description:
            "ISO 41001 provides a framework for efficient facility management within university campuses. It covers aspects such as maintenance, space planning, and sustainability, ensuring the effective use of university facilities.",
        },
        {
          code: "ISO 21070",
          title: "Legal Requirements for Archiving Electronic Documents",
          description:
            "ISO 21070 outlines requirements for the archiving of electronic documents, which can be essential for universities in managing records, research data, and academic information.",
        },
        {
          code: "ISO 31000",
          title: "Risk Management",
          description:
            "ISO 31000 offers principles and guidelines for risk management in universities, helping institutions identify, assess, and mitigate risks associated with their academic and administrative activities.",
        },
      ],
    },
    {
      "name": "Food Industry",
      "shortdes": "The food industry is subject to various ISO standards, Good Food Safety Initiative (GFSI) standards, and social responsibility standards to ensure food safety, quality, and ethical practices.",
      "points": [
        {
          "code": "ISO 22000 - Food Safety Management Systems",
          "title": "ISO Standards for Food Safety and Quality",
          "description": "ISO 22000 is a comprehensive standard for food safety management. It covers all aspects of food production, from sourcing raw materials to the final product, to ensure the safety and quality of food products."
        },
        {
          "code": "ISO 9001 - Quality Management System",
          "title": "ISO Standards for Food Safety and Quality",
          "description": "While not specific to food, ISO 9001 can be applied to the food industry to ensure consistent quality, customer satisfaction, and continuous improvement in processes and products."
        },
        {
          "code": "FSSC 22000 (Food Safety System Certification)",
          "title": "GFSI Standards (Global Food Safety Initiative)",
          "description": "This standard is based on ISO 22000 and includes additional requirements specific to the food industry. It is one of the most widely recognized GFSI standards."
        },
        {
          "code": "BRCGS (British Retail Consortium Global Standards)",
          "title": "GFSI Standards (Global Food Safety Initiative)",
          "description": "BRCGS provides guidelines for food safety, quality, and operational criteria for the food industry. It is widely used by retailers and manufacturers."
        },
        {
          "code": "IFS (International Featured Standards)",
          "title": "GFSI Standards (Global Food Safety Initiative)",
          "description": "IFS is a globally recognized standard that focuses on food safety and quality management systems, particularly for retailers and private label producers."
        },
        {
          "code": "SQF (Safe Quality Food)",
          "title": "GFSI Standards (Global Food Safety Initiative)",
          "description": "SQF is a comprehensive food safety and quality certification program that covers the entire supply chain, including food manufacturing, distribution, and retail."
        },
        {
          "code": "Fair Trade",
          "title": "Social Responsibility Standards",
          "description": "Fair Trade standards promote ethical trade practices by ensuring fair wages and safe working conditions for agricultural workers involved in the production of food ingredients like coffee, cocoa, and sugar."
        },
        {
          "code": "Rainforest Alliance",
          "title": "Social Responsibility Standards",
          "description": "The Rainforest Alliance certification ensures that food products, especially those with tropical ingredients, are sourced from farms and supply chains that meet environmental and social sustainability standards."
        },
        {
          "code": "Fair for Life",
          "title": "Social Responsibility Standards",
          "description": "This certification guarantees fair working conditions, fair prices for farmers, and responsible sourcing of food products."
        },
        {
          "code": "BSCI (Business Social Compliance Initiative)",
          "title": "Social Responsibility Standards",
          "description": "BSCI focuses on improving social performance in the supply chain, including food manufacturers and retailers, by addressing issues like labor rights and workplace conditions."
        },
        {
          "code": "Sedex (Supplier Ethical Data Exchange)",
          "title": "Social Responsibility Standards",
          "description": "Sedex is a platform for sharing ethical and responsible sourcing data. It helps food companies assess and improve their social and ethical practices."
        },
        {
          "code": "RSP (Responsible Sourcing Partnership)",
          "title": "Social Responsibility Standards",
          "description": "RSP focuses on responsible sourcing practices in the food supply chain, covering aspects such as labor rights, environmental sustainability, and supply chain transparency."
        }
      ]
    },
    
    {
      "name": "Banks",
      "shortdes": "Banks are highly regulated financial institutions and must adhere to a comprehensive set of standards and regulations to ensure their stability, security, and ethical operation.",
      "points": [
        {
          "code": "Basel III Framework",
          "title": "Financial and Risk Management Standards",
          "description": "Developed by the Basel Committee on Banking Supervision, Basel III provides global standards for bank capital adequacy, liquidity, and risk management."
        },
        {
          "code": "IFRS (International Financial Reporting Standards)",
          "title": "Financial and Risk Management Standards",
          "description": "IFRS sets accounting standards for financial reporting, ensuring transparency and comparability of financial statements."
        },
        {
          "code": "Banking Regulations",
          "title": "Regulatory and Compliance Standards",
          "description": "Each country has its own banking regulations overseen by regulatory bodies (e.g., the Federal Reserve in the United States). These regulations cover capital requirements, lending practices, and consumer protection."
        },
        {
          "code": "Anti-Money Laundering (AML) and Know Your Customer (KYC) Regulations",
          "title": "Regulatory and Compliance Standards",
          "description": "Banks must comply with AML and KYC regulations to prevent money laundering and terrorist financing and verify customer identities."
        },
        {
          "code": "Consumer Financial Protection Bureau (CFPB) Regulations (U.S.)",
          "title": "Regulatory and Compliance Standards",
          "description": "In the United States, CFPB oversees and enforces consumer financial protection laws."
        },
        {
          "code": "GDPR (General Data Protection Regulation)",
          "title": "Regulatory and Compliance Standards",
          "description": "GDPR applies to banks operating in the European Union and sets standards for the protection of customer data."
        },
        {
          "code": "ISO 27001 (Information Security Management System)",
          "title": "Information Security and Data Protection Standards",
          "description": "ISO 27001 provides guidelines for information security management, including data protection, risk assessment, and security controls."
        },
        {
          "code": "PCI DSS (Payment Card Industry Data Security Standard)",
          "title": "Information Security and Data Protection Standards",
          "description": "Banks processing credit card payments must comply with PCI DSS to protect cardholder data."
        },
        {
          "code": "Customer Service Standards",
          "title": "Customer Service and Ethical Standards",
          "description": "Banks often have internal service standards to ensure excellent customer service, including response times, complaint handling, and dispute resolution procedures."
        },
        {
          "code": "Code of Ethics",
          "title": "Customer Service and Ethical Standards",
          "description": "Banks often have their own code of ethics that employees must adhere to, promoting ethical conduct and customer trust."
        },
        {
          "code": "Stress Testing Guidelines",
          "title": "Capital Adequacy and Stress Testing Standards",
          "description": "Regulators often require banks to undergo stress tests to assess their ability to withstand adverse economic conditions."
        },
        {
          "code": "Corporate Governance Codes",
          "title": "Corporate Governance Standards",
          "description": "Many countries have corporate governance codes that banks must follow to ensure effective board oversight and management accountability."
        },
        {
          "code": "Sustainability Reporting",
          "title": "Sustainability and Social Responsibility Standards",
          "description": "Banks may be required to report on their environmental, social, and governance (ESG) practices and their impact on sustainability goals."
        },
        {
          "code": "Social Responsibility Initiatives",
          "title": "Sustainability and Social Responsibility Standards",
          "description": "Many banks engage in social responsibility initiatives, including community development, financial literacy programs, and support for underserved populations."
        }
      ]
    },
    {
      "name": "Pink Himalayan Salt",
      "shortdes": "Pink Himalayan salt is a popular and distinctive type of salt that is sourced from the Khewra Salt Mine in Pakistan. While it is not typically subject to ISO standards, it is essential for food safety and quality standards, as well as adherence to social responsibility practices and Good Food Safety Initiative (GFSI) standards.",
      "points": [
        {
          "code": "GFSI Standards (Global Food Safety Initiative)",
          "title": "Food Safety Standards",
          "description": "Pink Himalayan salt, like other food products, can adhere to GFSI standards. The GFSI sets benchmark standards for food safety management systems, and various schemes, such as FSSC 22000, BRCGS, and IFS (International Featured Standards), incorporate GFSI requirements. These standards ensure the safety and quality of food products, including salt."
        },
        {
          "code": "Fair Trade",
          "title": "Social Responsibility and Ethical Practices",
          "description": "Pink Himalayan salt producers can engage in fair trade practices, ensuring fair wages, safe working conditions, and ethical treatment of workers involved in the salt mining and processing industry."
        },
        {
          "code": "Environmental Sustainability",
          "title": "Social Responsibility and Ethical Practices",
          "description": "Sustainability practices can be implemented in salt mining operations to minimize environmental impact. This includes responsible land use, resource conservation, and responsible waste disposal."
        },
        {
          "code": "Organic Certification",
          "title": "Certification and Labeling",
          "description": "Some pink Himalayan salt producers may seek organic certification, ensuring that the salt is sourced and processed following organic farming and processing standards."
        },
        {
          "code": "Halal Certification",
          "title": "Certification and Labeling",
          "description": "For markets with a significant Muslim population, obtaining Halal certification can be important to ensure that the salt meets Islamic dietary requirements."
        },
        {
          "code": "GMO-Free Certification",
          "title": "Certification and Labeling",
          "description": "Certification can be obtained to confirm that the salt does not contain genetically modified organisms (GMOs)."
        },
        {
          "code": "ISO 9001",
          "title": "Quality Assurance",
          "description": "While not specific to salt, ISO 9001 is a quality management system standard that can be implemented to ensure consistent product quality, including for salt producers."
        },
        {
          "code": "ISO 18602",
          "title": "Packaging Standards",
          "description": "This standard provides guidelines for packaging and packaging materials. It ensures that the packaging used for Himalayan salt meets quality and safety requirements."
        },
        {
          "code": "EcoVidas, Sedex, BSCI (Business Social Compliance Initiative), RSP (Responsible Sourcing Partnership)",
          "title": "Sustainability Standards",
          "description": "These are various social standards and initiatives that promote ethical and sustainable business practices, including responsible sourcing, fair labor practices, and environmental sustainability."
        }
      ]
    },
    
    {
      name: "Restaurants",
      shortdes: "Restaurants are subject to various standards and regulations to ensure food safety, quality, and hygiene, as well as compliance with local regulations. While there isn't a specific ISO standard for restaurants, they must adhere to a combination of international, national, and local standards and regulations.",
      points: [
        {
          code: "HACCP",
          title: "Hazard Analysis and Critical Control Points",
          description: "HACCP is a systematic approach used to identify, assess, and control food safety hazards. It's essential for restaurants to ensure safe food handling and preparation."
        },
        {
          code: "Local Health Regulations",
          title: "Local Health Regulations",
          description: "Restaurants are obligated to comply with local health department regulations, which often include guidelines for food handling, storage, cleanliness, and sanitation practices."
        },
        {
          code: "ISO 9001",
          title: "Quality Management System (QMS)",
          description: "While not restaurant-specific, ISO 9001 can be applied to ensure consistent quality, customer satisfaction, and continuous improvement in restaurant operations."
        },
        {
          code: "Allergen Labeling Laws",
          title: "Allergen Labeling Laws",
          description: "Depending on the region, restaurants may be required to adhere to allergen labeling laws. These laws aim to inform customers about the presence of common allergens in menu items."
        },
        {
          code: "Menu Labeling Laws",
          title: "Menu Labeling Laws",
          description: "Some regions have regulations that necessitate restaurants to provide nutritional information on their menus. This information may include details such as calories, fat content, and other dietary information."
        },
        {
          code: "Liquor Licensing Laws",
          title: "Liquor Licensing Laws",
          description: "If a restaurant serves alcohol, it must comply with local liquor licensing laws and safety standards related to alcohol service."
        },
        {
          code: "Labor Laws",
          title: "Labor Laws",
          description: "Restaurants must adhere to employment and labor laws, including minimum wage regulations, working hours, and employee safety standards."
        },
        {
          code: "Environmental Regulations",
          title: "Environmental Regulations",
          description: "Restaurants should consider environmental regulations, including waste disposal and energy conservation requirements, to minimize their environmental impact."
        },
        {
          code: "Fair Labor Practices",
          title: "Fair Labor Practices",
          description: "Restaurants should follow fair labor practices to ensure fair wages and safe working conditions for their employees."
        },
        {
          code: "Sustainability Initiatives",
          title: "Sustainability Initiatives",
          description: "Many restaurants are adopting sustainable practices, such as sourcing locally, reducing food waste, and using eco-friendly packaging, as part of their commitment to social and environmental responsibility."
        }
      ]
    },
    {
      name: "Packaging Industry",
      shortdes: "The packaging industry adheres to various ISO standards, Good Food Safety Initiative (GFSI) standards, and social responsibility initiatives to ensure the safety, quality, and sustainability of packaging materials and processes.",
      points: [
        {
          code: "ISO 9001",
          title: "Quality Management System (QMS)",
          description: "ISO 9001 can be applied to packaging manufacturers to ensure consistent quality, customer satisfaction, and continuous improvement in production processes."
        },
        {
          code: "ISO 22000",
          title: "Food Safety Management System (FSMS)",
          description: "If packaging materials are intended for food products, ISO 22000 helps ensure the safety of packaging materials and compliance with food safety regulations."
        },
        {
          code: "ISO 11607",
          title: "Packaging for Terminally Sterilized Medical Devices",
          description: "ISO 11607 specifies requirements for packaging materials and systems used for terminally sterilized medical devices."
        },
        {
          code: "ISO 15378",
          title: "Primary Packaging Materials for Medicinal Products",
          description: "ISO 15378 outlines requirements for the production of primary packaging materials for pharmaceuticals, ensuring compliance with Good Manufacturing Practices (GMP)."
        },
        {
          code: "FSSC 22000",
          title: "Food Safety System Certification (FSSC)",
          description: "FSSC 22000 includes additional requirements for food packaging manufacturers to ensure food safety and quality."
        },
        {
          code: "BRCGS",
          title: "British Retail Consortium Global Standards (BRCGS)",
          description: "BRCGS provides guidelines for food safety, quality, and operational criteria for packaging materials intended for food."
        },
        {
          code: "IFS PACsecure",
          title: "IFS PACsecure",
          description: "IFS PACsecure is specifically designed for packaging material manufacturers supplying the food industry. It covers food safety, quality, and traceability."
        },
        {
          code: "ISO 14001",
          title: "Environmental Management System (EMS)",
          description: "ISO 14001 helps packaging companies manage their environmental impact, promote sustainability, and comply with environmental regulations."
        },
        {
          code: "ISO 14064",
          title: "Greenhouse Gas Accounting and Verification",
          description: "Packaging companies can use ISO 14064 to measure, report, and verify their greenhouse gas emissions and carbon footprint."
        },
        {
          code: "Fair Labor Practices",
          title: "Fair Labor Practices",
          description: "Packaging manufacturers can adopt fair labor practices to ensure fair wages, safe working conditions, and ethical treatment of workers."
        },
        {
          code: "Sustainability Initiatives",
          title: "Sustainability Initiatives",
          description: "Packaging companies may engage in sustainability initiatives, such as responsible sourcing of materials, waste reduction, and eco-friendly packaging designs."
        },
        {
          code: "Social Responsibility Certifications",
          title: "Social Responsibility Certifications",
          description: "Certifications like Fair Trade, Sedex, BSCI (Business Social Compliance Initiative), and others can be relevant for packaging companies committed to ethical and responsible business practices."
        },
        {
          code: "ISO 18602",
          title: "Packaging and the Environment - Optimization of the Packaging System",
          description: "ISO 18602 provides guidelines for optimizing packaging systems to minimize environmental impact while maintaining product integrity."
        },
        {
          code: "ISO 31000",
          title: "Risk Management",
          description: "ISO 31000 offers principles and guidelines for risk management in the packaging industry, helping companies identify, assess, and mitigate risks."
        },
        {
          code: "ISO 18604",
          title: "Packaging and the Environment - General Requirements for the Use of ISO Standards in Packaging",
          description: "ISO 18604 outlines general requirements for using ISO standards in packaging, including those related to recycling and environmental responsibility."
        }
      ]
    }
    
    
    
  
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
  ],
};
