import * as React from "react";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";

const CustomLeftArrow = ({ onClick }) => (
  <i onClick={() => onClick()} className="custom-left-arrow" />
);
const CustomRightArrow = ({ onClick }) => {
  return <i className="custom-right-arrow" onClick={() => onClick()} />;
};

const CustomButtonGroup = ({ next, previous, goToSlide, carouselState }) => {
  const { totalItems, currentSlide } = carouselState;
  return (
    <div className="custom-button-group">
      <div>Current slide is {currentSlide}</div>
      <button onClick={() => previous()}>Previous slide</button>
      <button onClick={() => next()}>Next slide</button>
      <button
        onClick={() => goToSlide(Math.floor(Math.random() * totalItems + 1))}
      >
        Go to a random slide
      </button>
    </div>
  );
};
const CustomButtonGroupAsArrows = ({ next, previous }) => {
  return (
    <div className="absolute w-[100%] hidden justify-end items-center md:flex bottom-[-40px] right-20  ">
      <button
        onClick={previous}
        className="absolute ml-[-80px]    items-center font-bold bg-purple-500 text-white  p-4 w-[50px] h-[50px]  flex justify-center  rounded-md cursor-pointer hover:bg-orange-500 text-[16px] my-auto border-2 border-purple-500 hover:border-orange-500"
      >
        <BsArrowLeft size={26} className="font-bold" />
      </button>
      <button
        onClick={next}
        className="items-center ml-4 left-[100%] absolute font-bold bg-purple-500 text-white  p-4 w-[50px] h-[50px]  flex justify-center rounded-md cursor-pointer hover:bg-orange-500 text-[16px] my-auto border-2 border-purple-500 hover:border-orange-500"
      >
        <BsArrowRight size={26} className="" />
      </button>
    </div>
  );
};
const CustomDot = ({ onClick, active, index, carouselState }) => {
  return (
    <div className=" flex items-center justify-center space-x-2 mt-[10px]">
      <li
        className={` w-4 md:h-4  rounded-full ml-2 ${
          active ? " bg-purple-500  w-8 md:h-4" : " bg-gray-300 "
        }`}
        onClick={() => onClick()}
      ></li>
    </div>
  );
};

export {
  CustomLeftArrow,
  CustomRightArrow,
  CustomButtonGroup,
  CustomButtonGroupAsArrows,
  CustomDot,
};
