import React from 'react';
import Layout from '../../../../components/Layout';
import Sustainabiltycommon from '../../Sustainabiltycommon';

const EnvironmentalBalance = () => {
const img1 = `${process.env.PUBLIC_URL}/assets/sustainability/Environmental balance.webp`;

    return (
        <Layout title={"MBA Solutions - Environmental Balance"} keywords={"Environmental Balance, MBA Sustainability Service, MBA Sustainability- Environment, MBA Services"}>
            <h1 className="text-3xl drop-shadow-md font-bold mt-14 mb-20 text-center" data-aos="fade-up"> Environmental Balance</h1>
            
            <div className="sectionlayout bg-white mt-20 justify-center overflow-x-hidden md:space-x-20 flex flex-col-reverse md:flex-row" data-aos="fade-up">
                <div className="flex flex-col md:w-1/2" data-aos="fade-right">
                    <p className="text-gray-700 mt-16 md:mt-4">
                        Our <strong> environmental balance </strong> is achieved by integrating sustainable business practices into every aspect of our operations. We focus on <strong> reducing emissions, conserving water, using eco-friendly materials, and minimizing waste </strong> to ensure a healthier planet for future generations. We regularly update our practices to reflect industry best practices and strive for continuous improvement. Our goal is to promote a culture of environmental stewardship within our organization and beyond.
                    </p>
                </div>
                <img src={img1} alt="Environmental Balance" className="w-full md:w-1/2 h-auto rounded-lg" data-aos="fade-left" />
            </div>
            <Sustainabiltycommon />

        </Layout>
    );
};

export default EnvironmentalBalance;
