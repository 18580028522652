import React, { useEffect, useState } from "react";
import { TfiClose } from "react-icons/tfi";
import { Link } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import "../Pages/Home/Home.css";
import { animateScroll as scroll } from "react-scroll";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import Cookies from "js-cookie";
const Navbar = () => {
  const [nav, setNav] = useState(false);
  const [showShadow, setShowShadow] = useState(false);
  const [activeSubmenu, setActiveSubmenu] = useState(null);

  const [navLinks, setNavLinks] = useState([
    { text: "Our Services", url: "/services", submenu: true, dropdown: [] },
    { text: "ToolKits", url: "/toolkits" },
    { text: "Sustainability", url: "/sustainability" },
    { text: "Training", url: "/training" },
    { text: "Jobs", url: "/jobs" },
    { text: "Blogs", url: "/blog" },
    { text: "Tracking", url: "/tracking" },
    {
      text: "About Us",
      url: "/about",
      submenu: true,
      dropdown: [
        { name: "Our Team", link: "/team" },
        { name: "Clientele", link: "/clientele" },
        { name: "Certifications", link: "/Certifications" },
      ],
    },
    { text: "Contact Us", url: "/contact" },
  ]);
  const handleSubmenuClick = (submenuIndex) => {
    setActiveSubmenu(submenuIndex);
  };
  const Logo = `${process.env.PUBLIC_URL}/assets/Logo.webp`;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const serviceApiEndpoint =
          "https://api.mbasolutions.co/api/categories/get";
        const serviceResponse = await fetch(serviceApiEndpoint);
        const serviceData = await serviceResponse.json();

        const uniqueServiceIds = new Set();
        const filteredServices = [];

        serviceData.forEach((service) => {
          if (!uniqueServiceIds.has(service._id)) {
            uniqueServiceIds.add(service._id);
            filteredServices.push({
              name: service.name,
              link: `/service/${service._id}`,
            });
          }
        });

        setNavLinks((prevNavLinks) => {
          const updatedNavLinks = [...prevNavLinks];
          const ourServicesLink = updatedNavLinks.find(
            (link) => link.text === "Our Services"
          );

          if (ourServicesLink) {
            ourServicesLink.dropdown = [...filteredServices];
          }

          return updatedNavLinks;
        });
        setLoading(false);
      } catch (error) {
        console.error("Error fetching service data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);
  const handleNav = () => {
    setNav(!nav);
  };

  const handleLinkClick = (link) => {
    if (typeof link !== "string") {
      console.error("Invalid link:", link); // Debugging output
      return;
    }
  
    scroll.scrollToTop();
  
    const linkParts = link.split("/");
    const id = linkParts[linkParts.length - 1];
  
    Cookies.set("clickedServiceId", id, { expires: 1 / 24 });
    console.log(`Cookie set: clickedServiceId=${id}`);
  };
  

  const screentop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div
      className={`poppins sticky top-0  z-[1000] text-black h-24 Georgian bg-white `}
    >
      <div
        className={`h-24 flex justify-between  items-center font-bold md:px-7 p-4 text-sm ${
          showShadow ? "shadow-2xl" : ""
        }`}
      >
        <div className="mx-[-12px]">
          <Link to="/">
            <img
              className="h-16"
              src={Logo}
              alt="MBA SOLUTIONS"
              onClick={() => handleLinkClick("/")}
            />
          </Link>
        </div>
        <div className="lg:hidden hover:text-orange-500 hover:drop-shadow-xl ">
          <button
            className="inline-flex items-center rounded-md hover:text-[#99702B] ease-in-out transition-all duration-200 py-2 px-4 p-2 text-lg font-medium "
            onClick={handleNav}
          >
            <FaBars
              size={22}
              className="text-[#454545] drop-shadow-md cursor-pointer hover:text-[#F86624] ease-in-out transition-all duration-200"
            />
          </button>
        </div>
        <div className="hidden lg:inline Georgian">
          <ul className="flex justify-center hover:cursor-pointer">
            {navLinks.map((li, index) => (
              <li
                key={index}
                className="py-1 px-4  hover:text-[#F86624] drop-shadow-xl relative group"
                onClick={screentop}
              >
                <Link to={li.url} className="flex">
                  {li.text}
                  {li.submenu ? (
                    <span className="text-md md:ml-2 mt-1 inline">
                      <BsChevronDown />
                    </span>
                  ) : null}
                </Link>

                {li.submenu && (
                  <div className="absolute top-7 max-h-[40vh] overflow-y-scroll left-10 w-[190px]  hidden group-hover:block shadow-lg z-[999] bg-white/90">
                    <div className="pt-3 py-1">
                      <div className="w-4 h-4 absolute mt-5 rotate-45"></div>
                      {loading ? (
                        <div className="py-1 px-4">Loading...</div>
                      ) : (
                        <div className="py-4 px-3 flex flex-col">
                          {li?.dropdown?.map((mysublinks, subIndex) => (
                            <div
                              className="text-black/80 hover:text-[#F86624]"
                              key={subIndex}
                            >
                              <Link
                                to={mysublinks.link}
                                onClick={() => handleLinkClick(mysublinks.link)}
                              >
                                <p className="text-md font-light hover:ml-2 ease-out transition-all duration-200">
                                  {mysublinks.name}
                                </p>

                                <p className="pt-5"></p>
                              </Link>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div
        className={
          nav
            ? "Georgian bg-white absolute right-0 top-0 w-80 z-1000 rounded-sm shadow-md overflow-y-auto text-black"
            : "fixed right-[-100%]"
        }
      >
        <div className="sticky h-full bg-white overflow-y-auto">
          <ul className="pt-4 px-6 bg-white pb-3">
            <div className="flex justify-end pr-2 pt-4">
              <TfiClose
                onClick={handleNav}
                className="rounded p-1 font-bold hover:bg-black/5"
                size={24}
              />
            </div>
            <div className="mt-6 h-full overflow-y-auto pb-6">
              {navLinks.map((li, index) => (
                <React.Fragment key={index}>
                  <li
                    key={index}
                    className="cursor-pointer my-3 Georgian py-2 pl-3 font-medium hover:rounded hover:bg-black/5 hover:text-orange-700"
                   
                  >
                    <Link to={li.url}  onClick={() => handleLinkClick(li.url)}>{li.text}</Link>
                  </li>
                  {li.submenu && (
                    <BsChevronUp
                      className={`${
                        activeSubmenu === index ? "rotate-180" : ""
                      } absolute mt-[-39px] right-8`}
                      onClick={() => {
                        if (activeSubmenu === index) {
                          setActiveSubmenu(null);
                        } else {
                          setActiveSubmenu(index);
                        }
                      }}
                    />
                  )}

                  <div className="ml-2 flex h-auto flex-col justify-center transition-all duration-200 ease-in-out pl-6 max-h-[40vh] overflow-y-auto">
                    {li.submenu && activeSubmenu === index && (
                      <ul className="pt-6">
                        {li.dropdown.map((submenuItem, index) => (
                        <li key={index} className="text-black/70 text-md ease-out transition-all duration-200 gap-x-4 cursor-pointer p-3 rounded-md py-4 pl-3 hover:text-[#F86624]">
                        <Link to={submenuItem.link} onClick={() => handleLinkClick(submenuItem.link)}>
                          {submenuItem.name}
                        </Link>
                      </li>
                      
                        ))}
                      </ul>
                    )}
                  </div>
                </React.Fragment>
              ))}
            </div>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
