import React from 'react';
import Layout from '../../../../components/Layout';
import Sustainabiltycommon from '../../Sustainabiltycommon';

const SustainableIt = () => {
    return (
        <Layout title={"MBA Solutions - Sustainable IT Infrastructure & Green Data Centers"} keywords={"Sustainable IT Infrastructure, Green Data Centers, LEED Certification, EU Code of Conduct, Energy Efficiency, Science-Based Targets Initiative, Carbon Reduction, MBA Sustainability Service, MBA Sustainability- IT Technology, MBA Services"}>
            <div className="sectionlayout" data-aos="fade-up">
                <h1 className="text-3xl mt-12 font-bold mb-16 text-center">Sustainable IT Infrastructure & Green Data Centers</h1>
                <div className="mb-6" data-aos="fade-up" data-aos-delay="100">
                    <h2 className="text-xl font-semibold mb-2">LEED (Leadership in Energy and Environmental Design) Certification</h2>
                    <p className="text-gray-700">
                        A widely used certification for IT offices and data centers ensuring sustainable building practices.
                    </p>
                </div>
                <div className="mb-6" data-aos="fade-up" data-aos-delay="200">
                    <h2 className="text-xl font-semibold mb-2">EU Code of Conduct for Energy Efficiency in Data Centers</h2>
                    <p className="text-gray-700">
                        Encourages IT firms to reduce energy consumption and improve operational efficiency.
                    </p>
                </div>
                <div className="mb-6" data-aos="fade-up" data-aos-delay="300">
                    <h2 className="text-xl font-semibold mb-2">Science-Based Targets Initiative (SBTi)</h2>
                    <p className="text-gray-700">
                        IT companies set and achieve carbon reduction goals aligned with global climate targets.
                    </p>
                </div>
            </div>
            <Sustainabiltycommon />
        </Layout>
    );
};

export default SustainableIt;
