import React from 'react';
import Layout from '../../../../components/Layout';
import Sustainabiltycommon from '../../Sustainabiltycommon';

const QualityAssurance = () => {
   const img1= `${process.env.PUBLIC_URL}/assets/sustainability/governance/QualityAssurance.webp`;
    
    return (
        <Layout title={"MBA Solutions - Quality Assurance"} keywords={"Quality Assurance, MBA Quality Service, MBA Quality Management, MBA Services"}>
            <h1 className="text-3xl drop-shadow-md font-bold mt-14 mb-20 text-center" data-aos="fade-up"> Quality Assurance</h1>
            
            <div className="sectionlayout mt-20 justify-center overflow-x-hidden md:space-x-20 flex flex-col-reverse md:flex-row" data-aos="fade-up">
                <div className="flex flex-col md:w-1/2" data-aos="fade-right">
                    <p className="text-gray-700 mt-16 md:mt-4">
                        Our commitment to <strong> quality assurance </strong> ensures that all our products and services meet the highest industry standards. Through rigorous testing, audits, and customer feedback analysis, we continuously enhance our quality management processes.
                    </p>
                </div>
                <img src={img1} alt="Quality Assurance" className="w-full md:w-1/2 h-auto rounded-lg" data-aos="fade-left" />
            </div>
            <Sustainabiltycommon />
            
        </Layout>
    );
};

export default QualityAssurance;
