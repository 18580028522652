import React from "react";
import './Ws.css'
import { BsWhatsapp } from "react-icons/bs";
const WhatsAppLogo = () => {
  const phoneNumber = "+923014900495"; 
  const message = "Hello, I have a question!"; 

  const generateWhatsAppLink = () => {
    const url = `https://wa.me/${phoneNumber}/?text=${encodeURIComponent(message)}`;
    return url;
  };

  return (
    <a href={generateWhatsAppLink()} target="_blank" rel="noopener noreferrer" className=" text-white ">
      {/* Replace this with your WhatsApp icon */}
      <BsWhatsapp
       
        style={{ width: "50px", height: "50px" }} 
        className="left-5 md:left-8 fixed  p-3   bottom-6  cursor-pointer bg-[#4dc247]  rounded-full text-white   shadow-black z-[1100]"// Adjust the size as needed
      />
    </a>
  );
};

export default WhatsAppLogo;
