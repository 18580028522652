import React from 'react'
import ContactSection1 from './ContactSection1'
import ContactSection2 from './ContactSection2'
import {ContactSection3} from './ContactSection3'
import ContactSection4 from './ContactSection4'
import Layout from '../../components/Layout'

const Contact = () => {
  return (
    <Layout title={"MBA Solutions - Contact Us "} keywords={"Contact, Contact Us, Get in Touch, Reach Us, MBA Solutions ,MBA Solutions Contact, Contact Information, Contact Form"}>
   <div className=''> 
      <ContactSection1 />
      <div className='bg-[#F2F2F2] py-12'>
      <ContactSection2 />
      <ContactSection3 />
      <ContactSection4 />
      </div>
      </div>
    </Layout>
  )
}

export default Contact