import React from 'react'
import Layout from '../../components/Layout'
import Sustainabiltycommon from './Sustainabiltycommon';
import SustainabilitySection1 from './SustainabilitySection1';

const Sustainability = () => {

const img6 = `${process.env.PUBLIC_URL}/assets/sustainability/resourcecircularity.webp`
  

  return (
       <Layout title={"MBA Solutions - Sustainability "} keywords={"Sustainability, MBA Sustainability, Business Sustainability, MBA Services"}>
      <SustainabilitySection1 />
       <div className='my-20'>
            <div className="flex flex-col md:flex-row items-center md:space-x-10 space-y-12 md:space-y-0 sectionlayout">
              <div className="md:w-1/2">
                <h1 className=" font-bold mb-10 drop-shadow-lg" style={{fontSize:'clamp(32px, 3vw,65px)'}}>Sustainability</h1>
                <p className="text-lg text-gray-700">
                  Sustainability is a key aspect of our operations. We are committed to managing our environmental, social, and governance responsibilities effectively.
                 We strive to minimize our environmental footprint, promote social equity, and uphold the highest standards of governance. Our sustainability initiatives are designed to create long-term value for our stakeholders and contribute to a better future for all.
                </p>
              </div>
              <div className="md:w-1/2">
                <img src={img6} alt="Sustainability" className="w-full h-auto rounded-lg shadow-md" />
              </div>
            </div>
            </div>
        <Sustainabiltycommon />
   
      </Layout>
  );
};


export default Sustainability