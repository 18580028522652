import React, { useState } from 'react';
import Layout from '../../../components/Layout';
import Sustainabiltycommon from '../Sustainabiltycommon';
import { Link } from 'react-router-dom';
import { animateScroll as scroll } from "react-scroll";

const SocialGovernance = () => {
const img1 = `${process.env.PUBLIC_URL}/assets/sustainability/governance/HumanLabouredRight.webp`
const img2 = `${process.env.PUBLIC_URL}/assets/sustainability/governance/diversityandInclusion.webp`
const img3 = `${process.env.PUBLIC_URL}/assets/sustainability/governance/Relationshipwithsociety.webp`
const img4 = `${process.env.PUBLIC_URL}/assets/sustainability/governance/fulfilmentofwork.webp`
const img5 = `${process.env.PUBLIC_URL}/assets/sustainability/governance/occupationalhealtyandsaftey.webp`
const img6 = `${process.env.PUBLIC_URL}/assets/sustainability/governance/QualityAssurance.webp`
const img7 = `${process.env.PUBLIC_URL}/assets/sustainability/governance/SupplyChainManagement.webp`
const img8 = `${process.env.PUBLIC_URL}/assets/sustainability/governance/corporategovernance.webp`
const img9 = `${process.env.PUBLIC_URL}/assets/sustainability/governance/riskmanagement.webp`
const img10 = `${process.env.PUBLIC_URL}/assets/sustainability/governance/compilance.webp`
const img11 = `${process.env.PUBLIC_URL}/assets/sustainability/governance/intelectualproperty.webp`

  const cards1 = [
    { title: "Human and Labored Rights", img: img1, link: "/social-governance/human-labored-rights" },
    { title: "Diversity and Inclusions", img: img2, link: "/social-governance/diversity-inclusions" },
    { title: "Relationship with Society", img: img3, link: "/social-governance/relationship-society" },
    { title: "Creating a Fulfillment Work Environment", img: img4, link: "/social-governance/fulfillment-work-environment" },
    { title: "Occupational Health and Safety", img: img5, link: "/social-governance/occupational-health-safety" },
    { title: "Quality Assurance", img: img6, link: "/social-governance/quality-assurance" },
    { title: "Supply Chain Management", img: img7, link: "/social-governance/supply-chain-management" },
  ];
  const cards2 = [
    { title: "Corporate Governance", img: img8, link: "/social-governance/corporate-governance" },
    { title: "Risk Management", img: img9, link: "/social-governance/risk-management" },
    { title: "Compliance", img: img10, link: "/social-governance/compliance" },
    { title: "Intellectual Properties", img: img11, link: "/social-governance/intellectual-properties" },
  ];

  const [loading, setLoading] = React.useState(true);
  const [showMore, setShowMore] = useState(false);

  const handleImageLoad = () => {
    setLoading(false);
  };

  const handleLinkClick = () => {
  
    scroll.scrollToTop();
  };

  const toggleShowMore = () => {
    setShowMore(!showMore);

  };


  
  return (
    <Layout title={"MBA Solutions - Environment"} keywords={"Environment, MBA Sustainability Service, Mba Sustainability- Environment, MBA Services"}>
      <div className="sectionlayout overflow-x-hidden">
        <h2 className='text-center font-bold drop-shadow-lg pt-20' style={{ fontSize: 'clamp(36px,2.5vw,50px)' }} data-aos="fade-up">
          Social Responsibility And Governance
        </h2>
        <p className="text-left mt-4 mb-8 px-4" data-aos="fade-up">
          At <strong> MBA Solutions, </strong> we recognize that sustainable business success goes beyond financial growth—it involves ethical governance and a commitment to social responsibility. By embedding social responsibility and strong governance into our operations, we strive to create a positive impact on people, communities, and the environment while ensuring transparency, accountability, and compliance with global standards such as:
        </p>
        <ul className="list-disc list-inside px-4 mb-8" data-aos="fade-up">
          <li className='mb-2'><strong> ISO 26000 – </strong> Guidelines on corporate social responsibility (CSR).</li>
          <li className='mb-2'><strong> GRI  </strong>(Global Reporting Initiative) – Framework for sustainability reporting and disclosures.</li>
          <li className='mb-2'><strong> SASB </strong>(Sustainability Accounting Standards Board) – Industry-specific sustainability accounting standards.</li>
          <li className='mb-2'><strong> CDP </strong>(Carbon Disclosure Project) – Global environmental impact disclosure system.</li>
          <li className='mb-2'><strong> TCFD </strong>(Task Force on Climate-Related Financial Disclosures) – Climate risk reporting recommendations.</li>
        </ul>
        <h3 className="text-center font-bold mt-8 mb-4" style={{ fontSize: 'clamp(24px,2vw,36px)' }} data-aos="fade-up">
          Our Commitment to Social Responsibility
        </h3>
        <p className="text-center mb-8 px-4" data-aos="fade-up">
          We believe that businesses have a duty to <strong> support and uplift society, </strong> ensuring that our operations contribute to social well-being. Our social responsibility efforts focus on <strong> human rights, diversity, community development, occupational health and safety, quality assurance, and sustainable supply chains. </strong> By fostering an inclusive and fair work environment, engaging with local communities, and upholding ethical labor practices, we create long-term social value.
        </p>
        <h4 className=" font-bold mt-8 mb-4" style={{ fontSize: 'clamp(20px,1.5vw,28px)' }} data-aos="fade-up">
          Key Focus Areas in Social Responsibility:
        </h4>
        <ul className="list-disc list-inside mb-8" data-aos="fade-up">
          <li className='mb-2'><strong> Human and Labor Rights – </strong> Ensuring ethical labor practices, fair wages, safe working conditions, and compliance with international human rights laws.</li>
          <li className='mb-2'><strong> Diversity and Inclusion – </strong> Creating an inclusive workplace that values gender equality, cultural diversity, and equal opportunities for all employees.</li>
          <li className='mb-2'><strong> Community Engagement – </strong> Supporting social initiatives, including corporate social responsibility (CSR) programs, philanthropy, and volunteerism.</li>
          <li className='mb-2'><strong> Occupational Health and Safety – </strong> Maintaining a safe work environment by implementing ISO 45001-certified health and safety programs and regular risk assessments.</li>
          <li className='mb-2'><strong> Quality Assurance – </strong> Ensuring the highest product and service quality through strict quality control measures, compliance audits, and continuous improvement programs.</li>
          <li className='mb-2'><strong> Sustainable Supply Chain Management – </strong> Partnering with ethical suppliers and enforcing responsible procurement practices to ensure sustainability across the supply chain.</li>
        </ul>
        {loading && <div className="flex justify-center items-center loader"></div>}
        <div className={`grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8 my-24 ${loading ? 'hidden' : ''}`}>
          {cards1.map((card, index) => (
            <div
              key={index}
              className="bg-white shadow-lg rounded-lg overflow-hidden transform transition-all duration-300 hover:scale-105 hover:shadow-2xl group"
              data-aos="fade-up"
              data-aos-delay={`${index * 10}`}
            >
              <div className="relative h-56 overflow-hidden">
                <img
                  src={card.img}
                  alt={card.title}
                  className="w-full h-full object-cover transition-transform duration-300 group-hover:scale-110"
                  onLoad={handleImageLoad}
                />
                <div className="absolute inset-0 bg-black bg-opacity-20 transition-opacity duration-300 group-hover:bg-opacity-30"></div>
              </div>
              <div className="p-6 text-center">
                <h2 className="text-2xl font-bold mb-4 text-gray-800">{card.title}</h2>
                <Link to={card.link} className="inline-block" onClick={handleLinkClick}>
                  <button className="text-white py-3 px-6 bg-purple-500 rounded-lg font-semibold hover:bg-purple-600 transition-all duration-300 ease-in-out transform hover:-translate-y-1">
                    Learn More
                  </button>
                </Link>
              </div>
            </div>
          ))}
        </div>
        <h4 className="text-center font-bold mt-8 mb-4 drop-shadow-lg" style={{ fontSize: 'clamp(32px,2vw,40px)' }} data-aos="fade-up">
          Our Governance Framework
        </h4>
        <p className="text-center mb-8 px-4" data-aos="fade-up">
          Strong corporate governance is the foundation of our business. We are committed to transparency, accountability, and ethical decision-making that aligns with international standards. Our governance structure ensures that we uphold integrity in all our business practices while mitigating risks and fostering stakeholder trust.
        </p>
        <h4 className=" font-bold mt-8 mb-4" style={{ fontSize: 'clamp(20px,1.5vw,28px)' }} data-aos="fade-up">
          Key Focus Areas in Governance:
        </h4>
        <ul className="list-disc list-inside mb-8" data-aos="fade-up">
          <li className='mb-2'><strong> Corporate Governance – </strong> Establishing clear governance policies that ensure ethical leadership, financial integrity, and regulatory compliance.</li>
          <li className='mb-2'><strong> Risk Management – </strong> Implementing proactive strategies to identify, assess, and mitigate financial, operational, and environmental risks.</li>
          <li className='mb-2'><strong> Compliance and Ethical Business Practices – </strong> Adhering to local and international laws, including anti-corruption, fair trade, and environmental regulations.</li>
          <li className='mb-2'><strong> Intellectual Property Protection – </strong> Safeguarding innovation, patents, trademarks, and proprietary technologies to maintain a competitive and secure business environment.</li>
        </ul>
        <div className={`grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8 my-24 ${loading ? 'hidden' : ''}`}>
          {cards2.map((card, index) => (
            <div
              key={index}
              className="bg-white shadow-lg rounded-lg overflow-hidden transform transition-all duration-300 hover:scale-105 hover:shadow-2xl group"
              data-aos="fade-up"
              data-aos-delay={`${index * 10}`}
            >
              <div className="relative h-56 overflow-hidden">
                <img
                  src={card.img}
                  alt={card.title}
                  className="w-full h-full object-cover transition-transform duration-300 group-hover:scale-110"
                  onLoad={handleImageLoad}
                />
                <div className="absolute inset-0 bg-black bg-opacity-20 transition-opacity duration-300 group-hover:bg-opacity-30"></div>
              </div>
              <div className="p-6 text-center">
                <h2 className="text-2xl font-bold mb-4 text-gray-800">{card.title}</h2>
                <Link to={card.link} className="inline-block" onClick={handleLinkClick}>
                  <button className="text-white py-3 px-6 bg-purple-500 rounded-lg font-semibold hover:bg-purple-600 transition-all duration-300 ease-in-out transform hover:-translate-y-1">
                    Learn More
                  </button>
                </Link>
              </div>
            </div>
          ))}
        </div>
        <div className="px-4 mb-8">
     <h2 className='tex-2xl '>   Driving Sustainability through Social Responsibility and Governance </h2>
          <p className="text-left mt-4 mb-8" data-aos="fade-up">
           
            By integrating social responsibility and governance into our core business strategy, we ensure that sustainability is more than just a corporate goal—it’s a commitment to ethical operations, employee well-being, and community development. Our initiatives support the UN Sustainable Development Goals (SDGs) and reinforce our role as a responsible corporate citizen that prioritizes people, the planet, and business integrity.
          </p>
          {showMore && (
            <div className="additional-text" data-aos="fade-up">
              <p className="text-left mt-4 mb-8">
                Circular Economy & Sustainable Supply Chain
                At MBA Solutions, we embrace a circular economy approach and a sustainable supply chain to reduce environmental impact, promote ethical sourcing, and ensure long-term business resilience. Our commitment to sustainability goes beyond compliance—we integrate responsible procurement, resource efficiency, and ethical labor practices into our entire supply chain.
                By adopting global sustainability standards, including:
                <ul className="list-disc list-inside my-8">
                  <li className='mb-2'><strong>ISO 20400 – </strong>Guidelines for sustainable procurement.</li>
                  <li className='mb-2'><strong>SA8000 – </strong>Social accountability standard for fair labor practices.</li>
                  <li className='mb-2'><strong>Fair Trade Certification – </strong>Ethical sourcing and trade standards.</li>
                  <li className='mb-2'><strong>Cradle to Cradle (C2C) – </strong>Sustainable product lifecycle framework.</li>
                  <li className='mb-2'><strong>EPEAT (Electronic Product Environmental Assessment Tool) – </strong>Sustainability standard for IT products.</li>
                </ul>
                we ensure that every stage of our supply chain aligns with best practices for environmental, social, and economic responsibility.
                Circular Economy: Rethinking Sustainability
                A circular economy is a shift from the traditional "take-make-dispose" model to a system where resources are used efficiently, repurposed, and regenerated to minimize waste. Our circular economy strategy focuses on:
                <ul className="list-disc list-inside my-8">
                  <li className='my-2'><strong>Sustainable Product Design – </strong>Developing products that are durable, repairable, and recyclable, reducing resource depletion and waste.</li>
                  <li className='mb-2'><strong>Resource Optimization – </strong>Maximizing the use of recycled materials, reducing energy consumption, and promoting zero-waste practices.</li>
                  <li className='mb-2'><strong>Closed-Loop Manufacturing – </strong>Implementing reuse, refurbishment, and remanufacturing systems to extend product life cycles.</li>
                  <li className='mb-2'><strong>Eco-Friendly Packaging – </strong>Reducing plastic usage and utilizing biodegradable and recyclable materials in packaging.</li>
                  <li className='mb-2'><strong>End-of-Life Management – </strong>Encouraging responsible product disposal through recycling initiatives and take-back programs.</li>
                </ul>
                The Cradle to Cradle (C2C) framework plays a vital role in our circular economy initiatives by ensuring that all materials used in our products are safe, reusable, and environmentally friendly throughout their life cycle.
                Sustainable Supply Chain: Responsible Sourcing & Ethical Procurement
                Our sustainable supply chain strategy ensures that all materials, products, and services are sourced ethically, sustainably, and responsibly. We follow ISO 20400 – Sustainable Procurement Guidelines to ensure that our purchasing decisions align with social, environmental, and economic sustainability goals.
                Key Principles of Our Sustainable Supply Chain:
                <ul className="list-disc list-inside my-8">
                  <li className='mb-2'><strong>Ethical Sourcing & Fair Labor Practices – </strong>We comply with SA8000 – Social Accountability Standard, ensuring fair wages, safe working conditions, and human rights protection across our supply chain. We actively prevent child labor, forced labor, and discrimination by partnering only with ethical suppliers.</li>
                  <li className='mb-2'><strong>Responsible Procurement & Supply Chain Transparency – </strong>Our procurement process prioritizes suppliers who meet environmental, labor, and governance (ESG) standards. We require suppliers to provide certifications and audits to ensure compliance with sustainability criteria.</li>
                  <li className='mb-2'><strong>Fair Trade & Ethical Trade Practices – </strong>We support Fair Trade Certification, ensuring equitable wages, fair pricing, and sustainable sourcing of raw materials. Our partnerships with socially responsible producers promote ethical farming, responsible mining, and fair labor rights.</li>
                  <li className='mb-2'><strong>Eco-Friendly & Sustainable IT Procurement – </strong>We follow the EPEAT (Electronic Product Environmental Assessment Tool) standard to source environmentally friendly IT products. Our technology procurement prioritizes energy-efficient, recyclable, and low-carbon footprint products.</li>
                  <li className='mb-2'><strong>Carbon Footprint Reduction in Logistics – </strong>We optimize supply chain logistics to reduce emissions, promote green transportation, and minimize waste. We work with suppliers to adopt low-carbon supply chain solutions, including electric vehicles, eco-friendly warehouses, and renewable energy adoption.</li>
                  <li className='mb-2'><strong>Waste Reduction & Circular Supply Chains – </strong>We promote a zero-waste philosophy across our supply chain by implementing material recovery programs, recycling of manufacturing waste, and circular packaging solutions.</li>
                </ul>
                Global Standards & Certifications Driving Our Sustainable Supply Chain
                At MBA Solutions, we align with internationally recognized sustainability frameworks to ensure compliance, accountability, and transparency across our supply chain.
                <ul className="list-disc list-inside my-8">
                  <li className='mb-2'><strong>ISO 20400 – </strong>Sustainable Procurement Guidelines: ISO 20400 provides a framework for organizations to integrate sustainability into procurement processes. We follow these guidelines to select suppliers with strong environmental and social governance (ESG) policies, ensure sustainable material sourcing and responsible purchasing, and promote ethical and eco-friendly business relationships.</li>
                  <li className='mb-2'><strong>SA8000 – </strong>Social Accountability Standard: The SA8000 certification ensures that our suppliers maintain fair wages, ethical labor conditions, and workplace safety. We have a zero-tolerance policy for child labor, forced labor, and discrimination.</li>
                </ul>
              </p>
            </div>
          )}
          <button onClick={toggleShowMore} className="text-white py-3 px-6 bg-purple-500 rounded-lg font-semibold hover:bg-purple-600 transition-all duration-300 ease-in-out transform hover:-translate-y-1">
            {showMore ? 'Show Less' : 'Show More'}
          </button>
        </div>
      </div>
      <Sustainabiltycommon />
    </Layout>
  );
};

export default SocialGovernance;
